import get from 'lodash.get';
import { IPartnership2x2 } from '@components/sections/Partnership2x2/Partnership2x2.d';

export const partnership2x2 = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: IPartnership2x2 = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: false, // just in case you need to add a background change
    items: get(data, 'fieldPartnership2x2Item', []).map(item => ({
      image: get(item, 'entity.fieldImage', undefined) ? {
        url: get(item, 'entity.fieldImage.url', ''),
        alt: get(item, 'entity.fieldImage.alt', ''),
        title: get(item, 'entity.fieldImage.title', ''),
        webp: get(item, 'entity.fieldImage.derivative.url', ''),
      } : undefined,
      title: get(item, 'entity.fieldTitle', ''),
      copy: get(item, 'entity.fieldCopy.processed', ''),
    }))
  };

  return mappedData;
}
