import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const partnershipPageData = () => {
  const data = useStaticQuery(graphql`
    query partnershipPageQuery {
      drupalData {
        nodeQuery(
          filter: { conditions: { field: "type", value: "partnership_page" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_NodePartnershipPage {
              entityLabel

              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldContextImageWithCaption {
                entity {
                  ...ParagraphContextImageWithCaption
                }
              }
              fieldConversion2Cta {
                entity {
                  ...ParagraphConversion2Cta
                }
              }
              fieldFormOffer
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldNumericalBenefits {
                entity {
                  ...ParagraphNumericalBenefits
                }
              }
              fieldOurCustomerLogo {
                entity {
                  ...ParagraphOurCustomerLogo
                }
              }
              fieldPartnership2x2 {
                entity {
                  ...ParagraphPartnership2x2
                }
              }
              fieldPartnershipContact {
                entity {
                  ...ParagraphPartnershipContact
                }
              }
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
