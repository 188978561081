import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCtaGrayFrame = graphql`
  fragment ParagraphCtaGrayFrame on drupalData_ParagraphCtaGrayFrame {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;

export const ParagraphCtaGrayFramePreview = gql`
  ... on ParagraphCtaGrayFrame {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;
