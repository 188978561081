import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphImageWithCaption = graphql`
  fragment ParagraphImageWithCaption on drupalData_ParagraphImageWithCaption {
    entityBundle
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldTitle
  }
`;

export const ParagraphImageWithCaptionPreview = gql`
  ... on ParagraphImageWithCaption {
    entityBundle
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldTitle
  }
`;
