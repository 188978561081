import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogVideo = graphql`
  fragment ParagraphBlogVideo on drupalData_ParagraphBlogVideo {
    entityBundle
    fieldVideoCaption
    fieldVideo {
      entity {
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    fieldPoster {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;

export const ParagraphBlogVideoPreview = gql`
  ... on ParagraphBlogVideo {
    entityBundle
    fieldVideoCaption
    fieldVideo {
      entity {
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    fieldPoster {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;
