import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphTextAndVideo = graphql`
  fragment ParagraphTextAndVideo on drupalData_ParagraphTextAndVideo {
    entityBundle
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldImageSubHeadline
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldSubtitle
    fieldTextSetting
    fieldTitle
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;

export const ParagraphTextAndVideoPreview = gql`
  ... on ParagraphTextAndVideo {
    entityBundle
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldImageSubHeadline
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldSubtitle
    fieldTextSetting
    fieldTitle
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;
