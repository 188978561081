import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCareersIntro = graphql`
  fragment ParagraphCareersIntro on drupalData_ParagraphCareersIntro {
    entityBundle
    fieldDepartamentName {
      entity {
        ... on drupalData_TaxonomyTermDepartmentsWithJob {
          name
        }
      }
    }
    fieldDescription {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldJobTitle
    fieldLocation
  }
`;

export const ParagraphCareersIntroPreview = gql`
  ... on ParagraphCareersIntro {
    entityBundle
    fieldDepartamentName {
      entity {
        ... on TaxonomyTermDepartmentsWithJob {
          name
        }
      }
    }
    fieldDescription {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldJobTitle
    fieldLocation
  }
`;