import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphAccessoriesMediaPreview } from '@graphql/fragments/ParagraphAccessoriesMedia';
import { ParagraphAccessoriesUrlPreview } from '@graphql/fragments/ParagraphAccessoriesUrl';

export const ParagraphAccessories = graphql`
  fragment ParagraphAccessories on drupalData_ParagraphAccessories {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldAccessoriesMedia {
      entity {
        ...ParagraphAccessoriesMedia
      }
    }
    fieldAccessoriesUrl {
      entity {
        ...ParagraphAccessoriesUrl
      }
    }
    uuid
  }
`;

export const ParagraphAccessoriesPreview = gql`
  ... on ParagraphAccessories {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldAccessoriesMedia {
      entity {
        ${ParagraphAccessoriesMediaPreview}
      }
    }
    fieldAccessoriesUrl {
      entity {
        ${ParagraphAccessoriesUrlPreview}
      }
    }
    uuid
  }
`;