import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphIndustriesItem = graphql`
  fragment ParagraphIndustriesItem on drupalData_ParagraphIndustriesItem {
    entityBundle
    fieldImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldLinkNotCta {
      title
      url {
        path
      }
    }
    fieldTitle
  }
`;

export const ParagraphIndustriesItemPreview = gql`
  ... on ParagraphIndustriesItem {
    entityBundle
    fieldImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldLinkNotCta {
      title
      url {
        path
      }
    }
    fieldTitle
  }
`;