import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphContactRegionDataPreview } from '@graphql/contact/ParagraphContactRegionData';

export const ParagraphContactRegion = graphql`
  fragment ParagraphContactRegion on drupalData_ParagraphContactRegion {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldContactRegionData {
      entity {
        ...ParagraphContactRegionData
      }
    }
    uuid
  }
`;

export const ParagraphContactRegionPreview = gql`
  ... on ParagraphContactRegion {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldContactRegionData {
      entity {
        ${ParagraphContactRegionDataPreview}
      }
    }
    uuid
  }
`;