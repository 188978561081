import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphLpNavigationHeader = graphql`
  fragment ParagraphLpNavigationHeader on drupalData_ParagraphLpNavigationHeader {
    entityBundle
    fieldCopyLp
    fieldPhone
    fieldAlpha
    fieldColor
    fieldEmail
    uuid
  }
`;

export const ParagraphLpNavigationHeaderPreview = gql`
  ... on ParagraphLpNavigationHeader {
    entityBundle
    fieldCopyLp
    fieldPhone
    fieldAlpha
    fieldColor
    fieldEmail
    uuid
  }
`;