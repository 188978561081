import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphContactDepartmentsWithJob = graphql`
  fragment ParagraphContactDepartmentsWithJob on drupalData_ParagraphContactDepartmentsWithJob {
    uuid
    fieldDescription {
      processed
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldTitle
    entityBundle
  }
`;

export const ParagraphContactDepartmentsWithJobPreview = gql`
  ... on ParagraphContactDepartmentsWithJob {
    uuid
    fieldDescription {
      processed
    }
    fieldLinkCta {
      title
      uri
    }
    fieldTitle
    entityBundle
  }
`;