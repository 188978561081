import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphKeyPointsItemPreview } from '@graphql/fragments/ParagraphKeyPointsItem';

export const ParagraphKeyPoints = graphql`
  fragment ParagraphKeyPoints on drupalData_ParagraphKeyPoints {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldKe {
      entity {
        ...ParagraphKeyPointsItem
      }
    }
  }
`;

export const ParagraphKeyPointsPreview = gql`
  ... on ParagraphKeyPoints {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldKe {
      entity {
        ${ParagraphKeyPointsItemPreview}
      }
    }
  }
`;