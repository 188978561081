import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphLpFooterMenu = graphql`
  fragment ParagraphLpFooterMenu on drupalData_ParagraphLpFooterMenu {
    id
    fieldCopyrights {
      processed
    }
    fieldLink {
      url {
        path
      }
      title
    }
    fieldFooter {
      entity {
        ... on drupalData_ParagraphFooter {
          fieldEmail
          fieldLabelEmail
          fieldLabelPhone
          fieldPhone
          fieldFooterLink {
            entity {
              ... on drupalData_ParagraphFooterLink {
                fieldLink {
                  url {
                    path
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
    entityBundle
  }
`;

export const ParagraphLpFooterMenuPreview = gql`
  ... on ParagraphLpFooterMenu {
    id
    fieldCopyrights {
      processed
    }
    fieldLink {
      url {
        path
      }
      title
    }
    fieldFooter {
      entity {
        ... on ParagraphFooter {
          fieldEmail
          fieldLabelEmail
          fieldLabelPhone
          fieldPhone
          fieldFooterLink {
            entity {
              ... on ParagraphFooterLink {
                fieldLink {
                  url {
                    path
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
    entityBundle
  }
`;
