import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphFaq = graphql`
  fragment ParagraphFaqItem on drupalData_ParagraphFaqItem {
    id
    fieldTitle
    fieldCopy {
      processed
    }
  }
`;

export const ParagraphFaqItemPreview = gql`
  ... on ParagraphFaqItem {
    id
    fieldTitle
    fieldCopy {
      processed
    }
  }
`;