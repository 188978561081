import get from 'lodash.get';

// === Data === //
import { productsData } from '@graphql/Products';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { bigNumbers } from '@DTO/sections/BigNumbers';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { gallery } from '@DTO/sections/Gallery';
import { conversionWith2CTA } from '@DTO/sections/ConversionWith2CTA';
import { cardWithProductDetails } from '@DTO/sections/CardWithProductDetails';
import { iconWithCopy } from '@DTO/sections/IconWithCopy';
import { video } from '@DTO/sections/Video';
import { orbitvuTour } from '@DTO/sections/OrbitvuTour';
import { accessories } from '@DTO/sections/Accessories';
import { cardTextVideo } from '@DTO/sections/CardTextVideo';
import { contextImageWithCaption } from '@DTO/sections/ContextImageWithCaption';
import { faq } from '@DTO/sections/FAQ';
import { pageForm } from '@DTO/sections/PageForm';
import { metatags } from '@DTO/sections/Metatags';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const ProductPageDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const productsDataQuery = productsData();
    currentPageData = productsDataQuery;
  } else {
    currentPageData = data;
  }

  const additionalData = data
    ? {
        form: get(currentPageData, 'drupalData.form'),
      }
    : undefined;
  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');

  const banner = mainBanner(nodeEntity, 'fieldMainBanner');
  banner.type = 'main_banner_variant_4';

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, ['fieldCategoryA', 'fieldCategoryB']),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      banner,
      bigNumbers(nodeEntity, 'fieldNumericalBenefits'),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      cardWithProductDetails(nodeEntity, 'fieldCardWithProductsDetails'),
      gallery(nodeEntity, 'fieldGallery'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta'),
      iconWithCopy(nodeEntity, 'fieldIconWithCopy'),
      video(nodeEntity, 'fieldVideo'),
      orbitvuTour(nodeEntity, 'fieldOrbitvuTour'),
      accessories(nodeEntity, 'fieldAccessories'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta2'),
      cardTextVideo(nodeEntity, 'fieldCardTextVideo'),
      contextImageWithCaption(nodeEntity, 'fieldContextImageWithCaption'),
      faq(nodeEntity, 'fieldFaq'),
      pageForm(nodeEntity, 'fieldProductPageForm', additionalData),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
