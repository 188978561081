import { gql } from 'graphql-request';

import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphGalleryPreview } from '@graphql/fragments/ParagraphGallery';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphAccessoriesPreview } from '@graphql/fragments/ParagraphAccessories';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';
import { ParagraphFashionCardSmallPreview } from '@graphql/fragments/ParagraphFashionCardSmall';
import { ParagraphFashionSectionPreview } from '@graphql/fragments/ParagraphFashionSection';

export const FashionSite = gql`
  ... on NodeIndustriesPage {
    entityLabel
    fieldAccessories {
      entity {
        ${ParagraphAccessoriesPreview}
      }
    }
    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldConversion2Cta {
      entity {
        ${ParagraphConversion2CtaPreview}
      }
    }
    fieldFaq {
      entity {
        ${ParagraphFaqPreview}
      }
    }
    fieldFashionCardSmall {
      entity {
        ${ParagraphFashionCardSmallPreview}
      }
    }
    fieldFashionSection {
      entity {
        ${ParagraphFashionSectionPreview}
      }
    }
    fieldGallery {
      entity {
        ${ParagraphGalleryPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }

    fieldCategoryA {
      entity {
        title
        path {
          alias
        }
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
