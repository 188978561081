import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphTeamItem = graphql`
  fragment ParagraphTeamItem on drupalData_ParagraphTeamItem {
    entityBundle
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    uuid
  }
`;

export const ParagraphTeamItemPreview = gql`
  ... on ParagraphTeamItem {
    entityBundle
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    uuid
  }
`;