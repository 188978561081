import { gql } from 'graphql-request';

import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphLpNavigationHeaderPreview } from '@graphql/lp/ParagraphLpNavigationHeader';
import { ParagraphLpHeaderPreview } from '@graphql/lp/ParagraphLpHeader';
import { ParagraphLpVideoPreview } from '@graphql/lp/ParagraphLpVideo';
import { ParagraphLpImageWithIconCopyPreview } from '@graphql/lp/ParagraphLpImageWithIconCopy';
import { ParagraphLpConversionBannerPreview } from '@graphql/lp/ParagraphLpConversionBanner';
import { ParagraphLpFooterMenuPreview } from '@graphql/lp/ParagraphLpFooter';
import { ParagraphLpImageWithCaptionPreview } from '@graphql/lp/ParagraphLpImageWithCaption';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphLpFooterFormPreview } from '@graphql/lp/ParagraphLpFooterForm';
import { ParagraphLpImageWithIconCopy2Preview } from '@graphql/lp/ParagraphLpImageWithIconCopy2';
import { ParagraphLpVideo2Preview } from '@graphql/lp/ParagraphLpVideo2';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphOurCustomerLogoPreview } from '@graphql/fragments/ParagraphOurCustomerLogo';
import { ParagraphGalleryPreview } from '@graphql/fragments/ParagraphGallery';
import { ParagraphCardTextImage2Preview } from '@graphql/fragments/ParagraphCardTextImage2';
import { ParagraphCardTextImage3Preview } from '@graphql/fragments/ParagraphCardTextImage3';
import { ParagraphCardWithProductsDetailsPreview } from '@graphql/fragments/ParagraphCardWithProductsDetails';

export const LandingPage = gql`
  ... on NodeLandingPage {
    entityLabel
    fieldFormOffer
    fieldLpFooter {
      entity {
        ${ParagraphLpFooterMenuPreview}
      }
    }
    fieldLpFooterForm {
      entity {
        ${ParagraphLpFooterFormPreview}
      }
    }
    fieldLpHeader {
      entity {
        ${ParagraphLpHeaderPreview}
      }
    }
    fieldLpNavigationHeader {
      entity {
        ${ParagraphLpNavigationHeaderPreview}
      }
    }
    fieldSectionLp {
      entity {
        ${ParagraphLpImageWithCaptionPreview}
        ${ParagraphLpImageWithIconCopyPreview}
        ${ParagraphLpImageWithIconCopy2Preview}
        ${ParagraphLpVideoPreview}
        ${ParagraphLpVideo2Preview}
        ${ParagraphOurCustomerLogoPreview}
        ${ParagraphCardTextImagePreview}
        ${ParagraphCardTextImage2Preview}
        ${ParagraphCardTextImage3Preview}
        ${ParagraphCardWithProductsDetailsPreview}
        ${ParagraphContextImageWithCaptionPreview}
        ${ParagraphGalleryPreview}
        ${ParagraphLpConversionBannerPreview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldForm {
      entity {
        uuid
      }
    }
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
