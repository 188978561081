import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphNoOnlineEvent = graphql`
  fragment ParagraphNoOnlineEvent on drupalData_ParagraphNoOnlineEvent {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    uuid
  }
`;

export const ParagraphNoOnlineEventPreview = gql`
  ... on ParagraphNoOnlineEvent {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    uuid
  }
`;