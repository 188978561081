import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCaseStudyItem = graphql`
  fragment ParagraphCaseStudyItem on drupalData_ParagraphCaseStudyItem {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldSubheadline
    uuid
  }
`;

export const ParagraphCaseStudyItemPreview = gql`
  ... on ParagraphCaseStudyItem {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldSubheadline
    uuid
  }
`;