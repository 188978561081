import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';

export const ParagraphIndustriesSubmenuSection = graphql`
  fragment ParagraphIndustriesSubmenuSection on drupalData_ParagraphIndustriesSubmenuSection {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldIndustriesSubmenuSection {
      entity {
        ... on drupalData_ParagraphIndustriesSubmenuSectionItem {
          entityBundle
          fieldCardTextImage {
            entity {
              ...ParagraphCardTextImage
            }
          }
          fieldSubmenu
        }
      }
    }
  }
`;
export const ParagraphIndustriesSubmenuSectionPreview = gql`
  ... on ParagraphIndustriesSubmenuSection {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldIndustriesSubmenuSection {
      entity {
        ... on ParagraphIndustriesSubmenuSectionItem {
          entityBundle
          fieldCardTextImage {
            entity {
              ${ParagraphCardTextImagePreview}
            }
          }
          fieldSubmenu
        }
      }
    }
  }
`;
