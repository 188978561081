import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphSoftwareAdditionalNavi = graphql`
  fragment ParagraphSoftwareAdditionalNavi on drupalData_ParagraphSoftwareAdditionalNavi {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphSoftwareAdditionalNaviPreview = gql`
  ... on ParagraphSoftwareAdditionalNavi {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    uuid
  }
`;