import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphFashionCardSmallLast = graphql`
  fragment ParagraphFashionCardSmallLast on drupalData_ParagraphFashionCardSmallLast {
    fieldTitle
    fieldCopy {
      processed
    }
    fieldLinkCta {
      url {
        path
      }
      title
    }
  }
`;

export const ParagraphFashionCardSmallLastPreview = gql`
  ... on ParagraphFashionCardSmallLast {
    fieldTitle
    fieldCopy {
      processed
    }
    fieldLinkCta {
      url {
        path
      }
      title
    }
  }
`;
