import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCardWithProductsDetails = graphql`
  fragment ParagraphCardWithProductsDetails on drupalData_ParagraphCardWithProductsDetails {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldSelectImageOrVideo
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldLinkOrUpload {
      entity {
        ... on drupalData_ParagraphProductsDetailsLinkOrUpload {
          entityBundle
          fieldLinkProduct {
            title
            url {
              path
            }
          }
          fieldSelectLinkOrUpload
          fieldUploadProduct {
            description
            entity {
              url
            }
          }
        }
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldLabel
    fieldLabelColor
    fieldLabelLink {
      url {
        path
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;

export const ParagraphCardWithProductsDetailsPreview = gql`
  ... on ParagraphCardWithProductsDetails {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldSelectImageOrVideo
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldLinkOrUpload {
      entity {
        ... on ParagraphProductsDetailsLinkOrUpload {
          entityBundle
          fieldLinkProduct {
            title
            url {
              path
            }
          }
          fieldSelectLinkOrUpload
          fieldUploadProduct {
            description
            entity {
              url
            }
          }
        }
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldLabel
    fieldLabelColor
    fieldLabelLink {
      url {
        path
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;
