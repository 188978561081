import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphSliderPreview } from '@graphql/fragments/ParagraphSlider';

export const ParagraphTextAndImage = graphql`
  fragment ParagraphTextAndImage on drupalData_ParagraphTextAndImage {
    entityBundle
    fieldDescription {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldLabel
    fieldLabelColor
    fieldLabelLink {
      url {
        path
      }
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldSelectImageOrVideo
    fieldVideo {
      entity {
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldSliderParagraph {
      entity {
        ...ParagraphSlider
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;

export const ParagraphTextAndImagePreview = gql`
  ... on ParagraphTextAndImage {
    entityBundle
    fieldDescription {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldLabel
    fieldLabelColor
    fieldLabelLink {
      url {
        path
      }
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldSelectImageOrVideo
    fieldVideo {
      entity {
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldSliderParagraph {
      entity {
        ${ParagraphSliderPreview}
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;
