import { graphql, useStaticQuery } from 'gatsby';
import { replaceLangCodes } from '@utils';

export const aboutUsData = () => {
  const data = useStaticQuery(graphql`
    query aboutUsQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "about_us_page" } }) {
          entities {
            ... on drupalData_NodeAboutUsPage {
              entityLabel

              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldCardTextImage2 {
                entity {
                  ...ParagraphCardTextImage2
                }
              }
              fieldCardTextImage3 {
                entity {
                  ...ParagraphCardTextImage3
                }
              }
              fieldContextImageWithCaption {
                entity {
                  ...ParagraphContextImageWithCaption
                }
              }
              fieldEmployees {
                entity {
                  ...ParagraphEmployees
                }
              }
              fieldIconWithCopy {
                entity {
                  ...ParagraphProductIconWithCopy
                }
              }
              fieldIconWithCopy2 {
                entity {
                  ...ParagraphProductIconWithCopy2
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldOurCustomerLogo {
                entity {
                  ...ParagraphOurCustomerLogo
                }
              }
              fieldVideo {
                entity {
                  ...ParagraphVideo
                }
              }
              fieldFormOffer

              path {
                alias
              }
              title

              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
