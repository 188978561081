import get from 'lodash.get';
import { carouselData } from '@helpers/smallCarousel';

export const cardSmallCarousel = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    title: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldDescription.processed', ''),
    slides: carouselData(
      get(data, 'fieldProductsAvailable', []),
      get(data, 'fieldProductSelect', [])
    ),
  }
}