import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphIndustriesItemPreview } from '@graphql/fragments/ParagraphIndustriesItem';

export const ParagraphIndustriesGrid = graphql`
  fragment ParagraphIndustriesGrid on drupalData_ParagraphIndustriesGrid {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldIndustriesItem {
      entity {
        ...ParagraphIndustriesItem
      }
    }
  }
`;

export const ParagraphIndustriesGridPreview = gql`
  ... on ParagraphIndustriesGrid {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldIndustriesItem {
      entity {
        ${ParagraphIndustriesItemPreview}
      }
    }
  }
`;