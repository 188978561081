import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphProductSmallCardPreview } from '@graphql/fragments/ParagraphProductSmallCard'

export const ParagraphCardSmallCarousel = graphql`
  fragment ParagraphCardSmallCarousel on drupalData_ParagraphCardSmallCarousel {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldDescription {
      processed
    }
    fieldProductsAvailable {
      entity {
        ... on drupalData_NodeProduct {
          entityBundle
          fieldSmallCard {
            entity {
              ... ParagraphProductSmallCard
            }
          }
          path {
            alias
          }
          title
        }
      }
    }
    fieldProductSelect {
      entity {
        entityBundle
        ... on drupalData_ParagraphProductsAvailableSelect {
          entityBundle
          fieldProductsAvailableSelect {
            entity {
              ... on drupalData_NodeProduct {
                entityPublished
                entityBundle
                fieldSmallCard {
                  entity {
                    ... ParagraphProductSmallCard
                  }
                }
                path {
                  alias
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const ParagraphCardSmallCarouselPreview = gql`
  ... on ParagraphCardSmallCarousel {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldDescription {
      processed
    }
    fieldProductsAvailable {
      entity {
        ... on NodeProduct {
          entityBundle
          fieldSmallCard {
            entity {
              ${ParagraphProductSmallCardPreview}
            }
          }
          path {
            alias
          }
          title
        }
      }
    }
    fieldProductSelect {
      entity {
        entityBundle
        ... on ParagraphProductsAvailableSelect {
          entityBundle
          fieldProductsAvailableSelect {
            entity {
              ... on NodeProduct {
                entityPublished
                entityBundle
                fieldSmallCard {
                  entity {
                    ${ParagraphProductSmallCardPreview}
                  }
                }
                path {
                  alias
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;
