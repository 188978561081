import { gql } from "graphql-request";

import { ParagraphCardTextImagePreview } from "@graphql/fragments/ParagraphCardTextImage";
import { ParagraphCardTextImage2Preview } from "@graphql/fragments/ParagraphCardTextImage2";
import { ParagraphCardTextImage3Preview } from "@graphql/fragments/ParagraphCardTextImage3";
import { ParagraphContextImageWithCaptionPreview } from "@graphql/fragments/ParagraphContextImageWithCaption";
import { ParagraphEmployeesPreview } from "@graphql/fragments/ParagraphEmployees";
import { ParagraphProductIconWithCopyPreview } from "@graphql/fragments/ParagraphProductIconWithCopy";
import { ParagraphProductIconWithCopy2Preview } from "@graphql/fragments/ParagraphProductIconWithCopy2";
import { ParagraphMainBannerVariant1Preview } from "@graphql/fragments/ParagraphMainBannerVariant1";
import { ParagraphMainBannerVariant2Preview } from "@graphql/fragments/ParagraphMainBannerVariant2";
import { ParagraphMainBannerVariant3Preview } from "@graphql/fragments/ParagraphMainBannerVariant3";
import { ParagraphMetatagsPreview } from "@graphql/fragments/ParagraphMetatags";
import { ParagraphOurCustomerLogoPreview } from "@graphql/fragments/ParagraphOurCustomerLogo";
import { ParagraphVideoPreview } from "@graphql/fragments/ParagraphVideo";


export const AboutUs = gql`
  ... on NodeAboutUsPage {
    entityLabel
    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldCardTextImage2 {
      entity {
        ${ParagraphCardTextImage2Preview}
      }
    }
    fieldCardTextImage3 {
      entity {
        ${ParagraphCardTextImage3Preview}
      }
    }
    fieldContextImageWithCaption {
      entity {
        ${ParagraphContextImageWithCaptionPreview}
      }
    }
    fieldEmployees {
      entity {
        ${ParagraphEmployeesPreview}
      }
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphProductIconWithCopyPreview}
      }
    }
    fieldIconWithCopy2 {
      entity {
        ${ParagraphProductIconWithCopy2Preview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldOurCustomerLogo {
      entity {
        ${ParagraphOurCustomerLogoPreview}
      }
    }
    fieldVideo {
      entity {
        ${ParagraphVideoPreview}
      }
    }
    fieldFormOffer
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;