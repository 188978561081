import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphPartnershipContact = graphql`
  fragment ParagraphPartnershipContact on drupalData_ParagraphPartnershipContact {
    entityBundle
    fieldBackground
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldAnchorId
  }
`;

export const ParagraphPartnershipContactPreview = gql`
  ... on ParagraphPartnershipContact {
    entityBundle
    fieldBackground
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldAnchorId
  }
`;