import { IndustriesSiteDTO } from '@DTO/IndustriesSiteDTO';
import { IndustriesSite } from '@graphql/preview/IndustriesSite';
import { Form } from '@graphql/preview/Form';
import { AboutUs } from '@graphql/preview/AboutUs';
import { AboutUsDTO } from '@DTO/AboutUsDTO';
import { CalculateProducts, CalculatorPage } from '@graphql/preview/CalculatorPage';
import { CalculatePageDTO } from '@DTO/CalculatePageDTO';
import { Error404 } from '@graphql/preview/ErrorPages';
import { ErrorPagesDTO } from '@DTO/ErrorPagesDTO';
import { FashionSite } from '@graphql/preview/FashionSite';
import { FashionSiteDTO } from '@DTO/FashionSiteDTO';
import { Career, CareerDetail, Taxonomy } from '@graphql/preview/Career';
import { CareerPageDTO } from '@DTO/CareerPageDTO';
import { CareerDetailPageDTO } from '@DTO/CareerDetailPageDTO';
import { CaseStudiesSite, CSBlogArticles, CSBlogCategories } from '@graphql/preview/CaseStudiesSite';
import { CaseStudiesSiteDTO } from '@DTO/CaseStudiesSiteDTO';
import { ContactPage, ContactPageQuery2 } from '@graphql/preview/ContactPage';
import { ContactPageDTO } from '@DTO/ContactPageDTO';
import { EventNoEventSite } from '@graphql/preview/EventNoEventSite';
import { EventNoEventSiteDTO } from '@DTO/EventNoEventSiteDTO';
import { Homepage } from '@graphql/preview/Homepage';
import { HomepageDTO } from '@DTO/HomepageDTO';
import { HowItWorksSite } from '@graphql/preview/HowItWorksSite';
import { HowItWorksSiteDTO } from '@DTO/HowItWorksSiteDTO';
import { LandingPage } from '@graphql/preview/LandingPage';
import { LandingPageDTO } from '@DTO/LandingPageDTO';
import { SupportPage } from '@graphql/preview/SupportPage';
import { SupportPageDTO } from '@DTO/SupportPageDTO';
import { SoftwareSite, SoftwareAdditionalNavi } from '@graphql/preview/SoftwareSite';
import { SoftwareSiteDTO } from '@DTO/SoftwareSiteDTO';
import { LegalPages, LegalPagesLinks } from '@graphql/preview/LegalPages';
import { LegalPagesDTO } from '@DTO/LegalPages';
import { CareersDetails, TaxonomyTermQuery } from '@graphql/preview/CareerDetail';
import { OrbitvuPage } from '@graphql/preview/OrbitvuPages';
import { OrbitvuPageDTO } from '@DTO/OrbitvuPageDTO';
import { PartnershipPage } from '@graphql/preview/PartnershipPage';
import { PartnershipPageDTO } from '@DTO/PartnershipPageDTO';
import { ProductAllCategory } from '@graphql/preview/ProductAllCategory';
import { ProductAllCategoryPageDTO } from '@DTO/ProductAllCategoryPageDTO';
import { ProductCategory } from '@graphql/preview/ProductCategory';
import { ProductCategoryPageDTO } from '@DTO/ProductCategoryDTO';
import { Products } from '@graphql/preview/Products';
import { ProductPageDTO } from '@DTO/ProductPageDTO';
import { SoftwarePage } from '@graphql/preview/SoftwarePage';
import { SoftwarePageDTO } from '@DTO/SoftwarePageDTO';
import { NodeBlogPage } from '@graphql/preview/BlogPage';
import { BlogPageDTO } from '@DTO/BlogPageDTO';
import { BlogArticlesList, BlogArticles, BlogCategories } from '@graphql/preview/BlogArticlesList';
import { BlogArticlesListPageDTO } from '@DTO/BlogArticlesListPageDTO';

interface IPageTypes {
  [key: string]: {
    query: string;
    query2?: string;
    dto: Function;
  };
}

const types: IPageTypes = {
  about_us_page: {
    query: AboutUs,
    query2: `${Form}`,
    dto: AboutUsDTO,
  },
  calculate_page: {
    query: CalculatorPage,
    query2: `${CalculateProducts} ${Form}`,
    dto: CalculatePageDTO,
  },
  careers_page: {
    query: Career,
    query2: `${Taxonomy} ${CareerDetail} ${Form}`,
    dto: CareerPageDTO,
  },
  careers_details: {
    query: CareersDetails,
    query2: `${TaxonomyTermQuery} ${Form}`,
    dto: CareerDetailPageDTO,
  },
  case_studies_site: {
    query: CaseStudiesSite,
    query2: `${CSBlogCategories} ${CSBlogArticles} ${Form}`,
    dto: CaseStudiesSiteDTO,
  },
  contact_page: {
    query: ContactPage,
    query2: `${Form} ${ContactPageQuery2}`,
    dto: ContactPageDTO,
  },
  '404_page': {
    query: Error404,
    dto: ErrorPagesDTO,
  },
  event_no_event_site: {
    query: EventNoEventSite,
    query2: `${Form} ${BlogArticles}`,
    dto: EventNoEventSiteDTO,
  },
  industries_page: {
    query: FashionSite,
    query2: `${Form}`,
    dto: FashionSiteDTO,
  },
  homepage: {
    query: Homepage,
    query2: `${Form}`,
    dto: HomepageDTO,
  },
  how_it_works_site: {
    query: HowItWorksSite,
    query2: `${BlogArticles} ${Form}`,
    dto: HowItWorksSiteDTO,
  },
  industries_site: {
    query: IndustriesSite,
    query2: `${Form}`,
    dto: IndustriesSiteDTO,
  },
  landing_page: {
    query: LandingPage,
    query2: Form,
    dto: LandingPageDTO,
  },
  legal_pages: {
    query: LegalPages,
    query2: LegalPagesLinks,
    dto: LegalPagesDTO,
  },
  product: {
    query: Products,
    query2: `${Form}`,
    dto: ProductPageDTO,
  },
  orbitvu_page: {
    query: OrbitvuPage,
    query2: `${Form}`,
    dto: OrbitvuPageDTO,
  },
  partnership_page: {
    query: PartnershipPage,
    query2: `${Form}`,
    dto: PartnershipPageDTO,
  },
  products_categories: {
    query: ProductAllCategory,
    query2: `${Form}`,
    dto: ProductAllCategoryPageDTO,
  },
  category: {
    query: ProductCategory,
    query2: `${Form}`,
    dto: ProductCategoryPageDTO,
  },
  software_page: {
    query: SoftwarePage,
    query2: `${Form}`,
    dto: SoftwarePageDTO,
  },
  software_site: {
    query: SoftwareSite,
    query2: `${SoftwareAdditionalNavi} ${Form}`,
    dto: SoftwareSiteDTO,
  },
  support_page: {
    query: SupportPage,
    query2: `${Form}`,
    dto: SupportPageDTO,
  },
  blog_page: {
    query: NodeBlogPage,
    query2: `${BlogArticles} ${Form}`,
    dto: BlogPageDTO,
  },
  blog_allpage: {
    query: BlogArticlesList,
    query2: `${BlogArticles} ${BlogCategories} ${Form}`,
    dto: BlogArticlesListPageDTO,
  },
};

export default types;
