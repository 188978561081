import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphFashionCardSmallItem = graphql`
  fragment ParagraphFashionCardSmallItem on drupalData_ParagraphFashionCardSmallItem {
    fieldTitle
    fieldBookADemoLink {
      title
      url {
        path
      }
    }
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldLearnMoreLink {
      title
      url {
        path
      }
    }
  }
`;

export const ParagraphFashionCardSmallItemPreview = gql`
  ... on ParagraphFashionCardSmallItem {
    fieldTitle
    fieldBookADemoLink {
      title
      url {
        path
      }
    }
    fieldCopy {
      processed
    }
    fieldImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldLearnMoreLink {
      title
      url {
        path
      }
    }
  }
`;