import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogTestimonial = graphql`
  fragment ParagraphBlogTestimonial on drupalData_ParagraphBlogTestimonial {
    entityBundle

    fieldIncludeInTable
    fieldTableOfContentsForBlog

    fieldTestimonialHeadline {
      processed
    }
    fieldTestimonialSubImage {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }
    fieldTestimonialCopy {
      processed
    }
    uuid
  }
`;

export const ParagraphBlogTestimonialPreview = gql`
  ... on ParagraphBlogTestimonial {
    entityBundle

    fieldIncludeInTable
    fieldTableOfContentsForBlog

    fieldTestimonialHeadline {
      processed
    }
    fieldTestimonialSubImage {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }
    fieldTestimonialCopy {
      processed
    }
    uuid
  }
`;