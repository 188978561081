import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphMetatags = graphql`
  fragment ParagraphMetatags on drupalData_ParagraphMetatags {
    entityBundle
    fieldMetaDescription
    fieldMetaImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldMetaKeywords
    fieldMetaTitle
    langcode {
      value
    }
    uuid
  }
`;

export const ParagraphMetatagsPreview = gql`
  ... on ParagraphMetatags {
    entityBundle
    fieldMetaDescription
    fieldMetaImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldMetaKeywords
    fieldMetaTitle
    langcode {
      value
    }
    uuid
  }
`;
