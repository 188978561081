import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphSystemCompatibilityTable = graphql`
  fragment ParagraphSystemCompatibilityTable on drupalData_ParagraphSystemCompatibilityTable {
    entityBundle
    fieldCardWithCollapseTable {
      value
    }
    fieldDictionaryNo
    fieldDictionaryYes
    fieldNotes {
      processed
    }
    uuid
  }
`;

export const ParagraphSystemCompatibilityTablePreview = gql`
  ... on ParagraphSystemCompatibilityTable {
    entityBundle
    fieldCardWithCollapseTable {
      value
    }
    fieldDictionaryNo
    fieldDictionaryYes
    fieldNotes {
      processed
    }
    uuid
  }
`;