import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const blogArticlesList = () => {
  const data = useStaticQuery(graphql`
    query blogArticlesListQuery {
      drupalData {
        blogCategories: taxonomyTermQuery(
          limit: 1000
          filter: { conditions: { field: "vid", value: "blog_category" } }
        ) {
          entities {
            ... on drupalData_TaxonomyTermBlogCategory {
              tid
              name
              path {
                alias
              }
            }
          }
        }
        blogArticles: nodeQuery(
          limit: 10000
          filter: { conditions: { field: "type", value: "blog_page" } }
        ) {
          entities {
            ... on drupalData_NodeBlogPage {
              entityLabel
              fieldBlogCategory {
                entity {
                  ... on drupalData_TaxonomyTermBlogCategory {
                    entityBundle
                    name
                    uuid
                  }
                }
              }
              fieldPublicationDate {
                date
                value
              }
              fieldReadingTime
              fieldShortIntroCopy {
                processed
              }
              fieldTeaserImage {
                alt
                derivative(style: WEBP) {
                  url
                }
                title
                url
              }
              fieldRefAuthor {
                entity {
                  ...NodeAuthor
                }
              }
              fieldSectionBlog {
                entity {
                  ...ParagraphArticle
                  ...ParagraphBlogAlertBox
                  ...ParagraphBlogImage
                  ...ParagraphBlogTextImageVideo
                  ...ParagraphBlogVideo
                  ...ParagraphBlogTestimonial
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldFormOffer
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
        nodeQuery(filter: { conditions: { field: "type", value: "blog_allpage" } }) {
          entities {
            ... on drupalData_NodeBlogAllpage {
              entityBundle
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldSearch
              fieldSearchCategory
              fieldForm {
                entity {
                  uuid
                }
              }
              fieldFormOffer

              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
