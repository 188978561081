import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCtaWhite = graphql`
  fragment ParagraphCtaWhite on drupalData_ParagraphCtaWhite {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;

export const ParagraphCtaWhitePreview = gql`
  ... on ParagraphCtaWhite {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;