import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphRecommendedArticles = graphql`
  fragment ParagraphRecommendedArticles on drupalData_ParagraphRecommendedArticles {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldCategory {
      entity {
        name
      }
    }
  }
`;

export const ParagraphRecommendedArticlesPreview = gql`
  ... on ParagraphRecommendedArticles {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldCategory {
      entity {
        name
      }
    }
  }
`;