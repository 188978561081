import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCtaGrayPreview } from '@graphql/fragments/ParagraphCtaGray';
import { ParagraphCtaGrayFramePreview } from '@graphql/fragments/ParagraphCtaGrayFrame';
import { ParagraphCtaPinkPreview } from '@graphql/fragments/ParagraphCtaPink';
import { ParagraphCtaWhiteFramePreview } from '@graphql/fragments/ParagraphCtaWhiteFrame';

export const ParagraphMainBannerVariant3 = graphql`
  fragment ParagraphMainBannerVariant3 on drupalData_ParagraphMainBannerVariant3 {
    entityBundle
    fieldBackgroundVideo {
      entity {
        url
      }
    }
    entityLabel
    fieldCopyLeft {
      processed
    }
    fieldCta {
      entity {
        entityBundle
        ...ParagraphCtaGray
        ...ParagraphCtaGrayFrame
        ...ParagraphCtaPink
        ...ParagraphCtaWhiteFrame
      }
    }
    fieldTitle
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;

export const ParagraphMainBannerVariant3Preview = gql`
  ... on ParagraphMainBannerVariant3 {
    entityBundle
    fieldBackgroundVideo {
      entity {
        url
      }
    }
    entityLabel
    fieldCopyLeft {
      processed
    }
    fieldCta {
      entity {
        entityBundle
        ${ParagraphCtaGrayPreview}
        ${ParagraphCtaGrayFramePreview}
        ${ParagraphCtaPinkPreview}
        ${ParagraphCtaWhiteFramePreview}
      }
    }
    fieldTitle
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;
