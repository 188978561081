import get from 'lodash.get';

import { IKeyPoints } from '@components/sections/KeyPoints/KeyPoints.d';

export const keyPoints = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IKeyPoints = {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldCopy.processed', ''),
    points: get(data, 'fieldKe', []).map(point => ({
      number: get(point, 'entity.fieldNumber', ''),
      description: get(point, 'entity.fieldCopy.processed', ''),
    }))
  }

  return componentData;
}
