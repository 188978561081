import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphJobContact = graphql`
  fragment ParagraphJobContact on drupalData_ParagraphJobContact {
    entityBundle
    fieldCopy {
      processed
    }
    uuid
  }
`;

export const ParagraphJobContactPreview = gql`
  ... on ParagraphJobContact {
    entityBundle
    fieldCopy {
      processed
    }
    uuid
  }
`;