import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphEventImage = graphql`
  fragment ParagraphEventImage on drupalData_ParagraphEventImage {
    entityBundle
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldImageCaption
    uuid
  }
`;

export const ParagraphEventImagePreview = gql`
  ... on ParagraphEventImage {
    entityBundle
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldImageCaption
    uuid
  }
`;