import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphSoftwarePluginsTable = graphql`
  fragment ParagraphSoftwarePluginsTable on drupalData_ParagraphSoftwarePluginsTable {
    entityBundle
    fieldBackground
    fieldCardWithCollapseTable {
      value
    }
    fieldDictionaryYes
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphSoftwarePluginsTablePreview = gql`
  ... on ParagraphSoftwarePluginsTable {
    entityBundle
    fieldBackground
    fieldCardWithCollapseTable {
      value
    }
    fieldDictionaryYes
    fieldHeadline {
      processed
    }
    uuid
  }
`;