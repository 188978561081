import { gql } from 'graphql-request';

export const Form = gql`
form: nodeQuery (
  filter: {conditions: [{field: "type", value: "forms"}, {field: "status", value: "1"}]}, limit: 10000
) {
  entities {
    ... on NodeForms {
      entityLabel
      fieldTitle
      fieldHeadline
      fieldCopy {
        processed
      }
      fieldCopy2 {
        processed
      }
      fieldFormsRef {
        entity {
          ... on ParagraphFormContainer {
            entityBundle
            fieldFormName
            fieldCtaText
            fieldFormItemRef {
              entity {
                ... on ParagraphFormItem {
                  entityBundle
                  fieldFieldLabel
                  fieldFieldName
                  fieldFieldRequired
                  fieldFieldType
                  fieldPlaceholder
                }
              }
            }
          }
          entityLanguage {
            name
            id
          }
        }
      }
      uuid
      nid
      langcode {
        value
      }
      sticky: entityMultisiteEnabled
      status
      entityMultisiteTranslations {
        entity {
          entityTranslations {
            entityLabel
            entityLanguage {
              id
            }
            ... on Node {
              status
              path {
                alias
              }
            }
          }
        }
      }
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
}`;
