import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogSocialMediaShare = graphql`
  fragment ParagraphBlogSocialMediaShare on drupalData_ParagraphBlogSocialMediaShare {
    entityBundle
    fieldSocialMediaChannels
    fieldTitleShareBlog
    uuid
  }
`;

export const ParagraphBlogSocialMediaSharePreview = gql`
  ... on ParagraphBlogSocialMediaShare {
    entityBundle
    fieldSocialMediaChannels
    fieldTitleShareBlog
    uuid
  }
`;