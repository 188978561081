import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { Paragraph3ImageWithCaptionPreview } from '@graphql/fragments/Paragraph3ImageWithCaption'
import { ParagraphCardTextImageOrVideoPreview } from '@graphql/fragments/ParagraphCardTextImageOrVideo'

export const ParagraphCardTextContextWithCaption = graphql`
  fragment ParagraphCardTextContextWithCaption on drupalData_ParagraphCardTextContextWithCaption {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldImageWithCaption3 {
      entity {
        ...Paragraph3ImageWithCaption
      }
    }
    fieldTextAndImageVideo {
      entity {
        ...ParagraphCardTextImageOrVideo
      }
    }
  }
`;

export const ParagraphCardTextContextWithCaptionPreview = gql`
  ... on ParagraphCardTextContextWithCaption {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldImageWithCaption3 {
      entity {
        ${Paragraph3ImageWithCaptionPreview}
      }
    }
    fieldTextAndImageVideo {
      entity {
        ${ParagraphCardTextImageOrVideoPreview}
      }
    }
  }
`;