import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCopyWithCta = graphql`
  fragment ParagraphCopyWithCta on drupalData_ParagraphCopyWithCta {
    entityBundle
    fieldCopy {
      processed
    }
    fieldContactPage {
      entity {
        ... on drupalData_NodeContactPage {
          title
          path {
            alias
          }
        }
      }
    }
    fieldHeadline {
      processed
    }
  }
`;

export const ParagraphCopyWithCtaPreview = gql`
  ... on ParagraphCopyWithCta {
    entityBundle
    fieldCopy {
      processed
    }
    fieldContactPage {
      entity {
        ... on NodeContactPage {
          title
          path {
            alias
          }
        }
      }
    }
    fieldHeadline {
      processed
    }
  }
`;