import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphSlider = graphql`
  fragment ParagraphSlider on drupalData_ParagraphSlider {
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldSlider {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;

export const ParagraphSliderPreview = gql`
  ... on ParagraphSlider {
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldSlider {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;
