import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphAccessoriesUrl = graphql`
  fragment ParagraphAccessoriesUrl on drupalData_ParagraphAccessoriesUrl {
    entityBundle
    fieldDescription {
      processed
    }
    fieldLinkCta {
      url {
        path
      }
      title
    }
    uuid
  }
`;

export const ParagraphAccessoriesUrlPreview = gql`
  ... on ParagraphAccessoriesUrl {
    entityBundle
    fieldDescription {
      processed
    }
    fieldLinkCta {
      url {
        path
      }
      title
    }
    uuid
  }
`;
