import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphContactPartnersData = graphql`
  fragment ParagraphContactPartnersData on drupalData_ParagraphContactPartnersData {
    entityBundle
    fieldCountry
    fieldEMail
    fieldOfficeName
    fieldPhone
    uuid
  }
`;

export const ParagraphContactPartnersDataPreview = gql`
  ... on ParagraphContactPartnersData {
    entityBundle
    fieldCountry
    fieldEMail
    fieldOfficeName
    fieldPhone
    uuid
  }
`;