import get from 'lodash.get';

// === Data === //
import { homepageData } from '@graphql/Homepage';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { bigNumbers } from '@DTO/sections/BigNumbers';
import { copyImageGrid2x2 } from '@DTO/sections/CopyImageGrid2x2';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { cardSmallCarousel } from '@DTO/sections/CardSmallCarousel';
import { gallery } from '@DTO/sections/Gallery';
import { conversionWith2CTA } from '@DTO/sections/ConversionWith2CTA';
import { ourCustomerLogo } from '@DTO/sections/OurCustomerLogo';
import { conversionWithCTA } from '@DTO/sections/ConversionWithCTA';
import { industriesGrid } from '@DTO/sections/IndustriesGrid';
import { metatags } from '@DTO/sections/Metatags';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';

export const HomepageDTO = (uuid?: string, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const homepageDataQuery = homepageData();
    currentPageData = homepageDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      bigNumbers(nodeEntity, 'fieldNumericalBenefits'),
      copyImageGrid2x2(nodeEntity, 'fieldCopyImageGrid2x2'),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      cardSmallCarousel(nodeEntity, 'fieldCardSmallCarousel'),
      gallery(nodeEntity, 'fieldGallery'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta'),
      cardTextImage(nodeEntity, 'fieldCardTextImage2'),
      ourCustomerLogo(nodeEntity, 'fieldOurCustomerLogo'),
      conversionWithCTA(nodeEntity, 'fieldConversionWithCta'),
      industriesGrid(nodeEntity, 'fieldIndustriesGrid'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta2'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
