import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';

export const ParagraphCareerAboutOrbitvu = graphql`
  fragment ParagraphCareerAboutOrbitvu on drupalData_ParagraphCareerAboutOrbitvu {
    entityBundle
    fieldCardTextImage {
      entity {
        ...ParagraphCardTextImage
      }
    }
    fieldCopy {
      processed
    }
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldImageCarousel {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldVariantVideo
    fieldVideo {
      description
      entity {
        filename
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;

export const ParagraphCareerAboutOrbitvuPreview = gql`
  ... on ParagraphCareerAboutOrbitvu {
    entityBundle
    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldCopy {
      processed
    }
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldImageCarousel {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldVariantVideo
    fieldVideo {
      description
      entity {
        filename
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;
