import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphIconWithCopyPreview } from '@graphql/fragments/ParagraphIconWithCopy';

export const ParagraphLpImageWithIconCopy = graphql`
  fragment ParagraphLpImageWithIconCopy on drupalData_ParagraphLpImageWithIconCopy {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ...ParagraphIconWithCopy
      }
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldVariantImage
    uuid
  }
`;

export const ParagraphLpImageWithIconCopyPreview = gql`
  ... on ParagraphLpImageWithIconCopy {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphIconWithCopyPreview}
      }
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldVariantImage
    uuid
  }
`;