import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphTimeline = graphql`
  fragment ParagraphTimeline on drupalData_ParagraphTimeline {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldBackground
    fieldDescription {
      processed
    }
    fieldVariantOfTheNumber
    fieldTimelineItem {
      entity {
        ... on drupalData_ParagraphTimelineItem {
          entityId
          fieldTitle
          fieldNumber
          fieldImage {
            derivative(style: WEBP) {
              url
            }
            alt
            title
            url
          }
          fieldCopy {
            processed
          }
        }
      }
    }
  }
`;

export const ParagraphTimelinePreview = gql`
  ... on ParagraphTimeline {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldBackground
    fieldDescription {
      processed
    }
    fieldVariantOfTheNumber
    fieldTimelineItem {
      entity {
        ... on ParagraphTimelineItem {
          entityId
          fieldTitle
          fieldNumber
          fieldImage {
            derivative(style: WEBP) {
              url
            }
            alt
            title
            url
          }
          fieldCopy {
            processed
          }
        }
      }
    }
  }
`;