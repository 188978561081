import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphArticle = graphql`
  fragment ParagraphArticle on drupalData_ParagraphArticle {
    entityBundle
    fieldBlogCopy {
      processed
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    uuid
  }
`;

export const ParagraphArticlePreview = gql`
  ... on ParagraphArticle {
    entityBundle
    fieldBlogCopy {
      processed
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    uuid
  }
`;