import { gql } from 'graphql-request';

import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphCardTextImage2Preview } from '@graphql/fragments/ParagraphCardTextImage2';
import { ParagraphCareerAboutOrbitvuPreview } from '@graphql/careers/ParagraphCareerAboutOrbitvu';
import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphDepartmentsWithJobOffersListPreview } from '@graphql/fragments/ParagraphDepartmentsWithJobOffersList';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphOurCustomerLogoPreview } from '@graphql/fragments/ParagraphOurCustomerLogo';
import { ParagraphCareersIntroPreview } from '@graphql/careers/ParagraphCareersIntro';

export const Career = gql`
  ... on NodeCareersPage {
    entityLabel
    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldCardTextImage2 {
      entity {
        ${ParagraphCardTextImage2Preview}
      }
    }
    fieldCareerAboutOrbitvu {
      entity {
        ${ParagraphCareerAboutOrbitvuPreview}
      }
    }
    fieldContextImageWithCaption {
      entity {
        ${ParagraphContextImageWithCaptionPreview}
      }
    }
    fieldDepartmentsWithJobOffer {
      entity {
        ${ParagraphDepartmentsWithJobOffersListPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldOurCustomerLogo {
      entity {
        ${ParagraphOurCustomerLogoPreview}
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;

export const CareerDetail = gql`
allOffers: nodeQuery (
  filter: { conditions: { field: "type", value: "careers_details" } }, limit: 10000
) {
  entities {
    ... on NodeCareersDetails {
      entityLabel
      fieldCareersIntro {
        entity {
          ${ParagraphCareersIntroPreview}
        }
      }
      path {
        alias
      }
      title
      uuid
      langcode {
        value
      }
      sticky: entityMultisiteEnabled
      status
      entityMultisiteTranslations {
        entity {
          entityTranslations {
            entityLabel
            entityLanguage {
              id
            }
            ... on Node {
              status
              path {
                alias
              }
            }
          }
        }
      }
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
}
`;

export const Taxonomy = gql`
taxonomyTermQuery(
  filter: { conditions: { field: "vid", value: "departments_with_job" } },
  limit: 1000
) {
  entities {
    ... on TaxonomyTermDepartmentsWithJob {
      entityLabel
      fieldDescription {
        processed
      }
      fieldHeadline

      name
      weight
    }
  }
}
`;
