import get from 'lodash.get';

export const blogCategoriesMenu = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entities`, undefined);

  const componentData = {
    type: 'blog_categories_menu', // wrapper doesn't have own entityBundle type
    items: data.map(item => ({
      id: get(item, `tid`, null),
      name: get(item, `name`, ''),
      path: get(item, `path.alias`, '/'),
    }))
  }

  return componentData;
}