// extracted by mini-css-extract-plugin
export var active = "Pagination-module--active--P8S48";
export var arrow = "Pagination-module--arrow--nGUpD";
export var arrowButton = "Pagination-module--arrow-button--llfMA";
export var arrowText = "Pagination-module--arrow-text--Nqf5a";
export var button = "Pagination-module--button--y+rC+";
export var disabled = "Pagination-module--disabled--D4xpS";
export var dots = "Pagination-module--dots--hAyGr";
export var flexCenter = "Pagination-module--flex-center---FIWs";
export var mirror = "Pagination-module--mirror--3sBHS";
export var nextArrow = "Pagination-module--next-arrow--ENFn9";
export var pageNumber = "Pagination-module--page-number--n8ZSf";
export var pagination = "Pagination-module--pagination--V1Bii";
export var prevArrow = "Pagination-module--prev-arrow--EvacW";