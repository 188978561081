import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphVideoPreview } from '@graphql/fragments/ParagraphVideo';

export const ParagraphLpVideo2 = graphql`
  fragment ParagraphLpVideo2 on drupalData_ParagraphLpVideo2 {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldVideoItem {
      entity {
        ...ParagraphVideo
      }
    }
    uuid
  }
`;

export const ParagraphLpVideo2Preview = gql`
  ... on ParagraphLpVideo2 {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldVideoItem {
      entity {
        ${ParagraphVideoPreview}
      }
    }
    uuid
  }
`;