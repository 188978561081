import get from 'lodash.get';

// === Data === //
import { productAllCategoryData } from '@graphql/ProductAllCategory';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { conversionWith2CTA } from '@DTO/sections/ConversionWith2CTA';
import { faq } from '@DTO/sections/FAQ';
import { submenuAllCategory } from '@DTO/sections/SubmenuAllCategory';
import { keyPoints } from '@DTO/sections/KeyPoints';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { metatags } from '@DTO/sections/Metatags';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const ProductAllCategoryPageDTO = (
  uuid: string | undefined,
  data?: { drupalData: unknown }
) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const productAllCategoryDataQuery = productAllCategoryData();
    currentPageData = productAllCategoryDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      submenuAllCategory(nodeEntity, 'fieldCategory', [
        'fieldSectionStillProduct',
        'fieldSection360deg3dProduct',
        'fieldSectionLiveModel',
        'fieldSectionFlatLayPhoto',
      ]),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      keyPoints(nodeEntity, 'fieldKeyPoints'),
      faq(nodeEntity, 'fieldFaq'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
