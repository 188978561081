import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCtaWhiteFrame = graphql`
  fragment ParagraphCtaWhiteFrame on drupalData_ParagraphCtaWhiteFrame {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;

export const ParagraphCtaWhiteFramePreview = gql`
  ... on ParagraphCtaWhiteFrame {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;
