import get from 'lodash.get';

// === Data === //
import { partnershipPageData } from '@graphql/PartnershipPage';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { mainBanner } from '@DTO/sections/MainBanner';
import { bigNumbers } from '@DTO/sections/BigNumbers';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { ourCustomerLogo } from '@DTO/sections/OurCustomerLogo';
import { partnership2x2 } from '@DTO/sections/Partnership2x2';
import { pageForm } from '@DTO/sections/PageForm';
import { contextImageWithCaption } from '@DTO/sections/ContextImageWithCaption';
import { conversionWith2CTA } from '@DTO/sections/ConversionWith2CTA';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const PartnershipPageDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const dataQuery = partnershipPageData();
    currentPageData = dataQuery;
  } else {
    currentPageData = data;
  }

  const additionalData = data
    ? {
        form: get(currentPageData, 'drupalData.form'),
      }
    : undefined;
  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      bigNumbers(nodeEntity, 'fieldNumericalBenefits'),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      ourCustomerLogo(nodeEntity, 'fieldOurCustomerLogo'),
      partnership2x2(nodeEntity, 'fieldPartnership2x2'),
      pageForm(nodeEntity, 'fieldPartnershipContact', additionalData),
      contextImageWithCaption(nodeEntity, 'fieldContextImageWithCaption'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
