import { gql } from 'graphql-request';

import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphArticlePreview } from '@graphql/blog/fragments/ParagraphArticle';
import { ParagraphBlogAlertBoxPreview } from '@graphql/blog/fragments/ParagraphBlogAlertBox';
import { ParagraphBlogImagePreview } from '@graphql/blog/fragments/ParagraphBlogImage';
import { ParagraphBlogTextImageVideoPreview } from '@graphql/blog/fragments/ParagraphBlogTextImageVideo';
import { ParagraphBlogVideoPreview } from '@graphql/blog/fragments/ParagraphBlogVideo';
import { ParagraphBlogTestimonialPreview } from '@graphql/blog/fragments/ParagraphTestimonial';
import { NodeAuthorPreview } from '@graphql/blog/fragments/NodeAuthor';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';

export const BlogArticlesList = gql`
  ... on NodeBlogAllpage {
    entityBundle
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldSearch
    fieldSearchCategory
    fieldForm {
      entity {
        uuid
      }
    }
    fieldFormOffer

    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;

export const BlogCategories = gql`
blogCategories: taxonomyTermQuery(
  limit: 1000
  filter: {conditions: {field: "vid", value: "blog_category"}}
) {
  entities {
    ... on TaxonomyTermBlogCategory {
      tid
      name
      path {
        alias
      }
    }
  }    
}
`;

export const BlogArticles = gql`
blogArticles: nodeQuery (
  limit: 10000,
  filter: { conditions: { field: "type", value: "blog_page" } }
) {
  entities {
    ... on NodeBlogPage {
      entityLabel
      fieldBlogCategory {
        entity {
          ... on TaxonomyTermBlogCategory {
            entityBundle
            name
            uuid
          }
        }
      }
      fieldPublicationDate {
        date
        value
      }
      fieldReadingTime
      fieldShortIntroCopy {
        processed
      }
      fieldTeaserImage {
        alt
        derivative(style: WEBP) {
          url
        }
        title
        url
      }
      fieldRefAuthor {
        entity {
          ${NodeAuthorPreview}
        }
      }
      fieldSectionBlog {
        entity {
          ${ParagraphArticlePreview}
          ${ParagraphBlogAlertBoxPreview}
          ${ParagraphBlogImagePreview}
          ${ParagraphBlogTextImageVideoPreview}
          ${ParagraphBlogVideoPreview}
          ${ParagraphBlogTestimonialPreview}
        }
      }
      fieldMetatags {
        entity {
          ${ParagraphMetatagsPreview}
        }
      }
      fieldFormOffer
      path {
        alias
      }
      title
      uuid
      langcode {
        value
      }
      sticky: entityMultisiteEnabled
      status
      entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
    }
  }
}
`;
