import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const Paragraph3ImageWithCaption = graphql`
  fragment Paragraph3ImageWithCaption on drupalData_Paragraph3ImageWithCaption {
    entityBundle
    fieldDescription {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
  }
`;

export const Paragraph3ImageWithCaptionPreview = gql`
  ... on Paragraph3ImageWithCaption {
    entityBundle
    fieldDescription {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
  }
`;
