import { request, gql, GraphQLClient } from 'graphql-request';
import { globalTranslationsData } from '@graphql/preview/GlobalTranslations';

const makeRequest = async (uuid: string | boolean, url: string, query: string) => {
  const client = new GraphQLClient(url, {
    mode: 'cors',
    headers: { 'Access-Control-Allow-Origin': '*' },
  });
  try {
    const response = await client.request(query, uuid ? { uuid } : null);
    return response;
  } catch (e) {
    console.warn(e);
    return false;
  }
};

export const getPageType = async (uuid: string, url: string) => {
  const query = gql`
    query previewType($uuid: String!) {
      preview: nodePreviewByUuid(uuid: $uuid) {
        type: entityBundle
      }
    }
  `;

  return await makeRequest(uuid, url, query);
};

export const previewData = async (
  siteQuery: string,
  additionalQueries: string,
  uuid: string,
  url: string
) => {
  const query = gql`
    query previewQuery($uuid: String!) {
      nodeQuery: nodePreviewByUuid(uuid: $uuid) {
        ${siteQuery}
      }
      ${additionalQueries}
    }
  `;

  return await makeRequest(uuid, url, query);
};

export const getGlobalTranslations = async (url: string) => {
  const query = gql`
    query globalTranslations {
      ${globalTranslationsData}
    }
  `;
  return await makeRequest(false, url, query);
};
