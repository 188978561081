import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphProductSmallCardPreview } from '@graphql/fragments/ParagraphProductSmallCard'

export const ParagraphCardSmallOnGrid4x2 = graphql`
  fragment ParagraphCardSmallOnGrid4x2 on drupalData_ParagraphCardSmallOnGrid4x2 {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldDescription {
      processed
    }
    fieldProductsAvailable {
      entity {
        ... on drupalData_NodeProduct {
          entityBundle
          entityId
          fieldSmallCard {
            entity {
              ... ParagraphProductSmallCard
            }
          }
          path {
            alias
          }
          title
        }
      }
    }
    fieldProductSelect {
      entity {
        entityBundle
        ... on drupalData_ParagraphProductsAvailableSelect {
          entityBundle
          fieldProductsAvailableSelect {
            entity {
              ... on drupalData_NodeProduct {
                entityPublished
                entityBundle
                fieldSmallCard {
                  entity {
                    ... ParagraphProductSmallCard
                  }
                }
                path {
                  alias
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const ParagraphCardSmallOnGrid4x2Preview = gql`
  ... on ParagraphCardSmallOnGrid4x2 {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldDescription {
      processed
    }
    fieldProductsAvailable {
      entity {
        ... on NodeProduct {
          entityBundle
          entityId
          fieldSmallCard {
            entity {
              ${ParagraphProductSmallCardPreview}
            }
          }
          path {
            alias
          }
          title
        }
      }
    }
    fieldProductSelect {
      entity {
        entityBundle
        ... on ParagraphProductsAvailableSelect {
          entityBundle
          fieldProductsAvailableSelect {
            entity {
              ... on NodeProduct {
                entityPublished
                entityBundle
                fieldSmallCard {
                  entity {
                    ${ParagraphProductSmallCardPreview}
                  }
                }
                path {
                  alias
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;