import { gql } from 'graphql-request';

import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphCardTextImage2Preview } from '@graphql/fragments/ParagraphCardTextImage2';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphProductIconWithCopyPreview } from '@graphql/fragments/ParagraphProductIconWithCopy';
import { ParagraphConversion2Cta2Preview } from '@graphql/fragments/ParagraphConversion2Cta2';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphOurCustomerLogoPreview } from '@graphql/fragments/ParagraphOurCustomerLogo';
import { ParagraphRecommendedArticlesPreview } from '@graphql/fragments/ParagraphRecommendedArticles';
import { ParagraphTimelinePreview } from '@graphql/fragments/ParagraphTimeline';
import { ParagraphVideoCardPreview } from '@graphql/fragments/ParagraphVideoCard';
import { ParagraphArticlePreview } from '@graphql/blog/fragments/ParagraphArticle';
import { ParagraphBlogAlertBoxPreview } from '@graphql/blog/fragments/ParagraphBlogAlertBox';
import { ParagraphBlogImagePreview } from '@graphql/blog/fragments/ParagraphBlogImage';
import { ParagraphBlogTextImageVideoPreview } from '@graphql/blog/fragments/ParagraphBlogTextImageVideo';
import { ParagraphBlogVideoPreview } from '@graphql/blog/fragments/ParagraphBlogVideo';
import { ParagraphBlogTestimonialPreview } from '@graphql/blog/fragments/ParagraphTestimonial';
import { NodeAuthorPreview } from '@graphql/blog/fragments/NodeAuthor';

export const HowItWorksSite = gql`
  ... on NodeHowItWorksSite {
    entityLabel

    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldCardTextImage2 {
      entity {
        ${ParagraphCardTextImage2Preview}
      }
    }
    fieldContextImageWithCaption {
      entity {
        ${ParagraphContextImageWithCaptionPreview}
      }
    }
    fieldConversion2Cta {
      entity {
        ${ParagraphConversion2CtaPreview}
      }
    }
    fieldConversion2Cta2 {
      entity {
        ${ParagraphConversion2Cta2Preview}
      }
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphProductIconWithCopyPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldOurCustomerLogo {
      entity {
        ${ParagraphOurCustomerLogoPreview}
      }
    }
    fieldRecommendedArticles {
      entity {
        ${ParagraphRecommendedArticlesPreview}
      }
    }
    fieldTimeline {
      entity {
        ${ParagraphTimelinePreview}
      }
    }
    fieldVideoCard {
      entity {
        ${ParagraphVideoCardPreview}
      }
    }
    fieldCategoryA {
      entity {
        title
        path {
          alias
        }
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }

    # important !!!
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
