import get from 'lodash.get';
import { carouselData } from '@helpers/smallCarousel';

export const cardSmallOnGrid4x2 = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldDescription.processed', ''),
    products: carouselData(
      get(data, 'fieldProductsAvailable', []),
      get(data, 'fieldProductSelect', [])
    ),
  }
}
