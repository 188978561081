import React from 'react';

// === Components === //
import PageComponents from '@components/layout/PageComponents/PageComponents';
import LpComponents from '@components/layout/LpComponents/LpComponents';
import LegalPagesComponents from '@components/layout/LegalPagesComponents/LegalPagesComponents';
import ContactComponents from '@components/layout/ContactComponents/ContactComponents';
import BlogComponents from '@components/layout/BlogComponents/BlogComponents';
import BlogArticlesListComponents from '@components/layout/BlogArticlesListComponents/BlogArticlesListComponents';
import ErrorPagesComponents from '@components/layout/ErrorPagesComponents/ErrorPagesComponents';

// === Helpers === //
import Loader from '@components/common/Loader/Loader';

// === Styles === //
import * as styles from '@components/preview/Preview.module.scss';

// === Types === //
import { IPreview } from '@components/preview/Preview.d';

const Preview = ({ pageData, pageType, loading }: IPreview) => {
  const defaultErrorData: ErrorPagesDataData = {
    copy: '',
    copy2: '',
    copy3: '',
    cta: {
      title: '',
      url: '/',
    },
    headline: '',
    subHeadline: '',
  };

  return (
    <div>
      {!loading ? (
        <>
          {(pageType && pageData.components?.length > 0 && pageType !== '') ||
          (pageType && pageType === '404_page') ? (
            <>
              {pageType === '404_page' ? (
                <ErrorPagesComponents data={pageData.data || defaultErrorData} />
              ) : (
                <>
                  {!pageType.includes('contact') &&
                    !pageType.includes('landing_page') &&
                    !pageType.includes('blog') && (
                      <PageComponents
                        type={(pageType === 'products_categories' && 'productDetail') || 'default'}
                        components={pageData.components}
                      />
                    )}
                  {pageType.includes('landing_page') && (
                    <LpComponents components={pageData.components} />
                  )}
                  {pageType.includes('legal') && (
                    <LegalPagesComponents
                      components={pageData.components}
                      pageTitle={pageData.title}
                    />
                  )}
                  {pageType.includes('contact') && (
                    <ContactComponents components={pageData.components} type="contact" />
                  )}
                  {pageType.includes('blog_page') && (
                    <BlogComponents components={pageData.components} />
                  )}
                  {pageType.includes('blog_allpage') && (
                    <BlogArticlesListComponents components={pageData.components} />
                  )}
                </>
              )}
            </>
          ) : (
            <div className={styles.inner}>
              Unfortunately, the ID doesn't return any data. Check if the ID is correct and try
              again.
            </div>
          )}
        </>
      ) : (
        <div className={styles.inner}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Preview;
