import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphProductSmallCardPreview } from '@graphql/fragments/ParagraphProductSmallCard';

export const ParagraphBlogProductsCarousel = graphql`
  fragment ParagraphBlogProductsCarousel on drupalData_ParagraphBlogProductsCarousel {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldIncludeInTable
    fieldProductsAvailable {
      entity {
        ... on drupalData_NodeProduct {
          fieldSmallCard {
            entity {
              ...ParagraphProductSmallCard
            }
          }
          path {
            alias
          }
        }
        title
      }
    }
    fieldProductSelect {
      entity {
        entityBundle
        ... on drupalData_ParagraphProductsAvailableSelect {
          entityBundle
          fieldProductsAvailableSelect {
            entity {
              ... on drupalData_NodeProduct {
                entityPublished
                entityBundle
                fieldSmallCard {
                  entity {
                    ... ParagraphProductSmallCard
                  }
                }
                path {
                  alias
                }
                title
              }
            }
          }
        }
      }
    }
    fieldTableOfContentsForBlog
    uuid
  }
`;

export const ParagraphBlogProductsCarouselPreview = gql`
  ... on ParagraphBlogProductsCarousel {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldIncludeInTable
    fieldProductsAvailable {
      entity {
        ... on NodeProduct {
          fieldSmallCard {
            entity {
              ${ParagraphProductSmallCardPreview}
            }
          }
          path {
            alias
          }
        }
        title
      }
    }
    fieldProductSelect {
      entity {
        entityBundle
        ... on ParagraphProductsAvailableSelect {
          entityBundle
          fieldProductsAvailableSelect {
            entity {
              ... on NodeProduct {
                entityPublished
                entityBundle
                fieldSmallCard {
                  entity {
                    ${ParagraphProductSmallCardPreview}
                  }
                }
                path {
                  alias
                }
                title
              }
            }
          }
        }
      }
    }
    fieldTableOfContentsForBlog
    uuid
  }
`;