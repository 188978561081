import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCardSmallCarouselPreview } from '@graphql/fragments/ParagraphCardSmallCarousel';
import { ParagraphCardWithProductsDetailsPreview } from '@graphql/fragments/ParagraphCardWithProductsDetails';

export const ParagraphSectionStillProductPhotograph = graphql`
  fragment ParagraphSectionStillProductPhotograph on drupalData_ParagraphSectionStillProductPhotograph {
    entityBundle
    fieldCardSmallCarousel {
      entity {
        ...ParagraphCardSmallCarousel
      }
    }
    fieldCardWithProductsDetails {
      entity {
        ...ParagraphCardWithProductsDetails
      }
    }
    fieldSubmenu
    uuid
  }
`;

export const ParagraphSectionStillProductPhotographPreview = gql`
  ... on ParagraphSectionStillProductPhotograph {
    entityBundle
    fieldCardSmallCarousel {
      entity {
        ${ParagraphCardSmallCarouselPreview}
      }
    }
    fieldCardWithProductsDetails {
      entity {
        ${ParagraphCardWithProductsDetailsPreview}
      }
    }
    fieldSubmenu
    uuid
  }
`;