import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphCopyWithCtaPreview } from '@graphql/fragments/ParagraphCopyWithCta';
import { ParagraphFullScreenImagePreview } from '@graphql/fragments/ParagraphFullScreenImage';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { gql } from 'graphql-request';

export const SoftwarePage = gql`
... on NodeSoftwarePage {
  entityLabel

  fieldMetatags {
    entity {
      ${ParagraphMetatagsPreview}
    }
  }
  fieldMainBanner {
    entity {
      ${ParagraphMainBannerVariant1Preview}
      ${ParagraphMainBannerVariant2Preview}
      ${ParagraphMainBannerVariant3Preview}
    }
  }
  fieldFullScreenImage {
    entity {
      ${ParagraphFullScreenImagePreview}
    }
  }
  fieldCardTextImage {
    entity {
      ${ParagraphCardTextImagePreview}
    }
  }
  fieldCopyWithCta {
    entity {
      ${ParagraphCopyWithCtaPreview}
    }
  }
  fieldFormOffer
  fieldForm {
    entity {
      uuid
    }
  }

  path {
    alias
  }
  title
  uuid
  langcode {
    value
  }
  sticky: entityMultisiteEnabled
  status
  entityMultisiteTranslations {
    entity {
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
  entityTranslations {
    entityLabel
    entityLanguage {
      id
    }
    ... on Node {
      status
      path {
        alias
      }
    }
  }
}
`;
