import get from 'lodash.get';

import { ICardTextContextWithCaption } from '@components/sections/CardTextContextWithCaption/CardTextContextWithCaption.d';

export const cardTextContextWithCaption = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: ICardTextContextWithCaption = {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    headline: get(data, 'fieldHeadline.processed', ''),
    cardTextImage: get(data, 'fieldTextAndImageVideo', undefined)
      ? {
          headline: get(data, 'fieldTextAndImageVideo.entity.fieldHeadline.processed', ''),
          subheadline: get(data, 'fieldTextAndImageVideo.entity.fieldSubheadline', ''),
          description: get(data, 'fieldTextAndImageVideo.entity.fieldCopy.processed', ''),
          image: get(data, 'fieldTextAndImageVideo.entity.fieldImage', undefined)
            ? {
                alt: get(data, 'fieldTextAndImageVideo.entity.fieldImage.alt', ''),
                title: get(data, 'fieldTextAndImageVideo.entity.fieldImage.title', ''),
                url: get(data, 'fieldTextAndImageVideo.entity.fieldImage.url', ''),
                webp: get(data, 'fieldTextAndImageVideo.entity.fieldImage.derivative.url', ''),
              }
            : undefined,
          cta: get(data, 'fieldTextAndImageVideo.entity.fieldLinkCta', undefined)
            ? {
                label: get(data, 'fieldTextAndImageVideo.entity.fieldLinkCta.title', ''),
                url: get(data, 'fieldTextAndImageVideo.entity.fieldLinkCta.url.path', ''),
              }
            : undefined,
          video: get(data, 'fieldTextAndImageVideo.entity.fieldVideo.entity.url', undefined),
          videoUrl: get(data, 'fieldTextAndImageVideo.entity.fieldVideoUrl.url.path', undefined),
          label: get(data, 'fieldTextAndImageVideo.entity.fieldLabel', undefined)
            ? {
                title: get(data, 'fieldTextAndImageVideo.entity.fieldLabel', ''),
                url: get(data, 'fieldTextAndImageVideo.entity.fieldLabelLink.url.path', ''),
                color: get(data, 'fieldTextAndImageVideo.entity.fieldLabelColor', null),
              }
            : undefined,
          imageOrVideo: get(data, 'fieldTextAndImageVideo.entity.fieldSelectImageOrVideo', 'image'),
          type: '',
          reverse: true,
          poster: get(data, 'fieldTextAndImageVideo.entity.fieldPoster', undefined)
            ? {
                alt: get(data, 'fieldTextAndImageVideo.entity.fieldPoster.alt', ''),
                title: get(data, 'fieldTextAndImageVideo.entity.fieldPoster.title', ''),
                url: get(data, 'fieldTextAndImageVideo.entity.fieldPoster.url', ''),
                webp: get(data, 'fieldTextAndImageVideo.entity.fieldPoster.derivative.url'),
              }
            : undefined,
          schemaData: get(data, 'fieldTextAndImageVideo.entity.fieldName', undefined)
            ? {
                name: get(data, 'fieldTextAndImageVideo.entity.fieldName', ''),
                duration: get(data, 'fieldTextAndImageVideo.entity.fieldVideoDuration', ''),
                description: get(data, 'fieldTextAndImageVideo.entity.fieldVideoDescription', ''),
                uploadDate: get(data, 'fieldTextAndImageVideo.entity.entityCreated', ''),
              }
            : undefined,
        }
      : undefined,
    imagesWithCaption: get(data, 'fieldImageWithCaption3', []).map((item) => ({
      title: '',
      copy: get(item, 'entity.fieldDescription.processed', ''),
      image: get(item, 'entity.fieldImage', undefined)
        ? {
            alt: get(item, 'entity.fieldImage.alt', ''),
            title: get(item, 'entity.fieldImage.title', ''),
            url: get(item, 'entity.fieldImage.url', ''),
            webp: get(item, 'entity.fieldImage.derivative.url', ''),
          }
        : undefined,
      cta: undefined,
    })),
  };

  return componentData;
};
