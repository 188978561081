import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphFaq = graphql`
  fragment ParagraphProductSmallCard on drupalData_ParagraphProductSmallCard {
    id
    fieldCardCopyNotHtml
    fieldCtaText
    fieldImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldDevicePrice
    fieldIdCalculate
    fieldMax
  }
`;

export const ParagraphProductSmallCardPreview = gql`
  ... on ParagraphProductSmallCard {
    id
    fieldCardCopyNotHtml
    fieldCtaText
    fieldImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldDevicePrice
    fieldIdCalculate
    fieldMax
  }
`;