import get from 'lodash.get';

// === Data === //
import { aboutUsData } from '@graphql/AboutUs';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { ourCustomerLogo } from '@DTO/sections/OurCustomerLogo';
import { metatags } from '@DTO/sections/Metatags';
import { iconWithCopy } from '@DTO/sections/IconWithCopy';
import { video } from '@DTO/sections/Video';
import { contextImageWithCaption } from '@DTO/sections/ContextImageWithCaption';
import { employees } from '@DTO/sections/Employees';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { languagePath } from '@DTO/sections/LanguagePath';

export const AboutUsDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const aboutUsDataQuery = aboutUsData();
    currentPageData = aboutUsDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      ourCustomerLogo(nodeEntity, 'fieldOurCustomerLogo'),
      cardTextImage(nodeEntity, 'fieldCardTextImage2'),
      iconWithCopy(nodeEntity, 'fieldIconWithCopy', true),
      video(nodeEntity, 'fieldVideo'),
      iconWithCopy(nodeEntity, 'fieldIconWithCopy2', true),
      employees(nodeEntity, 'fieldEmployees'),
      cardTextImage(nodeEntity, 'fieldCardTextImage3'),
      contextImageWithCaption(nodeEntity, 'fieldContextImageWithCaption'),
    ],
  };

  return {
    ...queryData,
  };
};
