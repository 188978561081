import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphLpHeaderVariant1Preview } from '@graphql/lp/ParagraphLpHeaderVariant1';
import { ParagraphLpHeaderVariant2Preview } from '@graphql/lp/ParagraphLpHeaderVariant2';

export const ParagraphLpHeader = graphql`
  fragment ParagraphLpHeader on drupalData_ParagraphLpHeader {
    entityBundle
    fieldBackgroundImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldBackgroundImageMobile {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldLpHeaderVariant {
      entity {
        ...ParagraphLpHeaderVariant1
        ...ParagraphLpHeaderVariant2
      }
    }
    fieldLpLogo {
      alt
      title
      entity {
        url
      }
    }
    fieldAlpha

    uuid
  }
`;

export const ParagraphLpHeaderPreview = gql`
  ... on ParagraphLpHeader {
    entityBundle
    fieldBackgroundImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldBackgroundImageMobile {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldLpHeaderVariant {
      entity {
        ${ParagraphLpHeaderVariant1Preview}
        ${ParagraphLpHeaderVariant2Preview}
      }
    }
    fieldLpLogo {
      alt
      title
      entity {
        url
      }
    }
    fieldAlpha

    uuid
  }
`;