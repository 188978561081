import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphFormProduct = graphql`
  fragment ParagraphFormProduct on drupalData_ParagraphFormProduct {
    entityBundle
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldAnchorId
  }
`;

export const ParagraphFormProductPreview = gql`
  ... on ParagraphFormProduct {
    entityBundle
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldAnchorId
  }
`;
