import { IJobOfferDepartment } from '@components/sections/Career/JobOfferDepartment/JobOfferDepartment.d';
import get from 'lodash.get';

export const jobOfferDepartment = (nodeData, displayFields) => {
  const componentData: IJobOfferDepartment = {
    type: 'job_offer_department',
    displayFields: displayFields,
    contact: get(nodeData, 'fieldContact')
      ? {
          title: get(nodeData, 'fieldContact.entity.fieldTitle', ''),
          description: get(nodeData, 'fieldContact.entity.fieldDescription.processed', ''),
          cta: {
            title: get(nodeData, 'fieldContact.entity.fieldLinkCta.title', ''),
            url: get(nodeData, 'fieldContact.entity.fieldLinkCta.url.path', ''),
          },
        }
      : undefined,
    description: get(nodeData, 'fieldDescription.processed', ''),
    headline: get(nodeData, 'fieldHeadline', ''),
    video: {
      alt: get(nodeData, 'fieldVideo.entity.fieldPoster.alt' || ''),
      title: get(nodeData, 'fieldVideo.entity.fieldPoster.title' || ''),
      url: get(nodeData, 'fieldVideo.entity.fieldVideoUrl.url.path' || ''),
      poster: {
        alt: get(nodeData, 'fieldVideo.entity.fieldPoster.alt' || ''),
        title: get(nodeData, 'fieldVideo.entity.fieldPoster.title' || ''),
        url: get(nodeData, 'fieldVideo.entity.fieldPoster.url' || ''),
        webp: get(nodeData, 'fieldVideo.entity.fieldPoster.derivative.url' || ''),
      },
      schemaData: get(nodeData, 'fieldVideo.entity.fieldName', undefined)
        ? {
            name: get(nodeData, 'fieldVideo.entity.fieldName', ''),
            duration: get(nodeData, 'fieldVideo.entity.fieldVideoDuration', ''),
            description: get(nodeData, 'fieldVideo.entity.fieldVideoDescription', ''),
            uploadDate: get(nodeData, 'fieldVideo.entity.entityCreated', ''),
          }
        : undefined,
    },
  };

  return componentData;
};
