import get from 'lodash.get';

import { IJobOfferContact } from '@components/sections/Career/JobOfferContact/JobOfferContact.d'

export const jobOfferContact = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IJobOfferContact = {
    type: get(data, 'entityBundle', ''),
    copy: get(data, 'fieldCopy.processed', ''),
  }

  return componentData;
}