import { gql } from 'graphql-request';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';
import { ParagraphFullScreenImagePreview } from '@graphql/fragments/ParagraphFullScreenImage';
import { ParagraphSoftware360SectionPreview } from '@graphql/software/ParagraphSoftware360Section';
import { ParagraphConversion1CtaPreview } from '@graphql/fragments/ParagraphConversion1Cta';
import { ParagraphConversion1Cta2Preview } from '@graphql/fragments/ParagraphConversion1Cta2';
import { ParagraphProductIconWithCopyPreview } from '@graphql/fragments/ParagraphProductIconWithCopy';
import { ParagraphProductIconWithCopy2Preview } from '@graphql/fragments/ParagraphProductIconWithCopy2';
import { ParagraphProductIconWithCopy3Preview } from '@graphql/fragments/ParagraphProductIconWithCopy3';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphSoftwareAdditionalNaviPreview } from '@graphql/software/ParagraphSoftwareAdditionalNavi';
import { ParagraphSoftwareNaviPreview } from '@graphql/software/ParagraphSoftwareNavi';
import { ParagraphSoftwarePluginsTablePreview } from '@graphql/software/ParagraphSoftwarePluginsTable';
import { ParagraphTextImageTimelinePreview } from '@graphql/fragments/ParagraphTextImageTimeline';
import { ParagraphTextImageTimeline2Preview } from '@graphql/fragments/ParagraphTextImageTimeline2';
import { ParagraphTimelinePreview } from '@graphql/fragments/ParagraphTimeline';

export const SoftwareSite = gql`
  ... on NodeSoftwareSite {
    entityLabel

    fieldConversion1Cta {
      entity {
        ${ParagraphConversion1CtaPreview}
      }
    }
    fieldConversion1Cta2 {
      entity {
        ${ParagraphConversion1Cta2Preview}
      }
    }
    fieldFaq {
      entity {
        ${ParagraphFaqPreview}
      }
    }
    fieldFullScreenImage {
      entity {
        ${ParagraphFullScreenImagePreview}
      }
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphProductIconWithCopyPreview}
      }
    }
    fieldIconWithCopy2 {
      entity {
        ${ParagraphProductIconWithCopy2Preview}
      }
    }
    fieldIconWithCopy3 {
      entity {
        ${ParagraphProductIconWithCopy3Preview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldSoftware360Section {
      entity {
        ${ParagraphSoftware360SectionPreview}
      }
    }
    fieldSoftwareAdditionalNavi {
      entity {
        ${ParagraphSoftwareAdditionalNaviPreview}
      }
    }
    fieldSoftwareNavi {
      entity {
        ${ParagraphSoftwareNaviPreview}
      }
    }
    fieldSoftwarePluginsTable {
      entity {
        ${ParagraphSoftwarePluginsTablePreview}
      }
    }
    fieldTextImageTimeline {
      entity {
        ${ParagraphTextImageTimelinePreview}
      }
    }
    fieldTextImageTimeline2 {
      entity {
        ${ParagraphTextImageTimeline2Preview}
      }
    }
    fieldTimeline {
      entity {
        ${ParagraphTimelinePreview}
      }
    }
    fieldCategoryA {
      entity {
        title
        path {
          alias
        }
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }

    # important !!!
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
export const SoftwareAdditionalNavi = gql`
  additionalNavi: nodeQuery (
    filter: { conditions: { field: "type", value: "software_site" } }, limit: 1000
  ) {
    entities {
      ${SoftwareSite}
    }
  }
`;
