import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphSupportContactForm = graphql`
  fragment ParagraphSupportContactForm on drupalData_ParagraphSupportContactForm {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphSupportContactFormPreview = gql`
  ... on ParagraphSupportContactForm {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;