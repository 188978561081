import { gql } from 'graphql-request';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphGetAQuoteFormPreview } from '@graphql/contact/ParagraphGetAQuoteForm';
import { ParagraphLiveDemoRegisterPreview } from '@graphql/contact/ParagraphLiveDemoRegister';
import { ParagraphWriteToUsFormPreview } from '@graphql/contact/ParagraphWriteToUsForm';
import { ParagraphBookAnOnlineDemoPreview } from '@graphql/contact/ParagraphBookAnOnlineDemo';
import { ParagraphGlobalOfficePreview } from '@graphql/fragments/ParagraphGlobalOffice';
import { ParagraphContactRegionPreview } from '@graphql/contact/ParagraphContactRegion';

export const ContactPage = gql`
  ... on NodeContactPage {
    entityLabel

    fieldFaq {
      entity {
        ${ParagraphFaqPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }

    fieldSectionContact {
      entity {
        ${ParagraphGetAQuoteFormPreview}
        ${ParagraphWriteToUsFormPreview}
      }
    }
    fieldFormOffer
    fieldPhoneLabel
    fieldEmailLabel
    fieldForm {
      entity {
        uuid
      }
    }

    # important !!!
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;

export const ContactPageQuery2 = gql`
template: nodeQuery(
  filter: { conditions: { field: "type", value: "contact_template" } }
  limit: 1000
) {
  entities {
    ... on NodeContactTemplate {
      entityLabel
      fieldGlobalOffice {
        entity {
          ${ParagraphGlobalOfficePreview}
        }
      }
      fieldContactRegion {
        entity {
          ${ParagraphContactRegionPreview}
        }
      }
      uuid
      langcode {
        value
      }
      sticky: entityMultisiteEnabled
      status
      entityMultisiteTranslations {
        entity {
          entityTranslations {
            entityLabel
            entityLanguage {
              id
            }
            ... on Node {
              status
              path {
                alias
              }
            }
          }
        }
      }
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
}

taxonomyTermQuery(
  limit: 1000
  filter: { conditions: { field: "vid", value: "contact_submenu" } }
) {
  entities {
    ... on TaxonomyTermContactSubmenu {
      entityLabel
      entityBundle
      fieldPage {
        entity {
          path {
            alias
          }
        }
      }
    }
  }
}
`;
