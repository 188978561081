import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphNewsletter = graphql`
  fragment ParagraphNewsletter on drupalData_ParagraphNewsletter {
    entityBundle
    fieldCaptchaKey
    uuid
  }
`;

export const ParagraphNewsletterPreview = gql`
  ... on ParagraphNewsletter {
    entityBundle
    fieldCaptchaKey
    uuid
  }
`;