import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphEventDisclaimer = graphql`
  fragment ParagraphEventDisclaimer on drupalData_ParagraphEventDisclaimer {
    entityBundle
    fieldCopy {
      processed
    }
    uuid
  }
`;

export const ParagraphEventDisclaimerPreview = gql`
  ... on ParagraphEventDisclaimer {
    entityBundle
    fieldCopy {
      processed
    }
    uuid
  }
`;