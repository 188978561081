import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCtaPinkPreview } from '@graphql/fragments/ParagraphCtaPink';

export const ParagraphEventConversionBanner = graphql`
  fragment ParagraphEventConversionBanner on drupalData_ParagraphEventConversionBanner {
    entityBundle
    fieldCtaPink {
      entity {
        ...ParagraphCtaPink
      }
    }
    fieldHeadline {
      processed
    }
    fieldUpperSubheadline
    uuid
  }
`;

export const ParagraphEventConversionBannerPreview = gql`
  ... on ParagraphEventConversionBanner {
    entityBundle
    fieldCtaPink {
      entity {
        ${ParagraphCtaPinkPreview}
      }
    }
    fieldHeadline {
      processed
    }
    fieldUpperSubheadline
    uuid
  }
`;