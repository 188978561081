import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphContactPartnersDataPreview } from '@graphql/contact/ParagraphContactPartnersData';

export const ParagraphContactRegionData = graphql`
  fragment ParagraphContactRegionData on drupalData_ParagraphContactRegionData {
    entityBundle
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldContactPartnersData {
      entity {
        ...ParagraphContactPartnersData
      }
    }
    fieldTitle
    uuid
  }
`;

export const ParagraphContactRegionDataPreview = gql`
  ... on ParagraphContactRegionData {
    entityBundle
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldContactPartnersData {
      entity {
        ${ParagraphContactPartnersDataPreview}
      }
    }
    fieldTitle
    uuid
  }
`;