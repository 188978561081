import get from 'lodash.get';

// === Data === //
import { productCategoryData } from '@graphql/ProductCategory';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { bigNumbers } from '@DTO/sections/BigNumbers';
import { timeline } from '@DTO/sections/Timeline';
import { gallery } from '@DTO/sections/Gallery';
import { cardSmallOnGrid4x2 } from '@DTO/sections/CardSmallOnGrid4x2';
import { conversionWith2CTA } from '@DTO/sections/ConversionWith2CTA';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { testimonials } from '@DTO/sections/Testimonials';
import { faq } from '@DTO/sections/FAQ';
import { cardTextContextWithCaption } from '@DTO/sections/CardTextContextWithCaption';
import { metatags } from '@DTO/sections/Metatags';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const ProductCategoryPageDTO = (
  uuid: string | undefined,
  data?: { drupalData: unknown }
) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const productCategoryDataQuery = productCategoryData();
    currentPageData = productCategoryDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, ['fieldCategoryA']),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      bigNumbers(nodeEntity, 'fieldNumericalBenefits'),
      cardTextContextWithCaption(nodeEntity, 'fieldCardTextContextWithCap'),
      timeline(nodeEntity, 'fieldTimeline'),
      gallery(nodeEntity, 'fieldGallery'),
      cardSmallOnGrid4x2(nodeEntity, 'fieldCardSmallOnGrid4x2'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta'),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      testimonials(nodeEntity, 'fieldTestimonials'),
      faq(nodeEntity, 'fieldFaq'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta2'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
