import get from 'lodash.get';

import { IIndustriesSubmenu } from '@components/sections/IndustriesSubmenu/IndustriesSubmenu.d';

export const industriesSubmenu = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IIndustriesSubmenu = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    sections: get(data, 'fieldIndustriesSubmenuSection', []).map(section => ({
      title: get(section, 'entity.fieldSubmenu', ''),
      cardTextImage: {
        type: get(section, 'entity.fieldCardTextImage.entity.entityBundle', ''),
        background: get(section, 'entity.fieldCardTextImage.entity.fieldBackground[0]', 'no') === 'yes' ? true : false,
        headline: get(section, 'entity.fieldCardTextImage.entity.fieldHeadline.processed', ''),
        firstCardSide:
          get(section, 'entity.fieldCardTextImage.entity.fieldVariant', '1') === '1' ? 'left' : 'right',
        cards: get(section, 'entity.fieldCardTextImage.entity.fieldTextAndImage', []).map((card) => ({
          headline: get(card, 'entity.fieldHeadline.processed', ''),
          description: get(card, 'entity.fieldDescription.processed', ''),
          image: get(card, 'entity.fieldImage', undefined)
            ? {
                alt: get(card, 'entity.fieldImage.alt', ''),
                title: get(card, 'entity.fieldImage.title', ''),
                url: get(card, 'entity.fieldImage.url', ''),
                webp: get(card, 'entity.fieldImage.derivative.url'),
              }
            : undefined,
          video: get(card, 'entity.fieldVideo.entity.url', undefined),
          videoUrl: get(card, 'entity.fieldVideoUrl.url.path', undefined),
          label: get(card, 'entity.fieldLabel', undefined)
            ? {
                title: get(card, 'entity.fieldLabel', ''),
                url: get(card, 'entity.fieldLabelLink.url.path', ''),
                color: get(card, 'entity.fieldLabelColor', null),
              }
            : undefined,
          cta: get(card, 'entity.fieldLinkCta', undefined)
            ? {
                label: get(card, 'entity.fieldLinkCta.title', ''),
                url: get(card, 'entity.fieldLinkCta.url.path', ''),
              }
            : undefined,
          imageOrVideo: get(card, 'entity.fieldSelectImageOrVideo', 'image'), // return: 'image' | 'video'
          poster: get(card, 'entity.fieldPoster', undefined) ? {
            alt: get(card, 'entity.fieldPoster.alt', ''),
            title: get(card, 'entity.fieldPoster.title', ''),
            url: get(card, 'entity.fieldPoster.url', ''),
            webp: get(card, 'entity.fieldPoster.derivative.url'),
          } : undefined,
          schemaData: get(data, 'entity.fieldName', undefined) ? {
            name: get(data, 'entity.fieldName', ''),
            duration: get(data, 'entity.fieldVideoDuration', ''),
            description: get(data, 'entity.fieldVideoDescription', ''),
            uploadDate: get(data, 'entity.entityCreated', '')
          } : undefined
        })),
      }
    })),
  }

  return componentData;
}