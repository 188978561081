import get from 'lodash.get';

export const copyImageGrid2x2 = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    title: get(data, 'fieldHeadline.processed', ''),
    products: get(data, 'fieldImageGrid2x2', []).map(
      (item) => ({
        header: get(item, 'entity.fieldTitle', ''),
        body: get(item, 'entity.fieldDescription.processed', ''),
        image: {
          alt: get(item, 'entity.fieldImage.alt', ''),
          title: get(item, 'entity.fieldImage.title', ''),
          url: get(item, 'entity.fieldImage.url', ''),
          webp: get(item, 'entity.fieldImage.derivative.url', ''),
        },
        link: {
          title: get(item, 'entity.fieldLinkCta.title', ''),
          url: get(item, 'entity.fieldLinkCta.url.path', '/'),
        },
      })
    ),
  }
}