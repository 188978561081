import { globalTranslations } from '@helpers/globalTranslations';
import { filterByLanguage } from '@utils';
import get from 'lodash.get';

export const blogArticles = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entities`, undefined);

  const componentData = {
    type: 'blog_articles_list', // wrapper doesn't have own entityBundle type
    articles: data
      .reverse()
      .filter(filterByLanguage)
      .map((article) => ({
        category: get(article, 'fieldBlogCategory.entity.name', ''),
        publicDate: get(article, 'fieldPublicationDate.value', undefined),
        readingTime: get(article, 'fieldReadingTime', ''),
        headline: get(article, 'title', ''),
        textIntro: get(article, 'fieldShortIntroCopy.processed', ''),
        image: get(article, 'fieldTeaserImage', undefined)
          ? {
              alt: get(article, 'fieldTeaserImage.alt', ''),
              title: get(article, 'fieldTeaserImage.title', ''),
              url: get(article, 'fieldTeaserImage.url', ''),
              webp: get(article, 'fieldTeaserImage.derivative.url', undefined),
            }
          : undefined,
        uuid: get(article, 'uuid', ''),
        buttonLabel: globalTranslations('fieldCtaBlogCard'),
        buttonUrl: get(article, 'path.alias', '/'),
        pageContent: get(article, 'fieldSectionBlog', []),
      }))
      .sort((a, b) => new Date(b.publicDate).getTime() - new Date(a.publicDate).getTime()),
  };

  return componentData;
};
