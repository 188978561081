import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphGetAQuoteForm = graphql`
  fragment ParagraphGetAQuoteForm on drupalData_ParagraphGetAQuoteForm {
    entityBundle
    fieldCopy {
      processed
    }
    fieldDescription {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldFormDisclaimer
    fieldHeadline {
      processed
    }
    fieldTitle
    # fieldAnchorId
    uuid
  }
`;

export const ParagraphGetAQuoteFormPreview = gql`
  ... on ParagraphGetAQuoteForm {
    entityBundle
    fieldCopy {
      processed
    }
    fieldDescription {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldFormDisclaimer
    fieldHeadline {
      processed
    }
    fieldTitle
    # fieldAnchorId
    uuid
  }
`;