import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const careerDetailData = () => {
  const data = useStaticQuery(graphql`
    query careerDetailQuery {
      drupalData {
        nodeQuery(
          filter: { conditions: { field: "type", value: "careers_details" } }
          limit: 10000
        ) {
          entities {
            ... on drupalData_NodeCareersDetails {
              entityLabel

              fieldCareersArticle {
                entity {
                  ...ParagraphCareersArticle
                }
              }
              fieldCareersArticle2 {
                entity {
                  ...ParagraphCareersArticle2
                }
              }
              fieldCareersIntro {
                entity {
                  ...ParagraphCareersIntro
                }
              }
              fieldDepartamentDisplay
              fieldJobContact {
                entity {
                  ...ParagraphJobContact
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }

              fieldCategoryA {
                entity {
                  title
                  path {
                    alias
                  }
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }

        taxonomyTermQuery(
          filter: { conditions: { field: "vid", value: "departments_with_job" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_TaxonomyTermDepartmentsWithJob {
              entityLabel
              fieldDescription {
                processed
              }
              fieldHeadline
              fieldContact {
                entity {
                  ...ParagraphContactDepartmentsWithJob
                }
              }
              fieldVideo {
                entity {
                  ...ParagraphVideo
                }
              }
              name
              uuid
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
