import get from 'lodash.get';

// === Data === //
import { industriesSiteData } from '@graphql/IndustriesSite';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { mainBanner } from '@DTO/sections/MainBanner';
import { industriesSubmenu } from '@DTO/sections/IndustriesSubmenu';
import { contextImageWithCaption } from '@DTO/sections/ContextImageWithCaption';
import { conversionWith2CTA } from '@DTO/sections/ConversionWith2CTA';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { getNodeEntity } from '@utils';
import { languagePath } from '@DTO/sections/LanguagePath';

export const IndustriesSiteDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const homepageDataQuery = industriesSiteData();
    currentPageData = homepageDataQuery;
  } else {
    currentPageData = data;
  }
  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      industriesSubmenu(nodeEntity, 'fieldIndustriesSubmenuSection'),
      contextImageWithCaption(nodeEntity, 'fieldContextImageWithCaption'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
