import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphLpImageWithCaption = graphql`
  fragment ParagraphLpImageWithCaption on drupalData_ParagraphLpImageWithCaption {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldLpImageWithCaptionItem {
      entity {
        ... on drupalData_ParagraphLpImageWithCaptionItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
        }
      }
    }
  }
`;

export const ParagraphLpImageWithCaptionPreview = gql`
  ... on ParagraphLpImageWithCaption {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldLpImageWithCaptionItem {
      entity {
        ... on ParagraphLpImageWithCaptionItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
        }
      }
    }
  }
`;
