import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphConversionBoxPreview } from '@graphql/fragments/ParagraphConversionBox';

export const ParagraphConversion2Cta = graphql`
  fragment ParagraphConversion2Cta on drupalData_ParagraphConversion2Cta {
    entityBundle
    fieldConversionBox {
      entity {
        entityBundle
        ...ParagraphConversionBox
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphConversion2CtaPreview = gql`
  ... on ParagraphConversion2Cta {
    entityBundle
    fieldConversionBox {
      entity {
        entityBundle
        ${ParagraphConversionBoxPreview}
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;
