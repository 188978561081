import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCtaPinkPreview } from '@graphql/fragments/ParagraphCtaPink';

export const ParagraphOnlineEventHeader = graphql`
  fragment ParagraphOnlineEventHeader on drupalData_ParagraphOnlineEventHeader {
    entityBundle
    fieldCtaPink {
      entity {
        ...ParagraphCtaPink
      }
    }
    fieldEventDate
    fieldEventTag
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    uuid
  }
`;

export const ParagraphOnlineEventHeaderPreview = gql`
  ... on ParagraphOnlineEventHeader {
    entityBundle
    fieldCtaPink {
      entity {
        ${ParagraphCtaPinkPreview}
      }
    }
    fieldEventDate
    fieldEventTag
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    uuid
  }
`;
