import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogSideBySideImage = graphql`
  fragment ParagraphBlogSideBySideImage on drupalData_ParagraphBlogSideBySideImage {
    entityBundle
    fieldImageSideBySide {
      entity {
        ... on drupalData_ParagraphImageSideBySide {
          entityBundle
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldImageCaption
          uuid
        }
      }
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
  }
`;

export const ParagraphBlogSideBySideImagePreview = gql`
  ... on ParagraphBlogSideBySideImage {
    entityBundle
    fieldImageSideBySide {
      entity {
        ... on ParagraphImageSideBySide {
          entityBundle
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldImageCaption
          uuid
        }
      }
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
  }
`;