import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphTextAndImagePreview } from '@graphql/fragments/ParagraphTextAndImage';

export const ParagraphCardTextImage2 = graphql`
  fragment ParagraphCardTextImage2 on drupalData_ParagraphCardTextImage2 {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldHeadlineImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldTextAndImage {
      entity {
        ...ParagraphTextAndImage
      }
    }
    fieldVariant
    uuid
  }
`;

export const ParagraphCardTextImage2Preview = gql`
  ... on ParagraphCardTextImage2 {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldHeadlineImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldTextAndImage {
      entity {
        ${ParagraphTextAndImagePreview}
      }
    }
    fieldVariant
    uuid
  }
`;