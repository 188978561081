import PageTypes from './PageTypes';
import { getPageType, previewData, getGlobalTranslations } from './requests';

export default async (uuid: string | undefined) => {
  if (!uuid || !process.env.API_GRAPHQL_URL) {
    return false;
  }

  const translationData = await getGlobalTranslations(process.env.API_GRAPHQL_URL);
  globalThis.__ORBITVU_TRANSLATIONS = translationData.globalTranslations;

  let pageType = await getPageType(uuid, process.env.API_GRAPHQL_URL);
  if (!pageType) {
    return {};
  }
  pageType = pageType?.preview?.type;

  if (pageType) {
    const pageData = PageTypes[pageType];

    if (!pageData) {
      console.warn(
        `preview/DTO: line 18 - No page type ${pageType} in queries, possible types: ${Object.keys(
          PageTypes
        )}`
      );
      return false;
    }
  
    const data = await previewData(pageData.query, pageData.query2 || '', uuid, process.env.API_GRAPHQL_URL);
  
    const params: unknown[] = [uuid];
    if (pageType.includes('test')) {
      params.push(parseInt(pageType.replace('test', '')));
    }
    params.push({ drupalData: data });
  
    const result = pageData.dto(...params);
    result.pageType = pageType;

    return result;
  }
};
