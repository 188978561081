import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphWriteToUsForm = graphql`
  fragment ParagraphWriteToUsForm on drupalData_ParagraphWriteToUsForm {
    entityBundle
    fieldCopy {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    fieldAnchorId
    uuid
  }
`;

export const ParagraphWriteToUsFormPreview = gql`
  ... on ParagraphWriteToUsForm {
    entityBundle
    fieldCopy {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    fieldAnchorId
    uuid
  }
`;