import get from 'lodash.get';

import { IJobOfferIntro } from '@components/sections/Career/JobOfferIntro/JobOfferIntro.d'

export const jobOfferIntro = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IJobOfferIntro = {
    type: get(data, 'entityBundle', ''),
    department: get(data, 'fieldDepartamentName.entity.name', ''),
    place: get(data, 'fieldLocation', ''),
    headline: get(data, 'fieldJobTitle', ''),
    description: get(data, 'fieldDescription.processed', ''),
    image: get(data, 'fieldImage') ? {
      url: get(data, 'fieldImage.url'),
      alt: get(data, 'fieldImage.alt'),
      title: get(data, 'fieldImage.title'),
      webp: get(data, 'fieldImage.derivative.url'),
    } : undefined
  }

  return componentData;
}