import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphIconWithCopyPreview } from '@graphql/fragments/ParagraphIconWithCopy';

export const ParagraphSoftware360Section = graphql`
  fragment ParagraphSoftware360Section on drupalData_ParagraphSoftware360Section {
    entityBundle
    fieldBackground
    fieldCode
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ...ParagraphIconWithCopy
      }
    }
    uuid
  }
`;

export const ParagraphSoftware360SectionPreview = gql`
  ... on ParagraphSoftware360Section {
    entityBundle
    fieldBackground
    fieldCode
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphIconWithCopyPreview}
      }
    }
    uuid
  }
`;