import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphPartnership2x2 = graphql`
  fragment ParagraphPartnership2x2 on drupalData_ParagraphPartnership2x2 {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldPartnership2x2Item {
      entity {
        ... on drupalData_ParagraphPartnership2x2Item {
          entityBundle
          fieldCopy {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldTitle
        }
      }
    }
  }
`;

export const ParagraphPartnership2x2Preview = gql`
  ... on ParagraphPartnership2x2 {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldPartnership2x2Item {
      entity {
        ... on ParagraphPartnership2x2Item {
          entityBundle
          fieldCopy {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldTitle
        }
      }
    }
  }
`;