import React, { Fragment, useEffect, useRef, useState } from 'react';

// === Components === //
import Hero from '@components/sections/Hero/Hero';
import Search from '@components/sections/Search/Search';
import Pagination from '@components/common/Pagination/Pagination';
import Submenu from '@components/common/Submenu/Submenu';
import Newsletter from '@components/sections/Newsletter/Newsletter';
import ArticlesList from '@components/sections/Blog/ArticlesList/ArticlesList';

// === Helpers === //
import cn from 'classnames';
import { heroVariant } from '@helpers/heroVariants';
import { globalTranslations } from '@helpers/globalTranslations';
import { getQuerySearch, setQuerySearch } from '@helpers/changeQueryString';

// === Styles === //
import * as styles from '@components/layout/BlogArticlesListComponents/BlogArticlesListComponents.module.scss';

// === Types === //
import { HeroData, PageData } from '@type/page-components.d';

const BlogArticlesListComponents = ({ components, type, slug }: PageData) => {
  const allArticlesTranslation = globalTranslations('fieldAllArticles');
  const [searchPhrase, setSearchPhrase] = useState('');
  const [currentCategory, setCurrentCategory] = useState(allArticlesTranslation);
  const [currentPage, setPage] = useState(1);
  const [firstUpload, setFirstUpload] = useState(true);
  const menuItems =
    components.find((component) => component.type === 'blog_categories_menu')?.items || [];

  const categories = [
    {
      id: 0,
      name: allArticlesTranslation,
      path: '',
    },
    ...menuItems,
  ];

  const allArticles =
    components.find((component) => component.type === 'blog_articles_list')?.articles || [];

  const [articles, setArticles] = useState(allArticles);
  const [totalCount, setTotalCount] = useState(articles.length);
  const pageSize = 9;
  const menuRef = useRef(null);

  const changePageQuery = (currentCategory, searchPhrase) => {
    if (currentCategory != allArticlesTranslation) {
      const art = allArticles.filter((article) => article.category === currentCategory);

      // setPage(1);
      setArticles(art);
      setTotalCount(art.length);
    } else {
      // setPage(1);
      setArticles(allArticles);
      setTotalCount(allArticles.length);
    }

    if (searchPhrase != '') {
      const art = allArticles
        .filter((article) =>
          currentCategory != allArticlesTranslation ? article.category === currentCategory : article
        )
        .filter(
          (article) =>
            article.headline.toLowerCase().includes(searchPhrase.toLowerCase()) ||
            article.textIntro.toLowerCase().includes(searchPhrase.toLowerCase()) ||
            article.pageContent.filter(
              (sec) =>
                sec.entity &&
                sec.entity?.fieldBlogCopy?.processed
                  .toLowerCase()
                  .includes(searchPhrase.toLowerCase())
            ).length > 0
        );

      setArticles(art);
      setTotalCount(art.length);
    }
  };

  useEffect(() => {
    if (getQuerySearch.category) {
      setSearchPhrase(getQuerySearch.search as string);
      setCurrentCategory(getQuerySearch.category as string);
      setPage(Number(getQuerySearch.page));
    }

    setTimeout(() => {
      setFirstUpload(false);
    }, 100);
  }, []);

  useEffect(() => {
    !firstUpload && setPage(1);
  }, [currentCategory, searchPhrase]);

  useEffect(() => {
    changePageQuery(currentCategory, searchPhrase);
    setQuerySearch({
      category: currentCategory,
      search: searchPhrase,
      page: currentPage,
    });
  }, [currentCategory, currentPage]);

  useEffect(() => {
    changePageQuery(currentCategory, searchPhrase);
    setQuerySearch({
      category: currentCategory,
      search: searchPhrase,
      page: currentPage,
    });
  }, [searchPhrase]);

  return (
    <div className={cn(styles['blogComponentsWrapper'])}>
      {components.map((component, index) => (
        <Fragment key={index}>
          {component && component.type && component.type !== '' && (
            <>
              {(component.type === 'main_banner_variant_1' ||
                component.type === 'main_banner_variant_2' ||
                component.type === 'main_banner_variant_3') && (
                <Hero
                  variant={
                    heroVariant[
                      type && type === 'productDetail' ? 'main_banner_variant_4' : component.type
                    ].variant
                  }
                  backgroundVariant={
                    heroVariant[
                      type && type === 'productDetail' ? 'main_banner_variant_4' : component.type
                    ].bgVariant
                  }
                  title={(component as HeroData).title}
                  description={(component as HeroData).description}
                  image={(component as HeroData).image || undefined}
                  buttons={(component as HeroData).buttons || []}
                  backgroundVideo={(component as HeroData).backgroundVideo || undefined}
                />
              )}
              {component.type === 'blog_search' && (
                <Search
                  {...component}
                  searchPhrase={searchPhrase}
                  setSearchPhrase={setSearchPhrase}
                />
              )}
              {component.type === 'blog_articles_list' && (
                <div className={cn('page-wrapper', styles['articlesList'])}>
                  {articles.length > 0 ? (
                    <ArticlesList
                      articles={articles
                        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                        .sort((a, b) => {
                          const dateA: any | number | bigint = new Date(a.publicDate);
                          const dateB: any | number | bigint = new Date(b.publicDate);
                          return dateB - dateA;
                        })}
                    />
                  ) : (
                    <h2>{globalTranslations('fieldResultNotFound')}</h2>
                  )}
                </div>
              )}
              {component.type === 'blog_categories_menu' && (
                <div className={cn('page-wrapper')}>
                  <Submenu
                    htmlId="article-submenu"
                    pageUrl={`${slug}`}
                    items={categories?.map((item) => ({
                      title: item.name,
                    }))}
                    noScroll={true}
                    {...{ setCurrentCategory, currentCategory }}
                  />
                </div>
              )}
            </>
          )}
        </Fragment>
      ))}
      {articles.length > pageSize && (
        <div className={cn('page-wrapper')}>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            maxSiblings={1}
            onChange={(value) => {
              // setTimeout(() => {
              //   const item = document.querySelector(`#article-submenu`);
              //   item?.scrollIntoView({ behavior: 'smooth' });
              // }, 50);

              setPage(value);
            }}
          ></Pagination>
        </div>
      )}
      {components.map((component, index) => (
        <Fragment key={index}>
          {component && component.type && component.type !== '' && (
            <>{component.type === 'newsletter' && <Newsletter {...component} />}</>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default BlogArticlesListComponents;
