import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphContactOffice = graphql`
  fragment ParagraphContactOffice on drupalData_ParagraphContactOffice {
    entityBundle
    fieldCompanyName
    fieldCountry
    fieldEMail
    fieldOfficeName
    fieldPhone
    fieldPostalCode
    fieldStreet
    uuid
  }
`;

export const ParagraphContactOfficePreview = gql`
  ... on ParagraphContactOffice {
    entityBundle
    fieldCompanyName
    fieldCountry
    fieldEMail
    fieldOfficeName
    fieldPhone
    fieldPostalCode
    fieldStreet
    uuid
  }
`;