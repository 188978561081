import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphTextAndVideoPreview } from '@graphql/fragments/ParagraphTextAndVideo'

export const ParagraphCardTextVideo = graphql`
  fragment ParagraphCardTextVideo on drupalData_ParagraphCardTextVideo {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldTextAndVideo {
      entity {
        ...ParagraphTextAndVideo
      }
    }
    fieldVariantTextAndVideo
  }
`;

export const ParagraphCardTextVideoPreview = gql`
  ... on ParagraphCardTextVideo {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldTextAndVideo {
      entity {
        ${ParagraphTextAndVideoPreview}
      }
    }
    fieldVariantTextAndVideo
  }
`;