import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const productsData = () => {
  const data = useStaticQuery(graphql`
    query productsQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "product" } }, limit: 1000) {
          entities {
            ... on drupalData_NodeProduct {
              entityLabel

              fieldAccessories {
                entity {
                  ...ParagraphAccessories
                }
              }
              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldCardTextVideo {
                entity {
                  ...ParagraphCardTextVideo
                }
              }
              fieldCardWithProductsDetails {
                entity {
                  ...ParagraphCardWithProductsDetails
                }
              }
              fieldContextImageWithCaption {
                entity {
                  ...ParagraphContextImageWithCaption
                }
              }
              fieldConversion2Cta {
                entity {
                  ...ParagraphConversion2Cta
                }
              }
              fieldConversion2Cta2 {
                entity {
                  ...ParagraphConversion2Cta2
                }
              }
              fieldFaq {
                entity {
                  ...ParagraphFaq
                }
              }
              fieldGallery {
                entity {
                  ...ParagraphGallery
                }
              }
              fieldIconWithCopy {
                entity {
                  ...ParagraphProductIconWithCopy
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldNumericalBenefits {
                entity {
                  ...ParagraphNumericalBenefits
                }
              }
              fieldOrbitvuTour {
                entity {
                  ...ParagraphOrbitvuTour
                }
              }
              fieldVideo {
                entity {
                  ...ParagraphVideo
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }

              fieldCategoryA {
                entity {
                  title
                  path {
                    alias
                  }
                }
              }
              fieldCategoryB {
                entity {
                  path {
                    alias
                  }
                  title
                }
              }
              fieldProductPageForm {
                entity {
                  ...ParagraphFormProduct
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
