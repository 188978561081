import { useMemo } from "react"

export function debug(...args) {
  const isDebug = true

  process.env.NODE_ENV !== 'production' && isDebug && console.log(...args)
}

export function usePagination(currentPage: number, lastPage: number, dots: number, maxSiblings: number) {
  const recursionGuard = 100
  let recursionIndex = 0
  let items: number[] = []

  function fillItemsRecursive(itemIndex, siblingIndex = 1, step = 1) {
    // debug('---')
    // debug({ items, itemIndex, siblingIndex, step })
    recursionIndex = recursionIndex + 1;
    if (itemIndex < 0 || itemIndex > lastPage) {
      return items
    }

    if (recursionIndex > recursionGuard) {
      return items
    }

    if (items.includes(itemIndex)) {
      // debug('already exists', itemIndex);
      return items
    }

    if (itemIndex === lastPage) {
      // go left
      // debug('last');
      items = [...items, itemIndex]
      return fillItemsRecursive(currentPage != 1 ? currentPage - 1 : 1, 1, -1)
    }

    if (itemIndex === 1) {
      // go right
      // debug('first');
      items = [itemIndex, ...items]
      return fillItemsRecursive(currentPage + 1, 1, 1)
    }

    if (siblingIndex > maxSiblings) {
      // show dots
      // debug('has siblings');
      if (step > 0) {
        // go left from current page
        items = [...items, dots, lastPage]
        if (currentPage == 1) {
          return items
        }
        return fillItemsRecursive(currentPage - 1, 1, -1)
      } else {
        // go right from current page
        if (itemIndex === 1) {
          return items
        }
        items = [1, dots, ...items]
        return fillItemsRecursive(currentPage + 1, 1, 1)
      }
    }

    // debug('adding item')
    if (step > 0) {
      items = [...items, itemIndex]
    } else {
      items = [itemIndex, ...items]
    }

    // go to the next page
    return fillItemsRecursive(itemIndex + step, siblingIndex + Math.abs(step), step)
  }

  return fillItemsRecursive(currentPage, 0)
}
