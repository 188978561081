import { gql } from "graphql-request";

import { ParagraphMetatagsPreview } from "@graphql/fragments/ParagraphMetatags";

export const Error404 = gql`
  ... on Node404Page {
    entityBundle
    fieldCopy {
      processed
    }
    fieldCopy2 {
      processed
    }
    fieldCopy3 {
      processed
    }
    fieldCta {
      title
      url {
        path
      }
    }
    fieldHeadline
    fieldSubheadline
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }

    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;