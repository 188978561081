import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphFaqItemPreview } from '@graphql/fragments/ParagraphFaqItem';

export const ParagraphFaq = graphql`
  fragment ParagraphFaq on drupalData_ParagraphFaq {
    entityBundle
    fieldBackground 
    fieldHeadline {
      value
      processed
    }
    fieldFaqItem {
      entity {
        ...ParagraphFaqItem
      }
    }
  }
`;

export const ParagraphFaqPreview = gql`
  ... on ParagraphFaq {
    entityBundle
    fieldBackground 
    fieldHeadline {
      value
      processed
    }
    fieldFaqItem {
      entity {
        ${ParagraphFaqItemPreview}
      }
    }
  }
`;