import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphTestimonials = graphql`
  fragment ParagraphTestimonials on drupalData_ParagraphTestimonials {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldBackground
    fieldVariantQuote
    fieldImage {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }

    fieldTestimonialHeadline {
      processed
    }
    fieldTestimonialSubImage {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }
    fieldTestimonialCopy {
      processed
    }


    fieldTestimonialHeadline2 {
      processed
    }
    fieldTestimonialSubImage2 {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }
    fieldTestimonialCopy2 {
      processed
    }
  }
`;

export const ParagraphTestimonialsPreview = gql`
  ... on ParagraphTestimonials {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldBackground
    fieldVariantQuote
    fieldImage {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }

    fieldTestimonialHeadline {
      processed
    }
    fieldTestimonialSubImage {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }
    fieldTestimonialCopy {
      processed
    }


    fieldTestimonialHeadline2 {
      processed
    }
    fieldTestimonialSubImage2 {
      derivative(style: WEBP) {
        url
      }
      alt
      title
      url
    }
    fieldTestimonialCopy2 {
      processed
    }
  }
`;