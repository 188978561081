import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphOurCustomerLogo = graphql`
  fragment ParagraphOurCustomerLogo on drupalData_ParagraphOurCustomerLogo {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldLogosUrl {
      entity {
        entityBundle
        ... on drupalData_ParagraphCustomerLogoItem {
          entityBundle
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLogoLink {
            title
            url {
              path
            }
          }
        }
      }
    }
    fieldVariantGrid
    uuid
  }
`;

export const ParagraphOurCustomerLogoPreview = gql`
  ... on ParagraphOurCustomerLogo {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldLogosUrl {
      entity {
        entityBundle
        ... on ParagraphCustomerLogoItem {
          entityBundle
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLogoLink {
            title
            url {
              path
            }
          }
        }
      }
    }
    fieldVariantGrid
    uuid
  }
`;