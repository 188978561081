import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphTextAndImagePreview } from '@graphql/fragments/ParagraphTextAndImage';

export const ParagraphCardTextImage = graphql`
  fragment ParagraphCardTextImage on drupalData_ParagraphCardTextImage {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldHeadlineImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldHeadlineImageMobile {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldTextAndImage {
      entity {
        ...ParagraphTextAndImage
      }
    }
    fieldVariant
    uuid
  }
`;
export const ParagraphCardTextImagePreview = gql`
  ... on ParagraphCardTextImage {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldHeadlineImage {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldHeadlineImageMobile {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldTextAndImage {
      entity {
        ${ParagraphTextAndImagePreview}
      }
    }
    fieldVariant
    uuid
  }
`;
