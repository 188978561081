import { gql } from 'graphql-request';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphContactDepartmentsWithJobPreview } from '@graphql/fragments/ParagraphContactDepartmentsWithJob';
import { ParagraphVideoPreview } from '@graphql/fragments/ParagraphVideo';
import { ParagraphJobContactPreview } from '@graphql/fragments/ParagraphJobContact';
import { ParagraphCareersIntroPreview } from '@graphql/careers/ParagraphCareersIntro';
import { ParagraphCareersArticle2Preview } from '@graphql/careers/ParagraphCareersArticle2';
import { ParagraphCareersArticlePreview } from '@graphql/careers/ParagraphCareersArticle';

export const CareersDetails = gql`
  ... on NodeCareersDetails {
  entityLabel

  fieldCareersArticle {
    entity {
      ${ParagraphCareersArticlePreview}
    }
  }
  fieldCareersArticle2 {
    entity {
      ${ParagraphCareersArticle2Preview}
    }
  }
  fieldCareersIntro {
    entity {
      ${ParagraphCareersIntroPreview}
    }
  }
  fieldDepartamentDisplay
  fieldJobContact {
    entity {
      ${ParagraphJobContactPreview}
    }
  }
  fieldMetatags {
    entity {
      ${ParagraphMetatagsPreview}
    }
  }

  fieldCategoryA {
    entity {
      title
      path {
        alias
      }
    }
  }
  fieldFormOffer
  fieldForm {
    entity {
      uuid
    }
  }

  # important !!!
  path {
    alias
  }
  title
  uuid
  langcode {
    value
  }
  sticky: entityMultisiteEnabled
  status
  entityMultisiteTranslations {
    entity {
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
  entityTranslations {
    entityLabel
    entityLanguage {
      id
    }
    ... on Node {
      status
      path {
        alias
      }
    }
  }
}
`;
export const TaxonomyTermQuery = gql`
taxonomyTermQuery(
  filter: { conditions: { field: "vid", value: "departments_with_job" } },
  limit: 1000
) {
  entities {
    ... on TaxonomyTermDepartmentsWithJob {
      entityLabel
      fieldDescription {
        processed
      }
      fieldHeadline
      fieldContact {
        entity {
          ${ParagraphContactDepartmentsWithJobPreview}
        }
      }
      fieldVideo {
        entity {
          ${ParagraphVideoPreview}
        }
      }
      name
      uuid
    }
  }
}`;
