import get from 'lodash.get';

// === Data === //
import { blogArticlesList } from '@graphql/blog/BlogArticlesList';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { search } from '@DTO/sections/blog/Search';
import { blogCategoriesMenu } from '@DTO/sections/blog/BlogCategoriesMenu';
import { blogArticles } from '@DTO/sections/blog/BlogArticlesList';
import { metatags } from '@DTO/sections/Metatags';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const BlogArticlesListPageDTO = (
  uuid: string | undefined,
  data?: { drupalData: unknown }
) => {
  let currentPageData: { drupalData: unknown };
  let blogDataQuery: { drupalData: unknown };
  if (!data) {
    blogDataQuery = blogArticlesList();
    currentPageData = blogDataQuery;
  } else {
    currentPageData = data;
    blogDataQuery = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;
  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      search(nodeEntity),
      blogCategoriesMenu(blogDataQuery, 'drupalData.blogCategories'),
      blogArticles(blogDataQuery, 'drupalData.blogArticles'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
