import React, { useEffect, useState } from 'react';

// === Components === //
import Layout from '@components/layout';

// === Helpers === //
import PreviewDTO from '@components/preview/DTO';

// === Types === //
import Preview from '@components/preview/Preview';

const PreviewRoute = ({ location }) => {
  const [pageData, setPageData] = useState<IPageData>({ metaData: { title: '' }, components: [] });
  const [pageType, setPageType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async () => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id') as string;
    const response = await PreviewDTO(id);

    if (response) {
      setPageData(response);
      setPageType(response.pageType);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout
      metaData={pageData.metaData}
      breadcrumbs={pageData.breadcrumbs}
      bannerContactInfo={pageData.bannerContactInfo}
      stickedForm={pageData.stickedForm}
    >
      <Preview pageData={pageData} pageType={pageType} loading={loading} />
    </Layout>
  );
};

export default PreviewRoute;
