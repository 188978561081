import { gql } from 'graphql-request';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphSection360deg3dProductPreview } from '@graphql/fragments/ParagraphSection360deg3dProduct';
import { ParagraphSectionFlatLayPhotographyPreview } from '@graphql/fragments/ParagraphSectionFlatLayPhotography';
import { ParagraphSectionLiveModelPreview } from '@graphql/fragments/ParagraphSectionLiveModel';
import { ParagraphSectionStillProductPhotographPreview } from '@graphql/fragments/ParagraphSectionStillProductPhotograph';
import { ParagraphKeyPointsPreview } from '@graphql/fragments/ParagraphKeyPoints';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';

export const ProductAllCategory = gql`
  ... on NodeProductsCategories {
    entityLabel

    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldCategory
    fieldConversion2Cta {
      entity {
        ${ParagraphConversion2CtaPreview}
      }
    }
    fieldFaq {
      entity {
        ${ParagraphFaqPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldSection360deg3dProduct {
      entity {
        ${ParagraphSection360deg3dProductPreview}
      }
    }
    fieldSectionFlatLayPhoto {
      entity {
        ${ParagraphSectionFlatLayPhotographyPreview}
      }
    }
    fieldSectionLiveModel {
      entity {
        ${ParagraphSectionLiveModelPreview}
      }
    }
    fieldSectionStillProduct {
      entity {
        ${ParagraphSectionStillProductPhotographPreview}
      }
    }
    fieldKeyPoints {
      entity {
        ${ParagraphKeyPointsPreview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }

    # important !!!
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
