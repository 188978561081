import get from 'lodash.get';

// === Data === //
import { careerDetailData } from '@graphql/CareerDetail';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { jobOfferIntro } from '@DTO/sections/career/JobOfferIntro';
import { jobOfferArticle } from '@DTO/sections/career/JobOfferArticle';
import { jobOfferContact } from '@DTO/sections/career/JobOfferContact';
import { jobOfferDepartment } from '@DTO/sections/career/JobOfferDepartment';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const CareerDetailPageDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const careerDetailDataQuery = careerDetailData();
    currentPageData = careerDetailDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const displayFields = get(nodeEntity, 'fieldDepartamentDisplay', []);
  const taxonomyEntity = get(currentPageData, 'drupalData.taxonomyTermQuery.entities', []);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, ['fieldCategoryA']),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      jobOfferIntro(nodeEntity, 'fieldCareersIntro'),
      jobOfferArticle(nodeEntity, 'fieldCareersArticle'),
      jobOfferContact(nodeEntity, 'fieldJobContact'),
      jobOfferArticle(nodeEntity, 'fieldCareersArticle2', 1),
      jobOfferDepartment(taxonomyEntity.filter(taxonomy =>
        taxonomy.name === get(nodeEntity, 'fieldCareersIntro.entity.fieldDepartamentName.entity.name', '')
      )[0], displayFields),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
