import { gql } from 'graphql-request';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphSectionLegalPreview } from '@graphql/fragments/ParagraphSectionLegal';

export const LegalPages = gql`
  ... on NodeLegalPages {
    entityBundle
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldSectionLegal {
      entity {
        ${ParagraphSectionLegalPreview}
      }
    }
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
export const LegalPagesLinks = gql`
legalPagesLinks: taxonomyTermQuery(
  filter: {conditions: {field: "vid", value: "menu_legal"}}
  limit: 1000
) {
  entities {
    ... on TaxonomyTermMenuLegal {
      entityBundle
      name
      tid
      parent {
        entity {
          tid
        }
      }
      description {
        processed
      }
      fieldPage {
        entity {
          path {
            alias
          }
        }
      }
      tid
      uuid
    }
  }
}`;
