import get from 'lodash.get';

// === Data === //
import { errorPagesData } from '@graphql/ErrorPages';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { languagePath } from '@DTO/sections/LanguagePath';

export const ErrorPagesDTO = (uuid?: string, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };
  
  if (!data) {
    const errorPagesDataQuery = errorPagesData();
    currentPageData = errorPagesDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: undefined,
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    data: {
      copy: get(nodeEntity, 'fieldCopy.processed', ''),
      copy2: get(nodeEntity, 'fieldCopy2.processed', ''),
      copy3: get(nodeEntity, 'fieldCopy3.processed', ''),
      cta: get(nodeEntity, 'fieldCta')
        ? {
            title: get(nodeEntity, 'fieldCta.title', ''),
            url: get(nodeEntity, 'fieldCta.url.path', ''),
          }
        : undefined,
      headline: get(nodeEntity, 'fieldHeadline', ''),
      subHeadline: get(nodeEntity, 'fieldSubheadline', ''),
    },
  };

  return {
    ...queryData,
  };
};
