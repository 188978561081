import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphAccessoriesMedia = graphql`
  fragment ParagraphAccessoriesMedia on drupalData_ParagraphAccessoriesMedia {
    entityBundle
    fieldDescription {
      processed
    }
    fieldImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldSelectImageOrVideo
    fieldTitle
    fieldVideo {
      entity {
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    fieldPoster {
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;

export const ParagraphAccessoriesMediaPreview = gql`
  ... on ParagraphAccessoriesMedia {
    entityBundle
    fieldDescription {
      processed
    }
    fieldImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldSelectImageOrVideo
    fieldTitle
    fieldVideo {
      entity{
        url
      }
    }
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    
    fieldPoster {
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;
