import { ParagraphCardSmallOnGrid4x2Preview } from '@graphql/fragments/ParagraphCardSmallOnGrid4x2';
import { ParagraphCardTextContextWithCaptionPreview } from '@graphql/fragments/ParagraphCardTextContextWithCaption';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphConversion2Cta2Preview } from '@graphql/fragments/ParagraphConversion2Cta2';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';
import { ParagraphGalleryPreview } from '@graphql/fragments/ParagraphGallery';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphNumericalBenefitsPreview } from '@graphql/fragments/ParagraphNumericalBenefits';
import { ParagraphTestimonialsPreview } from '@graphql/fragments/ParagraphTestimonials';
import { ParagraphTimelinePreview } from '@graphql/fragments/ParagraphTimeline';
import { gql } from 'graphql-request';

export const ProductCategory = gql`
... on NodeCategory {
  entityLabel

  fieldCardSmallOnGrid4x2 {
    entity {
      ${ParagraphCardSmallOnGrid4x2Preview}
    }
  }
  fieldCardTextContextWithCap {
    entity {
      ${ParagraphCardTextContextWithCaptionPreview}
    }
  }
  fieldCardTextImage {
    entity {
      ${ParagraphCardTextImagePreview}
    }
  }
  fieldConversion2Cta {
    entity {
      ${ParagraphConversion2CtaPreview}
    }
  }
  fieldConversion2Cta2 {
    entity {
      ${ParagraphConversion2Cta2Preview}
    }
  }
  fieldFaq {
    entity {
      ${ParagraphFaqPreview}
    }
  }
  fieldGallery {
    entity {
      ${ParagraphGalleryPreview}
    }
  }
  fieldMainBanner {
    entity {
      ${ParagraphMainBannerVariant1Preview}
      ${ParagraphMainBannerVariant2Preview}
      ${ParagraphMainBannerVariant3Preview}
    }
  }
  fieldNumericalBenefits {
    entity {
      ${ParagraphNumericalBenefitsPreview}
    }
  }
  fieldTestimonials {
    entity {
      ${ParagraphTestimonialsPreview}
    }
  }
  fieldTimeline {
    entity {
      ${ParagraphTimelinePreview}
    }
  }
  fieldMetatags {
    entity {
      ${ParagraphMetatagsPreview}
    }
  }
  fieldFormOffer
  fieldForm {
    entity {
      uuid
    }
  }

  fieldCategoryA {
    entity {
      title
      path {
        alias
      }
    }
  }

  # important !!!
  path {
    alias
  }
  title
  uuid
  langcode {
    value
  }
  sticky: entityMultisiteEnabled
  status
  entityMultisiteTranslations {
    entity {
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
  entityTranslations {
    entityLabel
    entityLanguage {
      id
    }
    ... on Node {
      status
      path {
        alias
      }
    }
  }
}
`;
