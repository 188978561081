import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphKeyPointsItem = graphql`
  fragment ParagraphKeyPointsItem on drupalData_ParagraphKeyPointsItem {
    entityBundle
    fieldCopy {
      processed
    }
    fieldNumber
  }
`;

export const ParagraphKeyPointsItemPreview = gql`
  ... on ParagraphKeyPointsItem {
    entityBundle
    fieldCopy {
      processed
    }
    fieldNumber
  }
`;