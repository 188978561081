import get from 'lodash.get';

export const bigNumbers = (nodeData, fieldName: string) => {
  return {
    type: get(nodeData, `${fieldName}[0].entity.entityBundle`, ''),
    list: get(nodeData, `${fieldName}`, []).map((column) => ({
      number: get(column, 'entity.fieldLabel', ''),
      title: get(column, 'entity.fieldTitle', ''),
      subtitle: get(column, 'entity.fieldSubtitle', ''),
      description: get(column, 'entity.fieldDescription.processed', ''),
    })),
  }
}