import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCardTextVideoPreview } from '@graphql/fragments/ParagraphCardTextVideo';
import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';

export const ParagraphFashionSection = graphql`
  fragment ParagraphFashionSection on drupalData_ParagraphFashionSection {
    entityBundle
    fieldCardTextVideo {
      entity {
        ...ParagraphCardTextVideo
      }
    }
    fieldContextImageWithCaption {
      entity {
        ...ParagraphContextImageWithCaption
      }
    }
    fieldHeadline {
      processed
    }
    fieldPoster {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldVariantVideoText
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;

export const ParagraphFashionSectionPreview = gql`
... on ParagraphFashionSection {
  entityBundle
  fieldCardTextVideo {
    entity {
      ${ParagraphCardTextVideoPreview}
    }
  }
  fieldContextImageWithCaption {
    entity {
      ${ParagraphContextImageWithCaptionPreview}
    }
  }
  fieldHeadline {
    processed
  }
  fieldPoster {
    alt
    derivative(style: WEBP) {
      url
    }
    title
    url
  }
  fieldVariantVideoText
  fieldVideo {
    description
    entity {
      url
    }
  }
  fieldVideoUrl {
    title
    url {
      path
    }
  }
  fieldName
  fieldVideoDescription
  fieldVideoDuration
  entityCreated
}
`;
