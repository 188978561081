import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCtaWhitePreview } from '@graphql/fragments/ParagraphCtaWhite';

export const ParagraphConversionBoxOne = graphql`
  fragment ParagraphConversionBoxOne on drupalData_ParagraphConversionBoxOne {
    entityBundle
    fieldCtaWhite {
      entity {
        ...ParagraphCtaWhite
      }
    }
    fieldDescription {
      processed
    }
    uuid
  }
`;

export const ParagraphConversionBoxOnePreview = gql`
  ... on ParagraphConversionBoxOne {
    entityBundle
    fieldCtaWhite {
      entity {
        ${ParagraphCtaWhitePreview}
      }
    }
    fieldDescription {
      processed
    }
    uuid
  }
`;