import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const NodeAuthor = graphql`
  fragment NodeAuthor on drupalData_NodeAuthor {
    entityBundle
    uuid
    fieldActive
    fieldDescription
    fieldImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    title
    fieldTitle
  }
`;

export const NodeAuthorPreview = gql`
  ... on NodeAuthor {
    entityBundle
    uuid
    fieldActive
    fieldDescription
    fieldImage {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    title
    fieldTitle
  }
`;