import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogSideBySideImage34 = graphql`
  fragment ParagraphBlogSideBySideImage34 on drupalData_ParagraphBlogSideBySideImage34 {
    entityBundle
    fieldImageSideBySide34 {
      entity {
        ... on drupalData_ParagraphImageSideBySide34 {
          entityBundle
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldImageCaption
          uuid
        }
      }
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
  }
`;

export const ParagraphBlogSideBySideImage34Preview = gql`
  ... on ParagraphBlogSideBySideImage34 {
    entityBundle
    fieldImageSideBySide34 {
      entity {
        ... on ParagraphImageSideBySide34 {
          entityBundle
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldImageCaption
          uuid
        }
      }
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
  }
`;