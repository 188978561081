import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphNumericalBenefits = graphql`
  fragment ParagraphNumericalBenefits on drupalData_ParagraphNumericalBenefits {
    entityBundle
    fieldDescription {
      processed
    }
    fieldLabel
    fieldSubtitle
    fieldTitle
    uuid
  }
`;

export const ParagraphNumericalBenefitsPreview = gql`
  ... on ParagraphNumericalBenefits {
    entityBundle
    fieldDescription {
      processed
    }
    fieldLabel
    fieldSubtitle
    fieldTitle
    uuid
  }
`;