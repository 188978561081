import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphConversionWithCta = graphql`
  fragment ParagraphConversionWithCta on drupalData_ParagraphConversionWithCta {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;

export const ParagraphConversionWithCtaPreview = gql`
  ... on ParagraphConversionWithCta {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;