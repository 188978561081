import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCareersArticle = graphql`
  fragment ParagraphCareersArticle on drupalData_ParagraphCareersArticle {
    entityBundle
    fieldCareersArticleItem {
      entity {
        ... on drupalData_ParagraphCareersArticleItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          uuid
        }
      }
    }
    uuid
  }
`;

export const ParagraphCareersArticlePreview = gql`
  ... on ParagraphCareersArticle {
    entityBundle
    fieldCareersArticleItem {
      entity {
        ... on ParagraphCareersArticleItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          uuid
        }
      }
    }
    uuid
  }
`;