import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphSystemCompatibilityTablePreview } from '@graphql/fragments/ParagraphSystemCompatibilityTable';
import { ParagraphTextAndImagePreview } from '@graphql/fragments/ParagraphTextAndImage';

export const ParagraphCardWithCollapseTable = graphql`
  fragment ParagraphCardWithCollapseTable on drupalData_ParagraphCardWithCollapseTable {
    entityBundle
    fieldBackground
    fieldCameraCompatibility {
      entity {
        ... on drupalData_ParagraphCameraCompatibility {
          fieldCameraCompatibilityTable {
            entity {
              ... on drupalData_ParagraphCameraCompatibilityTable {
                entityBundle
                fieldCameraRecommended {
                  entity {
                    ... on drupalData_ParagraphCameraRecommended {
                      entityBundle
                      fieldCopy {
                        processed
                      }
                      fieldImage {
                        alt
                        derivative(style: WEBP) {
                          url
                        }
                        title
                        url
                      }
                      fieldLinkNotCta {
                        title
                        url {
                          path
                        }
                      }
                      fieldSelectCamera
                    }
                  }
                }
                fieldCardWithCollapseTable {
                  caption
                  format
                  tableValue
                  value
                }
                fieldCopy {
                  processed
                }
                fieldDictionaryNo
                fieldDictionaryYes
                fieldHeadline {
                  processed
                }
                fieldNotes {
                  processed
                }
                fieldTitle
              }
            }
          }
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLinkToTable
          fieldLogos {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldAnchorId
        }
      }
    }
    fieldHeadline {
      processed
    }
    fieldLogos {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldSoftwareCompatibility {
      entity {
        ... on drupalData_ParagraphSoftwareCompatibility {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLinkToTable
          fieldSystemCompatibilityTable {
            entity {
              ...ParagraphSystemCompatibilityTable
            }
          }
          fieldAnchorId
        }
      }
    }
    fieldTextAndImage {
      entity {
        ...ParagraphTextAndImage
      }
    }
    uuid
  }
`;

export const ParagraphCardWithCollapseTablePreview = gql`
  ... on ParagraphCardWithCollapseTable {
    entityBundle
    fieldBackground
    fieldCameraCompatibility {
      entity {
        ... on ParagraphCameraCompatibility {
          fieldCameraCompatibilityTable {
            entity {
              ... on ParagraphCameraCompatibilityTable {
                entityBundle
                fieldCameraRecommended {
                  entity {
                    ... on ParagraphCameraRecommended {
                      entityBundle
                      fieldCopy {
                        processed
                      }
                      fieldImage {
                        alt
                        derivative(style: WEBP) {
                          url
                        }
                        title
                        url
                      }
                      fieldLinkNotCta {
                        title
                        url {
                          path
                        }
                      }
                      fieldSelectCamera
                    }
                  }
                }
                fieldCardWithCollapseTable {
                  caption
                  format
                  tableValue
                  value
                }
                fieldCopy {
                  processed
                }
                fieldDictionaryNo
                fieldDictionaryYes
                fieldHeadline {
                  processed
                }
                fieldNotes {
                  processed
                }
                fieldTitle
              }
            }
          }
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLinkToTable
          fieldLogos {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldAnchorId
        }
      }
    }
    fieldHeadline {
      processed
    }
    fieldLogos {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldSoftwareCompatibility {
      entity {
        ... on ParagraphSoftwareCompatibility {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLinkToTable
          fieldSystemCompatibilityTable {
            entity {
              ${ParagraphSystemCompatibilityTablePreview}
            }
          }
          fieldAnchorId
        }
      }
    }
    fieldTextAndImage {
      entity {
        ${ParagraphTextAndImagePreview}
      }
    }
    uuid
  }
`;