import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCtaGrayPreview } from '@graphql/fragments/ParagraphCtaGray';
import { ParagraphCtaGrayFramePreview } from '@graphql/fragments/ParagraphCtaGrayFrame';
import { ParagraphCtaPinkPreview } from '@graphql/fragments/ParagraphCtaPink';
import { ParagraphCtaWhiteFramePreview } from '@graphql/fragments/ParagraphCtaWhiteFrame';

export const ParagraphMainBannerVariant2 = graphql`
  fragment ParagraphMainBannerVariant2 on drupalData_ParagraphMainBannerVariant2 {
    entityBundle
    fieldBackgroundVideo {
      entity {
        url
      }
    }
    fieldCopyLeft {
      processed
    }
    fieldCta {
      entity {
        entityBundle
        ...ParagraphCtaGray
        ...ParagraphCtaGrayFrame
        ...ParagraphCtaPink
        ...ParagraphCtaWhiteFrame
      }
    }
    fieldImageDesktopJpg {
      alt
      height
      title
      url
      width
      derivative(style: WEBP) {
        url
      }
    }
    fieldImageMobileJpg {
      alt
      height
      title
      url
      width
      derivative(style: WEBP) {
        url
      }
    }
    fieldTitle
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;

export const ParagraphMainBannerVariant2Preview = gql`
  ... on ParagraphMainBannerVariant2 {
    entityBundle
    fieldBackgroundVideo {
      entity {
        url
      }
    }
    fieldCopyLeft {
      processed
    }
    fieldCta {
      entity {
        entityBundle
        ${ParagraphCtaGrayPreview}
        ${ParagraphCtaGrayFramePreview}
        ${ParagraphCtaPinkPreview}
        ${ParagraphCtaWhiteFramePreview}
      }
    }
    fieldImageDesktopJpg {
      alt
      height
      title
      url
      width
      derivative(style: WEBP) {
        url
      }
    }
    fieldImageMobileJpg {
      alt
      height
      title
      url
      width
      derivative(style: WEBP) {
        url
      }
    }
    fieldTitle
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
    uuid
  }
`;
