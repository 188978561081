import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphImageGrid2x2Preview } from '@graphql/fragments/ParagraphImageGrid2x2';

export const ParagraphCopyImageGrid2x2 = graphql`
  fragment ParagraphCopyImageGrid2x2 on drupalData_ParagraphCopyImageGrid2x2 {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldImageGrid2x2 {
      entity {
        ... ParagraphImageGrid2x2
      }
    }
    uuid
  }
`;

export const ParagraphCopyImageGrid2x2Preview = gql`
  ... on ParagraphCopyImageGrid2x2 {
    entityBundle
    fieldHeadline {
      processed
    }
    fieldImageGrid2x2 {
      entity {
        ${ParagraphImageGrid2x2Preview}
      }
    }
    uuid
  }
`;