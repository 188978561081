import React, { useEffect, useState } from 'react';
import { IPagination } from './Pagination.d';
import cn from 'classnames';
import * as styles from '@components/common/Pagination/Pagination.module.scss';
import { usePagination } from './functions';
import { globalTranslations } from '@helpers/globalTranslations';

const Pagination = ({
  currentPage,
  totalCount,
  pageSize,
  onChange,
  maxSiblings = 1,
  className = '',
}: IPagination) => {
  let lastPage = -1;
  const dots = -123; // if page is this number, show dots
  const [items, setItems] = useState<number[]>([]);
  const [hasPrev, setPrev] = useState(false);
  const [hasNext, setNext] = useState(false);

  useEffect(() => {
    lastPage = Math.ceil(totalCount / pageSize);
    const newItems = usePagination(currentPage, lastPage, dots, maxSiblings);

    setItems(newItems);
    setPrev(currentPage !== 1);
    setNext(currentPage !== lastPage);
  }, [totalCount, currentPage]);

  return (
    <ul className={cn(className, styles.pagination)}>
      <li className={cn(styles.arrow, !hasPrev ? styles.disabled : null)}>
        <button
          className={cn(styles.button, styles.arrowButton, styles.flexCenter)}
          onClick={() => onChange(currentPage - 1)}
          aria-label="pagination-arrow-prev"
        >
          <i className={styles.prevArrow}></i>
          <span className={styles.arrowText}>{globalTranslations('fieldPrevious')}</span>
        </button>
      </li>

      {items &&
        items.map((pageNumber, index) => {
          const isActive = pageNumber === currentPage;

          if (pageNumber === dots) {
            return (
              <li key={'dots' + index} className={cn(styles.pageNumber, styles.flexCenter)}>
                {/* <button className={cn(styles.button, styles.flexCenter)} onClick={() => showInput()} > */}
                <span className={styles.dots}>...</span>
                {/* </button> */}
              </li>
            );
          }

          return (
            <li key={pageNumber} className={cn(styles.pageNumber, isActive ? styles.active : null)}>
              <button
                className={cn(styles.button)}
                onClick={() => onChange(pageNumber)}
                aria-label={`pagination-page-${pageNumber}`}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}

      <li className={cn(styles.arrow, !hasNext ? styles.disabled : null)}>
        <button
          className={cn(styles.button, styles.arrowButton, styles.flexCenter)}
          onClick={() => onChange(currentPage + 1)}
          aria-label="pagination-arrow-next"
        >
          <span className={styles.arrowText}>{globalTranslations('fieldNext')}</span>
          <i className={styles.nextArrow}></i>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
