import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const errorPagesData = () => {
  const data = useStaticQuery(graphql`
    query errorPagesQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "404_page" } }) {
          entities {
            ... on drupalData_Node404Page {
              entityBundle
              fieldCopy {
                processed
              }
              fieldCopy2 {
                processed
              }
              fieldCopy3 {
                processed
              }
              fieldCta {
                title
                url {
                  path
                }
              }
              fieldHeadline
              fieldSubheadline
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }

              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
