import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphIconWithCopy = graphql`
  fragment ParagraphIconWithCopy on drupalData_ParagraphIconWithCopy {
    entityBundle
    fieldCopy {
      processed
    }
    fieldIcon {
      alt
      url
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldTitle
    uuid
  }
`;

export const ParagraphIconWithCopyPreview = gql`
  ... on ParagraphIconWithCopy {
    entityBundle
    fieldCopy {
      processed
    }
    fieldIcon {
      alt
      url
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldTitle
    uuid
  }
`;