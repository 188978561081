import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCareersArticle2 = graphql`
  fragment ParagraphCareersArticle2 on drupalData_ParagraphCareersArticle2 {
    entityBundle
    fieldCareersArticleItem {
      entity {
        ... on drupalData_ParagraphCareersArticleItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          uuid
        }
      }
    }
    uuid
  }
`;

export const ParagraphCareersArticle2Preview = gql`
  ... on ParagraphCareersArticle2 {
    entityBundle
    fieldCareersArticleItem {
      entity {
        ... on ParagraphCareersArticleItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          uuid
        }
      }
    }
    uuid
  }
`;