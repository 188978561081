import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphConversionBoxOnePreview } from '@graphql/fragments/ParagraphConversionBoxOne';

export const ParagraphConversion1Cta = graphql`
  fragment ParagraphConversion1Cta on drupalData_ParagraphConversion1Cta {
    entityBundle
    fieldConversionBoxOne {
      entity {
        ...ParagraphConversionBoxOne
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphConversion1CtaPreview = gql`
  ... on ParagraphConversion1Cta {
    entityBundle
    fieldConversionBoxOne {
      entity {
        ${ParagraphConversionBoxOnePreview}
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;