import { gql } from 'graphql-request';

import { ParagraphArticlePreview } from '@graphql/blog/fragments/ParagraphArticle';
import { ParagraphBlogAlertBoxPreview } from '@graphql/blog/fragments/ParagraphBlogAlertBox';
import { ParagraphBlogImagePreview } from '@graphql/blog/fragments/ParagraphBlogImage';
import { ParagraphBlogTextImageVideoPreview } from '@graphql/blog/fragments/ParagraphBlogTextImageVideo';
import { ParagraphBlogVideoPreview } from '@graphql/blog/fragments/ParagraphBlogVideo';
import { ParagraphBlogTestimonialPreview } from '@graphql/blog/fragments/ParagraphTestimonial';
import { NodeAuthorPreview } from '@graphql/blog/fragments/NodeAuthor';
import { ParagraphBlogProductsCarouselPreview } from '@graphql/blog/fragments/ParagraphBlogProductsCarousel';
import { ParagraphBlogSocialMediaSharePreview } from '@graphql/blog/fragments/ParagraphBlogSocialMediaShare';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphBlogSideBySideImagePreview } from '@graphql/blog/fragments/ParagraphBlogSideBySideImage';
import { ParagraphBlogCode360degPreview } from '@graphql/blog/fragments/ParagraphBlogCode360deg';
import { ParagraphBlogCode360degx2Preview } from '@graphql/blog/fragments/ParagraphBlogCode360degx2';
import { ParagraphWriteToUsFormPreview } from '@graphql/contact/ParagraphWriteToUsForm';
import { ParagraphBlogSideBySideImage34Preview } from '@graphql/blog/fragments/ParagraphBlogSideBySideImage34';

export const NodeBlogPage = gql`
  ... on NodeBlogPage {
    entityLabel
    fieldBlogCategory {
      entity {
        ... on TaxonomyTermBlogCategory {
          entityBundle
          name
          uuid
        }
      }
    }
    fieldBlogProductsCarousel {
      entity {
        ${ParagraphBlogProductsCarouselPreview}
      }
    }
    fieldBlogSocialMediaShare {
      entity {
        ${ParagraphBlogSocialMediaSharePreview}
      }
    }
    fieldBlogTableOfContents
    fieldIntroCopy {
      processed
    }
    fieldMainImage {
      alt
      width
      height
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldMainImageMobile {
      alt
      width
      height
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldPublicationDate {
      date
      value
    }
    fieldReadingTime
    fieldSectionBlog {
      entity {
        ${ParagraphArticlePreview}
        ${ParagraphBlogAlertBoxPreview}
        ${ParagraphBlogImagePreview}
        ${ParagraphBlogTextImageVideoPreview}
        ${ParagraphBlogVideoPreview}
        ${ParagraphBlogTestimonialPreview}
        ${ParagraphBlogSideBySideImagePreview}
        ${ParagraphBlogCode360degPreview}
        ${ParagraphBlogCode360degx2Preview}
        ${ParagraphWriteToUsFormPreview}
        ${ParagraphBlogSideBySideImage34Preview}
      }
    }
    fieldShortIntroCopy {
      processed
    }
    fieldTeaserImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldRefAuthor {
      entity {
        ${NodeAuthorPreview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldCategoryA {
      entity {
        title
        path {
          alias
        }
      }
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldFormOffer

    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;