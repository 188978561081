import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogTextImageVideo = graphql`
  fragment ParagraphBlogTextImageVideo on drupalData_ParagraphBlogTextImageVideo {
    entityBundle
    fieldBlogCopy {
      processed
    }
    fieldSelectImageOrVideo
    fieldImageCaption
    fieldImage {
      height
      width
      url
      targetId
      alt
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldVideo {
      entity {
        url
      }
    }
    fieldVideoCaption
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    fieldPoster {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    # fieldName
    entityCreated
    uuid
  }
`;

export const ParagraphBlogTextImageVideoPreview = gql`
  ... on ParagraphBlogTextImageVideo {
    entityBundle
    fieldBlogCopy {
      processed
    }
    fieldSelectImageOrVideo
    fieldImageCaption
    fieldImage {
      height
      width
      url
      targetId
      alt
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldVideo {
      entity {
        url
      }
    }
    fieldVideoCaption
    fieldVideoUrl {
      url {
        path
      }
      title
    }
    fieldPoster {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    # fieldName
    entityCreated
    uuid
  }
`;