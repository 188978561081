import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCardSmallCarouselPreview } from '@graphql/fragments/ParagraphCardSmallCarousel';
import { ParagraphCardWithProductsDetailsPreview } from '@graphql/fragments/ParagraphCardWithProductsDetails';

export const ParagraphSectionLiveModel = graphql`
  fragment ParagraphSectionLiveModel on drupalData_ParagraphSectionLiveModel {
    entityBundle
    fieldCardSmallCarousel {
      entity {
        ...ParagraphCardSmallCarousel
      }
    }
    fieldCardWithProductsDetails {
      entity {
        ...ParagraphCardWithProductsDetails
      }
    }
    fieldSubmenu
    uuid
  }
`;

export const ParagraphSectionLiveModelPreview = gql`
  ... on ParagraphSectionLiveModel {
    entityBundle
    fieldCardSmallCarousel {
      entity {
        ${ParagraphCardSmallCarouselPreview}
      }
    }
    fieldCardWithProductsDetails {
      entity {
        ${ParagraphCardWithProductsDetailsPreview}
      }
    }
    fieldSubmenu
    uuid
  }
`;