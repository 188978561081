import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogAlertBox = graphql`
  fragment ParagraphBlogAlertBox on drupalData_ParagraphBlogAlertBox {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    uuid
  }
`;

export const ParagraphBlogAlertBoxPreview = gql`
  ... on ParagraphBlogAlertBox {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    uuid
  }
`;