import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphImageGrid2x2 = graphql`
  fragment ParagraphImageGrid2x2 on drupalData_ParagraphImageGrid2x2 {
    entityBundle
    fieldTitle
    fieldDescription {
      processed
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldImage {
      alt
      title
      url
      width
      height
      derivative(style: WEBP) {
        url
      }
    }
    uuid
  }
`;

export const ParagraphImageGrid2x2Preview = gql`
  ... on ParagraphImageGrid2x2 {
    entityBundle
    fieldTitle
    fieldDescription {
      processed
    }
    fieldLinkCta {
      title
      url {
        path
      }
    }
    fieldImage {
      alt
      title
      url
      width
      height
      derivative(style: WEBP) {
        url
      }
    }
    uuid
  }
`;