import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphFashionCardSmallLastPreview } from '@graphql/fragments/ParagraphFashionCardSmallLast';
import { ParagraphFashionCardSmallItemPreview } from '@graphql/fragments/ParagraphFashionCardSmallItem';

export const ParagraphFashionCardSmall = graphql`
  fragment ParagraphFashionCardSmall on drupalData_ParagraphFashionCardSmall {
    uuid
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldFashionCardSmallLast {
      entity {
        ...ParagraphFashionCardSmallLast
      }
    }
    fieldFashionCardSmallItem {
      entity {
        ...ParagraphFashionCardSmallItem
      }
    }
    entityBundle
  }
`;

export const ParagraphFashionCardSmallPreview = gql`
  ... on ParagraphFashionCardSmall {
    uuid
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldFashionCardSmallLast {
      entity {
        ${ParagraphFashionCardSmallLastPreview}
      }
    }
    fieldFashionCardSmallItem {
      entity {
        ${ParagraphFashionCardSmallItemPreview}
      }
    }
    entityBundle
  }
`;