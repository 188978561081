import { ParagraphAccessoriesPreview } from '@graphql/fragments/ParagraphAccessories';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphCardTextVideoPreview } from '@graphql/fragments/ParagraphCardTextVideo';
import { ParagraphCardWithProductsDetailsPreview } from '@graphql/fragments/ParagraphCardWithProductsDetails';
import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphConversion2Cta2Preview } from '@graphql/fragments/ParagraphConversion2Cta2';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';
import { ParagraphFormProductPreview } from '@graphql/fragments/ParagraphFormProduct';
import { ParagraphGalleryPreview } from '@graphql/fragments/ParagraphGallery';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphNumericalBenefitsPreview } from '@graphql/fragments/ParagraphNumericalBenefits';
import { ParagraphOrbitvuTourPreview } from '@graphql/fragments/ParagraphOrbitvuTour';
import { ParagraphProductIconWithCopyPreview } from '@graphql/fragments/ParagraphProductIconWithCopy';
import { ParagraphVideoPreview } from '@graphql/fragments/ParagraphVideo';
import { gql } from 'graphql-request';

export const Products = gql`
... on NodeProduct {
  entityLabel

  fieldAccessories {
    entity {
      ${ParagraphAccessoriesPreview}
    }
  }
  fieldCardTextImage {
    entity {
      ${ParagraphCardTextImagePreview}
    }
  }
  fieldCardTextVideo {
    entity {
      ${ParagraphCardTextVideoPreview}
    }
  }
  fieldCardWithProductsDetails {
    entity {
      ${ParagraphCardWithProductsDetailsPreview}
    }
  }
  fieldContextImageWithCaption {
    entity {
      ${ParagraphContextImageWithCaptionPreview}
    }
  }
  fieldConversion2Cta {
    entity {
      ${ParagraphConversion2CtaPreview}
    }
  }
  fieldConversion2Cta2 {
    entity {
      ${ParagraphConversion2Cta2Preview}
    }
  }
  fieldFaq {
    entity {
      ${ParagraphFaqPreview}
    }
  }
  fieldGallery {
    entity {
      ${ParagraphGalleryPreview}
    }
  }
  fieldIconWithCopy {
    entity {
      ${ParagraphProductIconWithCopyPreview}
    }
  }
  fieldMainBanner {
    entity {
      ${ParagraphMainBannerVariant1Preview}
      ${ParagraphMainBannerVariant2Preview}
      ${ParagraphMainBannerVariant3Preview}
    }
  }
  fieldNumericalBenefits {
    entity {
      ${ParagraphNumericalBenefitsPreview}
    }
  }
  fieldOrbitvuTour {
    entity {
      ${ParagraphOrbitvuTourPreview}
    }
  }
  fieldVideo {
    entity {
      ${ParagraphVideoPreview}
    }
  }
  fieldMetatags {
    entity {
      ${ParagraphMetatagsPreview}
    }
  }

  fieldCategoryA {
    entity {
      title
      path {
        alias
      }
    }
  }
  fieldCategoryB {
    entity {
      path {
        alias
      }
      title
    }
  }
  fieldProductPageForm {
    entity {
      ${ParagraphFormProductPreview}
    }
  }
  fieldFormOffer
  fieldForm {
    entity {
      uuid
    }
  }

  # important !!!
  path {
    alias
  }
  title
  uuid
  langcode {
    value
  }
  sticky: entityMultisiteEnabled
  status
  entityMultisiteTranslations {
    entity {
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
  entityTranslations {
    entityLabel
    entityLanguage {
      id
    }
    ... on Node {
      status
      path {
        alias
      }
    }
  }
}
`;
