import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphImageWithCaptionPreview } from '@graphql/fragments/ParagraphImageWithCaption';

export const ParagraphContextImageWithCaption = graphql`
  fragment ParagraphContextImageWithCaption on drupalData_ParagraphContextImageWithCaption {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImageWithCaption {
      entity {
        ...ParagraphImageWithCaption
      }
    }
    fieldVariantMobile
  }
`;
export const ParagraphContextImageWithCaptionPreview = gql`
  ... on ParagraphContextImageWithCaption {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImageWithCaption {
      entity {
        ${ParagraphImageWithCaptionPreview}
      }
    }
    fieldVariantMobile
  }
`;
