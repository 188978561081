import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCtaPink = graphql`
  fragment ParagraphCtaPink on drupalData_ParagraphCtaPink {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;

export const ParagraphCtaPinkPreview = gql`
  ... on ParagraphCtaPink {
    entityBundle
    fieldLinkCta {
      title
      url {
        path
      }
    }
    uuid
  }
`;
