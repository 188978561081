import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphSoftwareNavi = graphql`
  fragment ParagraphSoftwareNavi on drupalData_ParagraphSoftwareNavi {
    entityBundle
    fieldCopy {
      processed
    }
    fieldCtaText
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldTitle
    uuid
  }
`;

export const ParagraphSoftwareNaviPreview = gql`
  ... on ParagraphSoftwareNavi {
    entityBundle
    fieldCopy {
      processed
    }
    fieldCtaText
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldTitle
    uuid
  }
`;