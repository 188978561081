import React from 'react';

// === Assets === //
import searchLoupe from '@assets/svg/loupe.svg';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/sections/Search/Search.module.scss';

// === Types === //
import { ISearch } from '@components/sections/Search/Search.d';

const Search = ({ search, searchCategory, searchPhrase, setSearchPhrase }: ISearch) => {
  const changeSearchedText = (e) => setSearchPhrase(e.target.value);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <section className={cn('page-wrapper', styles['search'])}>
        <h3 className={cn(styles['searchTitle'])}>{searchCategory}</h3>
        <div className={cn(styles['searchInputWrapper'])}>
          <input
            type="text"
            placeholder={search}
            className={cn(styles['searchInput'])}
            value={searchPhrase}
            onChange={changeSearchedText}
          />
          <img
            className={cn(styles['searchIcon'])}
            src={searchLoupe}
            alt={search}
            width="16px"
            height="16px"
            loading="lazy"
          />
        </div>
      </section>
    </form>
  );
};

export default Search;
