import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCaseStudyItemPreview } from '@graphql/fragments/ParagraphCaseStudyItem'

export const ParagraphCaseStudy = graphql`
  fragment ParagraphCaseStudy on drupalData_ParagraphCaseStudy {
    entityBundle
    fieldCaseStudy {
      entity {
        ...ParagraphCaseStudyItem
      }
    }
    uuid
  }
`;

export const ParagraphCaseStudyPreview = gql`
  ... on ParagraphCaseStudy {
    entityBundle
    fieldCaseStudy {
      entity {
        ${ParagraphCaseStudyItemPreview}
      }
    }
    uuid
  }
`;