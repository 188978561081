import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphTextImageTimeline = graphql`
  fragment ParagraphTextImageTimeline on drupalData_ParagraphTextImageTimeline {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldDescription {
      processed
    }
    fieldTextImageTimelineItem {
      entity {
        ... on drupalData_ParagraphTextImageTimelineItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLinkCta {
            title
            url {
              path
            }
          }
          fieldPoster {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldSubheadline
          fieldVariantImageOrVideo
          fieldVariantVideoText
          fieldVideo {
            description
            entity {
              url
            }
          }
          fieldVideoUrl {
            title
            url {
              path
            }
          }
          fieldName
          fieldVideoDescription
          fieldVideoDuration
          entityCreated
          uuid
        }
      }
    }
  }
`;

export const ParagraphTextImageTimelinePreview = gql`
  ... on ParagraphTextImageTimeline {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldDescription {
      processed
    }
    fieldTextImageTimelineItem {
      entity {
        ... on ParagraphTextImageTimelineItem {
          entityBundle
          fieldCopy {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldLinkCta {
            title
            url {
              path
            }
          }
          fieldPoster {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldSubheadline
          fieldVariantImageOrVideo
          fieldVariantVideoText
          fieldVideo {
            description
            entity {
              url
            }
          }
          fieldVideoUrl {
            title
            url {
              path
            }
          }
          fieldName
          fieldVideoDescription
          fieldVideoDuration
          entityCreated
          uuid
        }
      }
    }
  }
`;
