import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogCode360deg = graphql`
  fragment ParagraphBlogCode360deg on drupalData_ParagraphBlogCode360deg {
    entityBundle
    fieldCode
    fieldCodeCaption
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    uuid
  }
`;

export const ParagraphBlogCode360degPreview = gql`
  ... on ParagraphBlogCode360deg {
    entityBundle
    fieldCode
    fieldCodeCaption
    fieldIncludeInTable
    fieldTableOfContentsForBlog
    uuid
  }
`;