import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphEventCopy = graphql`
  fragment ParagraphEventCopy on drupalData_ParagraphEventCopy {
    entityBundle
    fieldCopy {
      processed
    }
    fieldTitle
    uuid
  }
`;

export const ParagraphEventCopyPreview = gql`
  ... on ParagraphEventCopy {
    entityBundle
    fieldCopy {
      processed
    }
    fieldTitle
    uuid
  }
`;