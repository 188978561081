import get from 'lodash.get';

export const conversionWithCTA = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    cta: {
      label: get(data, 'fieldLinkCta.title', ''),
      url: get(data, 'fieldLinkCta.url.path', '/'),
    },
  }
}