import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphIconWithCopyPreview } from '@graphql/fragments/ParagraphIconWithCopy';

export const ParagraphProductIconWithCopy2 = graphql`
  fragment ParagraphProductIconWithCopy2 on drupalData_ParagraphProductIconWithCopy2 {
    entityBundle
    fieldDescription {
      processed
    }
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ...ParagraphIconWithCopy
      }
    }
    fieldSelectVariantDesktop
    fieldSelectVariantMobile
    uuid
  }
`;

export const ParagraphProductIconWithCopy2Preview = gql`
  ... on ParagraphProductIconWithCopy2 {
    entityBundle
    fieldBackground
    fieldDescription {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphIconWithCopyPreview}
      }
    }
    fieldSelectVariantDesktop
    fieldSelectVariantMobile
    uuid
  }
`;