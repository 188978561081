// extracted by mini-css-extract-plugin
export var bottom = "ErrorPagesComponents-module--bottom--v5Szj";
export var discover = "ErrorPagesComponents-module--discover--TrttL";
export var errorCode = "ErrorPagesComponents-module--error-code--hZPrY";
export var errorCopy = "ErrorPagesComponents-module--error-copy--qLXsw";
export var errorPages = "ErrorPagesComponents-module--error-pages--U9NG8";
export var errorPagesWrapper = "ErrorPagesComponents-module--error-pages-wrapper--Xku1u";
export var head = "ErrorPagesComponents-module--head--KaFy6";
export var hideDesktop = "ErrorPagesComponents-module--hide-desktop--M+0SV";
export var hideMobile = "ErrorPagesComponents-module--hide-mobile--jv3Gp";
export var inner = "ErrorPagesComponents-module--inner--bPcWM";
export var more = "ErrorPagesComponents-module--more--9mKyM";