import get from 'lodash.get';
import * as utils from '@utils';

import { ISubmenuAllCategory } from '@components/sections/SubmenuAllCategory/SubmenuAllCategory.d';
import { carouselData } from '@helpers/smallCarousel';

export const submenuAllCategory = (nodeData, fieldTitleName: string, fieldName: string[]) => {
  const componentData: ISubmenuAllCategory = {
    type: 'product_all_categories', // wrapper doesn't have own entityBundle type
    subheadline: get(nodeData, `${fieldTitleName}`, ''),
    items: fieldName.map(name => {
      const data = get(nodeData, `${name}.entity`, undefined);

      return {
        headline: get(data, 'fieldSubmenu', ''),
        uuid: utils.slugify(get(data, 'fieldSubmenu', undefined) || get(data, 'uuid', '')),
        cardTextImage: get(data, 'fieldCardWithProductsDetails', undefined) ? {
          reverse: false,
          type: get(data, 'fieldCardWithProductsDetails.entity.entityBundle', ''),
          headline: get(data, 'fieldCardWithProductsDetails.entity.fieldHeadline.processed', ''),
          description: get(data, 'fieldCardWithProductsDetails.entity.fieldCopy.processed', ''),
          cta: undefined,
          ctaList: get(data, 'fieldCardWithProductsDetails.entity.fieldLinkOrUpload', []).map(cta => {
            const linkType = get(cta, 'entity.fieldSelectLinkOrUpload', 'link');
            const linkData = linkType === 'link' ?
              get(cta, 'entity.fieldLinkProduct', undefined) :
              get(cta, 'entity.fieldUploadProduct', undefined);

            return {
              label: linkType === 'link' ? get(linkData, 'title', undefined) : get(linkData, 'description', ''),
              url: linkType === 'link' ? get(linkData, 'url.path', undefined) : get(linkData, 'entity.url', '/'),
              target: linkType === 'link' ? '_self' : '_blank',
            }
          }),
          image: get(data, 'fieldCardWithProductsDetails.entity.fieldImage', undefined) ? {
            alt: get(data, 'fieldCardWithProductsDetails.entity.fieldImage.alt', ''),
            title: get(data, 'fieldCardWithProductsDetails.entity.fieldImage.title', ''),
            url: get(data, 'fieldCardWithProductsDetails.entity.fieldImage.url', ''),
            webp: get(data, 'fieldCardWithProductsDetails.entity.fieldImage.derivative.url', ''),
          } : undefined,
          video: get(data, 'fieldCardWithProductsDetails.entity.fieldVideo.entity.url', undefined),
          videoUrl: get(data, 'fieldCardWithProductsDetails.entity.fieldVideoUrl.url.path', undefined),
          imageOrVideo: get(data, 'fieldCardWithProductsDetails.entity.fieldSelectImageOrVideo', 'image'),
          label: get(data, 'fieldCardWithProductsDetails.entity.fieldLabel', undefined)
          ? {
              title: get(data, 'fieldCardWithProductsDetails.entity.fieldLabel', ''),
              url: get(data, 'fieldCardWithProductsDetails.entity.fieldLabelLink.url.path', ''),
              color: get(data, 'fieldCardWithProductsDetails.entity.fieldLabelColor', null),
            }
          : undefined,
          poster: get(data, 'fieldCardWithProductsDetails.entity.fieldPoster', undefined) ? {
            alt: get(data, 'fieldCardWithProductsDetails.entity.fieldPoster.alt', ''),
            title: get(data, 'fieldCardWithProductsDetails.entity.fieldPoster.title', ''),
            url: get(data, 'fieldCardWithProductsDetails.entity.fieldPoster.url', ''),
            webp: get(data, 'fieldCardWithProductsDetails.entity.fieldPoster.derivative.url'),
          } : undefined,
          schemaData: get(data, 'fieldCardWithProductsDetails.entity.fieldName', undefined) ? {
            name: get(data, 'fieldCardWithProductsDetails.entity.fieldName', ''),
            duration: get(data, 'fieldCardWithProductsDetails.entity.fieldVideoDuration', ''),
            description: get(data, 'fieldCardWithProductsDetails.entity.fieldVideoDescription', ''),
            uploadDate: get(data, 'fieldCardWithProductsDetails.entity.entityCreated', '')
          } : undefined,
        } : undefined,
        carousel: carouselData(
          get(data, 'fieldCardSmallCarousel.entity.fieldProductsAvailable', []),
          get(data, 'fieldCardSmallCarousel.entity.fieldProductSelect', [])
        ),
      }
    })
  }

  return componentData;
}