import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphLpHeaderVariant2 = graphql`
  fragment ParagraphLpHeaderVariant2 on drupalData_ParagraphLpHeaderVariant2 {
    entityBundle
    fieldForm {
      entity {
        uuid
      }
    }
    uuid
  }
`;

export const ParagraphLpHeaderVariant2Preview = gql`
  ... on ParagraphLpHeaderVariant2 {
    entityBundle
    fieldForm {
      entity {
        uuid
      }
    }
    uuid
  }
`;