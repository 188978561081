import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogImage = graphql`
  fragment ParagraphBlogImage on drupalData_ParagraphBlogImage {
    entityBundle
    fieldImageCaption
    fieldImage {
      alt
      title
      url
      width
      height
      derivative(style: WEBP) {
        url
      }
    }
    fieldImageCaption
    fieldTableOfContentsForBlog
    uuid
  }
`;

export const ParagraphBlogImagePreview = gql`
  ... on ParagraphBlogImage {
    entityBundle
    fieldImageCaption
    fieldImage {
      alt
      title
      url
      width
      height
      derivative(style: WEBP) {
        url
      }
    }
    fieldImageCaption
    fieldTableOfContentsForBlog
    uuid
  }
`;