import { gql } from 'graphql-request';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphCardWithCollapseTablePreview } from '@graphql/fragments/ParagraphCardWithCollapseTable';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';
import { ParagraphSupportContactFormPreview } from '@graphql/fragments/ParagraphSupportContactForm';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';

export const SupportPage = gql`
  ... on NodeSupportPage {
  entityLabel

  fieldMetatags {
    entity {
      ${ParagraphMetatagsPreview}
    }
  }
  fieldMainBanner {
    entity {
      ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
    }
  }
  fieldCardTextImage {
    entity {
      ${ParagraphCardTextImagePreview}
    }
  }
  fieldCardWithCollapseTable {
    entity {
      ${ParagraphCardWithCollapseTablePreview}
    }
  }
  fieldFaq {
    entity {
      ${ParagraphFaqPreview}
    }
  }
  fieldSupportContactForm {
    entity {
      ${ParagraphSupportContactFormPreview}
    }
  }
  fieldFormOffer
  fieldForm {
    entity {
      uuid
    }
  }

  path {
    alias
  }
  title
  uuid
  langcode {
    value
  }
  sticky: entityMultisiteEnabled
  status
  entityMultisiteTranslations {
    entity {
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
  entityTranslations {
    entityLabel
    entityLanguage {
      id
    }
    ... on Node {
      status
      path {
        alias
      }
    }
  }
}`;
