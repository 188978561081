import { gql } from 'graphql-request';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphNumericalBenefitsPreview } from '@graphql/fragments/ParagraphNumericalBenefits';
import { ParagraphOurCustomerLogoPreview } from '@graphql/fragments/ParagraphOurCustomerLogo';
import { ParagraphPartnership2x2Preview } from '@graphql/fragments/ParagraphPartnership2x2';
import { ParagraphPartnershipContactPreview } from '@graphql/fragments/ParagraphPartnershipContact';

export const PartnershipPage = gql`
  ... on NodePartnershipPage {
    entityLabel

    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldContextImageWithCaption {
      entity {
        ${ParagraphContextImageWithCaptionPreview}
      }
    }
    fieldConversion2Cta {
      entity {
        ${ParagraphConversion2CtaPreview}
      }
    }
    fieldFormOffer
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldNumericalBenefits {
      entity {
        ${ParagraphNumericalBenefitsPreview}
      }
    }
    fieldOurCustomerLogo {
      entity {
        ${ParagraphOurCustomerLogoPreview}
      }
    }
    fieldPartnership2x2 {
      entity {
        ${ParagraphPartnership2x2Preview}
      }
    }
    fieldPartnershipContact {
      entity {
        ${ParagraphPartnershipContactPreview}
      }
    }
    fieldForm {
      entity {
        uuid
      }
    }

    # important !!!
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
