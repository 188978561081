import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphImageWithCaptionPreview } from '@graphql/fragments/ParagraphImageWithCaption';

export const ParagraphOrbitvuTour = graphql`
  fragment ParagraphOrbitvuTour on drupalData_ParagraphOrbitvuTour {
    entityBundle
    fieldBackground
    fieldCode
    fieldImageWithCaption {
      entity {
        ...ParagraphImageWithCaption
      }
    }
    fieldHeadline {
      processed
    }
  }
`;

export const ParagraphOrbitvuTourPreview = gql`
  ... on ParagraphOrbitvuTour {
    entityBundle
    fieldBackground
    fieldCode
    fieldImageWithCaption {
      entity {
        ${ParagraphImageWithCaptionPreview}
      }
    }
    fieldHeadline {
      processed
    }
  }
`;