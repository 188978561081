import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphDepartmentsWithJobOffersList = graphql`
  fragment ParagraphDepartmentsWithJobOffersList on drupalData_ParagraphDepartmentsWithJobOffersList {
    entityBundle
    fieldContactCopy
    fieldContactLink
    fieldContactLinkCopy
    fieldDescription {
      processed
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphDepartmentsWithJobOffersListPreview = gql`
  ... on ParagraphDepartmentsWithJobOffersList {
    entityBundle
    fieldContactCopy
    fieldContactLink
    fieldContactLinkCopy
    fieldDescription {
      processed
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;