import { gql } from 'graphql-request';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphConversionWithCtaPreview } from '@graphql/fragments/ParagraphConversionWithCta';

import { ParagraphCardSmallCarouselPreview } from '@graphql/fragments/ParagraphCardSmallCarousel';
import { ParagraphOurCustomerLogoPreview } from '@graphql/fragments/ParagraphOurCustomerLogo';
import { ParagraphIndustriesGridPreview } from '@graphql/fragments/ParagraphIndustriesGrid';
import { ParagraphCopyImageGrid2x2Preview } from '@graphql/fragments/ParagraphCopyImageGrid2x2';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphCardTextImage2Preview } from '@graphql/fragments/ParagraphCardTextImage2';
import { ParagraphConversion2Cta2Preview } from '@graphql/fragments/ParagraphConversion2Cta2';
import { ParagraphGalleryPreview } from '@graphql/fragments/ParagraphGallery';
import { ParagraphNumericalBenefitsPreview } from '@graphql/fragments/ParagraphNumericalBenefits';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';

export const Homepage = gql`
  ... on NodeHomepage {
    entityLabel

    fieldCardSmallCarousel {
      entity {
        ${ParagraphCardSmallCarouselPreview}
      }
    }
    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldCardTextImage2 {
      entity {
        ${ParagraphCardTextImage2Preview}
      }
    }
    fieldConversion2Cta {
      entity {
        ${ParagraphConversion2CtaPreview}
      }
    }
    fieldConversion2Cta2 {
      entity {
        ${ParagraphConversion2Cta2Preview}
      }
    }
    fieldConversionWithCta {
      entity {
        ${ParagraphConversionWithCtaPreview}
      }
    }
    fieldCopyImageGrid2x2 {
      entity {
        ${ParagraphCopyImageGrid2x2Preview}
      }
    }
    fieldGallery {
      entity {
        ${ParagraphGalleryPreview}
      }
    }
    fieldIndustriesGrid {
      entity {
        ${ParagraphIndustriesGridPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldNumericalBenefits {
      entity {
        ${ParagraphNumericalBenefitsPreview}
      }
    }
    fieldOurCustomerLogo {
      entity {
        ${ParagraphOurCustomerLogoPreview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }

    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
