import { gql } from 'graphql-request';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphCardTextImagePreview } from '@graphql/fragments/ParagraphCardTextImage';
import { ParagraphProductIconWithCopyPreview } from '@graphql/fragments/ParagraphProductIconWithCopy';
import { ParagraphCardTextImage2Preview } from '@graphql/fragments/ParagraphCardTextImage2';
import { ParagraphFaqPreview } from '@graphql/fragments/ParagraphFaq';

export const OrbitvuPage = gql`
  ... on NodeOrbitvuPage {
    entityLabel
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldCardTextImage {
      entity {
        ${ParagraphCardTextImagePreview}
      }
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphProductIconWithCopyPreview}
      }
    }
    fieldCardTextImage2 {
      entity {
        ${ParagraphCardTextImage2Preview}
      }
    }
    fieldFaq {
      entity {
        ${ParagraphFaqPreview}
      }
    }
    fieldContextImageWithCaption {
      entity {
        ${ParagraphContextImageWithCaptionPreview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }

    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
