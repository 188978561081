import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphCtaPinkPreview } from '@graphql/fragments/ParagraphCtaPink';
import { ParagraphCtaWhiteFramePreview } from '@graphql/fragments/ParagraphCtaWhiteFrame';

export const ParagraphLpHeaderVariant1 = graphql`
  fragment ParagraphLpHeaderVariant1 on drupalData_ParagraphLpHeaderVariant1 {
    entityBundle
    fieldCta {
      entity {
        entityBundle
        ...ParagraphCtaPink
        ...ParagraphCtaWhiteFrame
      }
    }
    uuid
  }
`;

export const ParagraphLpHeaderVariant1Preview = gql`
  ... on ParagraphLpHeaderVariant1 {
    entityBundle
    fieldCta {
      entity {
        entityBundle
        ${ParagraphCtaPinkPreview}
        ${ParagraphCtaWhiteFramePreview}
      }
    }
    uuid
  }
`;