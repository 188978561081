import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphContactOfficePreview } from '@graphql/contact/ParagraphContactOffice';

export const ParagraphGlobalOffice = graphql`
  fragment ParagraphGlobalOffice on drupalData_ParagraphGlobalOffice {
    entityBundle
    fieldContactOffice {
      entity {
        ...ParagraphContactOffice
      }
    }
    fieldHeadline {
      processed
    }
    fieldLocalOffices {
      entity {
        ... on drupalData_ParagraphLocalOffices {
          entityBundle
          fieldContactOffice {
            entity {
              ...ParagraphContactOffice
            }
          }
          fieldSubheadline
        }
      }
    }
    fieldSubheadline
    uuid
  }
`;

export const ParagraphGlobalOfficePreview = gql`
  ... on ParagraphGlobalOffice {
    entityBundle
    fieldContactOffice {
      entity {
        ${ParagraphContactOfficePreview}
      }
    }
    fieldHeadline {
      processed
    }
    fieldLocalOffices {
      entity {
        ... on ParagraphLocalOffices {
          entityBundle
          fieldContactOffice {
            entity {
              ${ParagraphContactOfficePreview}
            }
          }
          fieldSubheadline
        }
      }
    }
    fieldSubheadline
    uuid
  }
`;