import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphGallery = graphql`
  fragment ParagraphGallery on drupalData_ParagraphGallery {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldGalleryItem {
      entity {
        ... on drupalData_ParagraphGalleryItem {
          entityBundle
          fieldCode
          fieldGalleryOption
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldVideo {
            description
            entity {
              filename
              url
            }
          }
          fieldPoster {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldName
          fieldVideoDescription
          fieldVideoDuration
          entityCreated
          uuid
        }
      }
    }
  }
`;

export const ParagraphGalleryPreview = gql`
  ... on ParagraphGallery {
    entityBundle
    fieldBackground
    fieldHeadline {
      processed
    }
    fieldGalleryItem {
      entity {
        ... on ParagraphGalleryItem {
          entityBundle
          fieldCode
          fieldGalleryOption
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldVideo {
            description
            entity {
              filename
              url
            }
          }
          fieldPoster {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
          fieldName
          fieldVideoDescription
          fieldVideoDuration
          entityCreated
          uuid
        }
      }
    }
  }
`;
