import { gql } from 'graphql-request';
import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphOurCustomerLogoPreview } from '@graphql/fragments/ParagraphOurCustomerLogo';
import { ParagraphRecommendedArticlesPreview } from '@graphql/fragments/ParagraphRecommendedArticles';
import { ParagraphArticlePreview } from '@graphql/blog/fragments/ParagraphArticle';
import { ParagraphBlogAlertBoxPreview } from '@graphql/blog/fragments/ParagraphBlogAlertBox';
import { ParagraphBlogImagePreview } from '@graphql/blog/fragments/ParagraphBlogImage';
import { ParagraphBlogTextImageVideoPreview } from '@graphql/blog/fragments/ParagraphBlogTextImageVideo';
import { ParagraphBlogVideoPreview } from '@graphql/blog/fragments/ParagraphBlogVideo';
import { ParagraphBlogTestimonialPreview } from '@graphql/blog/fragments/ParagraphTestimonial';
import { NodeAuthorPreview } from '@graphql/blog/fragments/NodeAuthor';
import { ParagraphCardTextVideoPreview } from '@graphql/fragments/ParagraphCardTextVideo';
import { ParagraphCaseStudyPreview } from '@graphql/fragments/ParagraphCaseStudy';

export const CaseStudiesSite = gql`
  ... on NodeCaseStudiesSite {
    entityLabel

    fieldCardTextVideo {
      entity {
        ${ParagraphCardTextVideoPreview}
      }
    }
    fieldCaseStudy {
      entity {
        ${ParagraphCaseStudyPreview}
      }
    }
    fieldContextImageWithCaption {
      entity {
        ${ParagraphContextImageWithCaptionPreview}
      }
    }
    fieldConversion2Cta {
      entity {
        ${ParagraphConversion2CtaPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldOurCustomerLogo {
      entity {
        ${ParagraphOurCustomerLogoPreview}
      }
    }
    fieldRecommendedArticles {
      entity {
        ${ParagraphRecommendedArticlesPreview}
      }
    }

    fieldCategoryA {
      entity {
        title
        path {
          alias
        }
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }

    # important !!!
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;

export const CSBlogCategories = gql`
  blogCategories: taxonomyTermQuery(
    limit: 1000
    filter: {conditions: {field: "vid", value: "blog_category"}}
  ) {
    entities {
      ... on TaxonomyTermBlogCategory {
        tid
        name
        path {
          alias
        }
      }
    }
  }
`;

export const CSBlogArticles = gql`
  blogArticles: nodeQuery(
    limit: 10000,
    filter: { conditions: { field: "type", value: "blog_page" } }
  ) {
    entities {
      ... on NodeBlogPage {
        entityLabel
        fieldBlogCategory {
          entity {
            ... on TaxonomyTermBlogCategory {
              entityBundle
              name
              uuid
            }
          }
        }
        fieldPublicationDate {
          date
          value
        }
        fieldReadingTime
        fieldShortIntroCopy {
          processed
        }
        fieldTeaserImage {
          alt
          derivative(style: WEBP) {
            url
          }
          title
          url
        }
        fieldRefAuthor {
          entity {
            ${NodeAuthorPreview}
          }
        }
        fieldSectionBlog {
          entity {
            ${ParagraphArticlePreview}
            ${ParagraphBlogAlertBoxPreview}
            ${ParagraphBlogImagePreview}
            ${ParagraphBlogTextImageVideoPreview}
            ${ParagraphBlogVideoPreview}
            ${ParagraphBlogTestimonialPreview}
          }
        }
        fieldMetatags {
          entity {
            ${ParagraphMetatagsPreview}
          }
        }
        path {
          alias
        }
        title
        uuid
        langcode {
          value
        }
        sticky: entityMultisiteEnabled
        status
        entityMultisiteTranslations {
          entity {
            entityTranslations {
              entityLabel
              entityLanguage {
                id
              }
              ... on Node {
                status
                path {
                  alias
                }
              }
            }
          }
        }
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
  }
`;
