import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphIconWithCopyPreview } from '@graphql/fragments/ParagraphIconWithCopy';

export const ParagraphProductIconWithCopy = graphql`
  fragment ParagraphProductIconWithCopy on drupalData_ParagraphProductIconWithCopy {
    entityBundle
    fieldBackground
    fieldDescription {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ...ParagraphIconWithCopy
      }
    }
    fieldSelectVariantDesktop
    fieldSelectVariantMobile
    uuid
  }
`;

export const ParagraphProductIconWithCopyPreview = gql`
  ... on ParagraphProductIconWithCopy {
    entityBundle
    fieldBackground
    fieldDescription {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphIconWithCopyPreview}
      }
    }
    fieldSelectVariantDesktop
    fieldSelectVariantMobile
    uuid
  }
`;