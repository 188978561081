import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const howItWorksSiteData = () => {
  const data = useStaticQuery(graphql`
    query howItWorksSiteQuery {
      drupalData {
        nodeQuery(
          filter: { conditions: { field: "type", value: "how_it_works_site" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_NodeHowItWorksSite {
              entityLabel

              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldCardTextImage2 {
                entity {
                  ...ParagraphCardTextImage2
                }
              }
              fieldContextImageWithCaption {
                entity {
                  ...ParagraphContextImageWithCaption
                }
              }
              fieldConversion2Cta {
                entity {
                  ...ParagraphConversion2Cta
                }
              }
              fieldConversion2Cta2 {
                entity {
                  ...ParagraphConversion2Cta2
                }
              }
              fieldIconWithCopy {
                entity {
                  ...ParagraphProductIconWithCopy
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldOurCustomerLogo {
                entity {
                  ...ParagraphOurCustomerLogo
                }
              }
              fieldRecommendedArticles {
                entity {
                  ...ParagraphRecommendedArticles
                }
              }
              fieldTimeline {
                entity {
                  ...ParagraphTimeline
                }
              }
              fieldVideoCard {
                entity {
                  ...ParagraphVideoCard
                }
              }
              fieldCategoryA {
                entity {
                  title
                  path {
                    alias
                  }
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
        blogArticles: nodeQuery(
          limit: 10000
          filter: { conditions: { field: "type", value: "blog_page" } }
        ) {
          entities {
            ... on drupalData_NodeBlogPage {
              entityLabel
              fieldBlogCategory {
                entity {
                  ... on drupalData_TaxonomyTermBlogCategory {
                    entityBundle
                    name
                    uuid
                  }
                }
              }
              fieldPublicationDate {
                date
                value
              }
              fieldReadingTime
              fieldShortIntroCopy {
                processed
              }
              fieldTeaserImage {
                alt
                derivative(style: WEBP) {
                  url
                }
                title
                url
              }
              fieldRefAuthor {
                entity {
                  ...NodeAuthor
                }
              }
              fieldSectionBlog {
                entity {
                  ...ParagraphArticle
                  ...ParagraphBlogAlertBox
                  ...ParagraphBlogImage
                  ...ParagraphBlogTextImageVideo
                  ...ParagraphBlogVideo
                  ...ParagraphBlogTestimonial
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
