import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphConversionBoxPreview } from '@graphql/fragments/ParagraphConversionBox'

export const ParagraphConversion2Cta2 = graphql`
  fragment ParagraphConversion2Cta2 on drupalData_ParagraphConversion2Cta2 {
    entityBundle
    fieldConversionBox {
      entity {
        entityBundle
        ...ParagraphConversionBox
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphConversion2Cta2Preview = gql`
  ... on ParagraphConversion2Cta2 {
    entityBundle
    fieldConversionBox {
      entity {
        entityBundle
        ${ParagraphConversionBoxPreview}
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;