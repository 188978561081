import React from 'react';

// === Components === //
import ButtonLink from '@components/common/ButtonLink/ButtonLink';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/layout/ErrorPagesComponents/ErrorPagesComponents.module.scss';

// === Types === //
import { ErrorPagesData } from '@type/page-components.d';

const ErrorPagesComponents = ({ data, type, slug }: ErrorPagesData) => {
  const { copy, copy2, copy3, cta, headline, subHeadline } = data;

  return (
    <div className={cn(styles['errorPages'])}>
      <div className={cn(styles['inner'])}>
        <div className={cn(styles['head'])}>
          {headline && (
            <h1 className={cn(styles['errorCode'])}>
              <small>{subHeadline}</small>
              <span>{headline}</span>
            </h1>
          )}
          <hr className={cn(styles['hideMobile'])} />
          {copy && <div className={cn(styles['errorCopy'])}>{utils.safeHtml(copy)}</div>}
        </div>
        <hr className={cn(styles['hideDesktop'])} />
        <div className={cn(styles['bottom'])}>
          {copy2 && <div className={cn(styles['discover'])}>{utils.safeHtml(copy2)}</div>}
          {cta && <ButtonLink label={cta.title} href={cta.url} variant="fullDark" />}
          {copy3 && <div className={cn(styles['more'])}>{utils.safeHtml(copy3)}</div>}
        </div>
      </div>
    </div>
  );
};

export default ErrorPagesComponents;
