import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphLpFooterForm = graphql`
  fragment ParagraphLpFooterForm on drupalData_ParagraphLpFooterForm {
    entityBundle
    fieldBackgroundImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldBackgroundImageMobile {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldCopy {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;

export const ParagraphLpFooterFormPreview = gql`
  ... on ParagraphLpFooterForm {
    entityBundle
    fieldBackgroundImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldBackgroundImageMobile {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldCopy {
      processed
    }
    fieldForm {
      entity {
        uuid
      }
    }
    fieldHeadline {
      processed
    }
    uuid
  }
`;