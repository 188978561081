import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphBlogCode360degx2 = graphql`
  fragment ParagraphBlogCode360degx2 on drupalData_ParagraphBlogCode360degx2 {
    entityBundle
    fieldCode360degCaption {
      entity {
        ... on drupalData_ParagraphCode360degCaption {
          entityBundle
          fieldCode
          fieldCodeCaption
          uuid
        }
      }
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
  }
`;

export const ParagraphBlogCode360degx2Preview = gql`
  ... on ParagraphBlogCode360degx2 {
    entityBundle
    fieldCode360degCaption {
      entity {
        ... on ParagraphCode360degCaption {
          entityBundle
          fieldCode
          fieldCodeCaption
          uuid
        }
      }
    }
    fieldIncludeInTable
    fieldTableOfContentsForBlog
  }
`;