import { gql } from "graphql-request";

import { ParagraphMetatagsPreview } from "@graphql/fragments/ParagraphMetatags";
import { ParagraphMainBannerVariant1Preview } from "@graphql/fragments/ParagraphMainBannerVariant1";
import { ParagraphProductSmallCardPreview } from "@graphql/fragments/ParagraphProductSmallCard";

export const CalculatorPage = gql`
  ... on NodeCalculatePage {
    entityBundle
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
      }
    }
    fieldSavingsCalculatorContact {
      entity {
        ... on ParagraphSavingsCalculatorContact {
          id
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldLinkCta {
            title
            url {
              path
            }
          }
        }
      }
    }
    calculators: fieldCalculatorQuestion {
      entity {
        ... on ParagraphCalculatorQuestion {
          id
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          fieldCalculatorQuestion {
            entity {
              ... on ParagraphCalculatorQuestionSection {
                id
                fieldTitle
                fieldCalculatorQuestionItem {
                  entity {
                    ... on ParagraphCalculatorQuestionItem {
                      id
                      fieldCopy {
                        processed
                      }
                      fieldIcon {
                        derivative(style: WEBP) {
                          url
                        }
                        url
                        alt
                        title
                      }
                      fieldSubtitle
                      fieldTitle
                      fieldAverageAmountOfImages
                      fieldAverageCostPerImageEu
                      fieldAverageOperatorCost
                      fieldFixedCostPerMonth
                      fieldRecommendedProduct {
                        entity {
                          nid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldSavingsInfobox {
            entity {
              ... on ParagraphSavingsInfobox {
                entityBundle
                fieldCopy {
                  processed
                }
                fieldHeadline {
                  processed
                }
                fieldLinkCta {
                  title
                  url {
                    path
                  }
                }
                id
                uuid
                fieldLessThan15Year
                fieldLessThan1Year
                fieldLessThan25Years
                fieldLessThan2Years
                fieldLessThan3Years
                fieldLessThan6Months
                fieldLessThan9Months
                fieldMoreThan3Years
              }
            }
          }
        }
      }
    }
    fieldRecommendDevices {
      entity {
        ... on ParagraphRecommendDevices {
          entityBundle
          fieldCopy {
            processed
          }
          fieldHeadline {
            processed
          }
          id
          uuid
        }
      }
    }
    fieldFormCalculator {
      entity {
        ... on ParagraphFormCalculator {
          entityBundle
          fieldForm {
            entity {
              uuid
            }
          }
          fieldHeadline {
            processed
          }
          fieldDescription {
            processed
          }
          fieldImage {
            alt
            derivative(style: WEBP) {
              url
            }
            title
            url
          }
        }
      }
    }
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;

export const CalculateProducts = gql`
  products: nodeQuery(filter: { conditions: { field: "type", value: "product" } }, limit: 1000) {
    entities {
      ... on NodeProduct {
        title
        fieldSmallCard {
          entity {
            ${ParagraphProductSmallCardPreview}
          }
        }
        path {
          alias
        }
        nid
        entityMultisiteTranslations {
          entity {
            entityTranslations {
              entityLabel
              entityLanguage {
                id
              }
              ... on Node {
                status
                path {
                  alias
                }
              }
            }
          }
        }
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
  }
`;