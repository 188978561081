import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphEmployeesItemPreview } from '@graphql/fragments/ParagraphEmployeesItem';
import { ParagraphTeamItemPreview } from '@graphql/fragments/ParagraphTeamItem';

export const ParagraphEmployees = graphql`
  fragment ParagraphEmployees on drupalData_ParagraphEmployees {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldDescription {
      processed
    }
    fieldEmployeesItem {
      entity {
        ...ParagraphEmployeesItem
      }
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldTeamItem {
      entity {
        ...ParagraphTeamItem
      }
    }
    uuid
  }
`;

export const ParagraphEmployeesPreview = gql`
  ... on ParagraphEmployees {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldDescription {
      processed
    }
    fieldEmployeesItem {
      entity {
        ${ParagraphEmployeesItemPreview}
      }
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldTeamItem {
      entity {
        ${ParagraphTeamItemPreview}
      }
    }
    uuid
  }
`;
