import { gql } from 'graphql-request';
import { ParagraphNewsletterPreview } from '@graphql/fragments/ParagraphNewsletter';

export const globalTranslationsData = gql`
globalTranslations: nodeQuery(filter: { conditions: { field: "type", value: "global_translation" } }) {
  entities {
    ... on NodeGlobalTranslation {
      entityBundle
      fieldCtaCardSmall
      fieldCtaBlogCard
      fieldAuthor
      fieldArticlesRecomended
      fieldCtaRecommendedArticles
      fieldNext
      fieldPrevious
      fieldReadingTimeTranslate
      fieldLinkToJobOffer
      fieldMultipleOffersLink
      fieldNoOffersLink
      fieldSingleOfferLink
      fieldAllArticles
      fieldResultNotFound
      fieldContactUs
      fieldNewsletter {
        entity {
          ${ParagraphNewsletterPreview}
        }
      }
      fieldBookADemoCta
      fieldLearnMoreCta
      fieldEmailLabel
      fieldPhoneLabel
      fieldHome
      fieldContactToBuy
      fieldRecommendeEkonomy
      fieldRecommendedAdvanced
      fieldRecommendedPremium
      fieldFieldFormatEmail
      fieldFieldFormatNumber
      fieldFieldFormatPhoneNumber
      fieldFieldRequired
      fieldPreferredLanguage
      fieldRequiredField
      fieldRecaptchaSiteKey
      fieldRecaptchaSecretKey
      fieldRecaptchaError
      fieldRecaptchaCopy {
        processed
      }
      fieldEstymatorCardCta
      fieldOrganizationLogo {
        alt
        url
      }
      fieldOrganizationName
      fieldOrganizationUrl
      fieldContactpointContacttype
      fieldContactpointTelephone
      fieldOrganizationSameas {
        entity {
          ... on ParagraphOrganizationSameas {
            entityBundle
            fieldSameas
          }
        }
      }
      fieldGtm
      uuid

      langcode {
        value
      }
      sticky: entityMultisiteEnabled
      status
      entityMultisiteTranslations {
        entity {
          entityTranslations {
            entityLabel
            entityLanguage {
              id
            }
            ... on Node {
              status
              path {
                alias
              }
            }
          }
        }
      }
      entityTranslations {
        entityLabel
        entityLanguage {
          id
        }
        ... on Node {
          status
          path {
            alias
          }
        }
      }
    }
  }
}`;
