import React from 'react';

// === Components === //
import { IArticlesList } from '@components/sections/Blog/ArticlesList/ArticlesList.d';

// === Styles === //
import * as styles from '@components/sections/Blog/ArticlesList/ArticlesList.module.scss';
import cn from 'classnames';
import BlogCard from '../BlogCard/BlogCard';

const ArticlesList = ({ articles }: IArticlesList) => {
  return (
    <div className={cn(styles['articlesList'])}>
      {articles && articles.map((article, index) => <BlogCard key={index} {...article} />)}
    </div>
  );
};

export default ArticlesList;
