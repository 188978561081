import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphCardTextImageOrVideo = graphql`
  fragment ParagraphCardTextImageOrVideo on drupalData_ParagraphCardTextImageOrVideo {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldSelectImageOrVideo
    fieldSubheadline
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;

export const ParagraphCardTextImageOrVideoPreview = gql`
  ... on ParagraphCardTextImageOrVideo {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldSelectImageOrVideo
    fieldSubheadline
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldPoster {
      alt
      title
      url
      derivative(style: WEBP) {
        url
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;
