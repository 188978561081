import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphVideoPreview } from '@graphql/fragments/ParagraphVideo';

export const ParagraphVideoCard = graphql`
  fragment ParagraphVideoCard on drupalData_ParagraphVideoCard {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldVideoItem {
      entity {
        ...ParagraphVideo
      }
    }
    uuid
  }
`;

export const ParagraphVideoCardPreview = gql`
  ... on ParagraphVideoCard {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldVideoItem {
      entity {
        ${ParagraphVideoPreview}
      }
    }
    uuid
  }
`;
