import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphFullScreenImage = graphql`
  fragment ParagraphFullScreenImage on drupalData_ParagraphFullScreenImage {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImageDesktopJpg {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldImageMobileJpg {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    uuid
  }
`;

export const ParagraphFullScreenImagePreview = gql`
  ... on ParagraphFullScreenImage {
    entityBundle
    fieldBackground
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldImageDesktopJpg {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    fieldImageMobileJpg {
      alt
      url
      title
      derivative(style: WEBP) {
        url
      }
    }
    uuid
  }
`;