import get from 'lodash.get';

import { form } from '@DTO/sections/form/Form';
import { getDataFromPreview } from '@helpers/form';

export const pageForm = (nodeData, fieldName: string, previewData?: unknown) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const formId = get(data, 'fieldForm.entity.uuid', '');

  return {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    headline: get(data, 'fieldHeadline.processed', ''),
    image: get(data, 'fieldImage', undefined)
      ? {
          alt: get(data, 'fieldImage.alt', ''),
          title: get(data, 'fieldImage.title', ''),
          url: get(data, 'fieldImage.url', ''),
          webp: get(data, 'fieldImage.derivative.url', ''),
        }
      : undefined,
    form: form(formId, getDataFromPreview(previewData)),
    anchorId: get(data, 'fieldAnchorId'),
  };
};
