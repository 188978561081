import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphEmployeesItem = graphql`
  fragment ParagraphEmployeesItem on drupalData_ParagraphEmployeesItem {
    entityBundle
    fieldDescription {
      processed
    }
    fieldEMail
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldName
    uuid
  }
`;

export const ParagraphEmployeesItemPreview = gql`
  ... on ParagraphEmployeesItem {
    entityBundle
    fieldDescription {
      processed
    }
    fieldEMail
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldName
    uuid
  }
`;