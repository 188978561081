import get from 'lodash.get';

export const employees = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData = {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldCopy.processed', ''),
    subheadline: get(data, 'fieldDescription.processed', ''),
    image: get(data, 'fieldImage', undefined) ? {
      alt: get(data, 'fieldImage.alt', ''),
      title: get(data, 'fieldImage.title', ''),
      url: get(data, 'fieldImage.url', ''),
      webp: get(data, 'fieldImage.derivative.url', ''),
    } : undefined,
    cardsWithCaption: get(data, 'fieldEmployeesItem', []).map(item => ({
      image: get(item, 'entity.fieldImage', undefined) ? {
        alt: get(item, 'entity.fieldImage.alt', ''),
        title: get(item, 'entity.fieldImage.title', ''),
        url: get(item, 'entity.fieldImage.url', ''),
        webp: get(item, 'entity.fieldImage.derivative.url', ''),
      } : undefined,
      title: get(item, 'entity.fieldName', ''),
      copy: get(item, 'entity.fieldDescription.processed', ''),
      cta: get(item, 'entity.fieldEMail', '') !== '' ? {
        label: get(item, 'entity.fieldEMail', ''),
        url: `mailto:${get(item, 'entity.fieldEMail', '')}`
      } : undefined
    })),
    imagesWithCaption: get(data, 'fieldTeamItem', []).map(item => ({
      copy: get(item, 'entity.fieldCopy.processed', ''),
      image: get(item, 'entity.fieldImage', undefined) ? {
        alt: get(item, 'entity.fieldImage.alt', ''),
        title: get(item, 'entity.fieldImage.title', ''),
        url: get(item, 'entity.fieldImage.url', ''),
        webp: get(item, 'entity.fieldImage.derivative.url', ''),
      } : undefined,
    }))
  };

  return mappedData;
}