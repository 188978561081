import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

import { ParagraphIconWithCopyPreview } from '@graphql/fragments/ParagraphIconWithCopy';

export const ParagraphLpImageWithIconCopy2 = graphql`
  fragment ParagraphLpImageWithIconCopy2 on drupalData_ParagraphLpImageWithIconCopy2 {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ...ParagraphIconWithCopy
      }
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldVariantImage
    uuid
  }
`;


export const ParagraphLpImageWithIconCopy2Preview = gql`
  ... on ParagraphLpImageWithIconCopy2 {
    entityBundle
    fieldCopy {
      processed
    }
    fieldHeadline {
      processed
    }
    fieldIconWithCopy {
      entity {
        ${ParagraphIconWithCopyPreview}
      }
    }
    fieldImage {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldVariantImage
    uuid
  }
`;