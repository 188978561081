import get from 'lodash.get';

import { IJobOfferArticle } from '@components/sections/Career/JobOfferArticle/JobOfferArticle.d'

export const jobOfferArticle = (nodeData, fieldName: string, version?: number) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IJobOfferArticle = {
    type: get(data, 'entityBundle', ''),
    articles: get(data, 'fieldCareersArticleItem', []).map(article => ({
      type: get(article, 'entity.entityBundle', ''),
      headline: get(article, 'entity.fieldHeadline.processed', ''),
      copy: get(article, 'entity.fieldCopy.processed', ''),
    })),
    version: version || 0,
  }

  return componentData;
}