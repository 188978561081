import { graphql } from 'gatsby';
import { gql } from 'graphql-request';

export const ParagraphVideo = graphql`
  fragment ParagraphVideo on drupalData_ParagraphVideo {
    entityBundle
    fieldPoster {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldVariantVideoText
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    fieldVideoMobile {
      entity {
        url
      }
    }
    entityCreated
  }
`;

export const ParagraphVideoPreview = gql`
  ... on ParagraphVideo {
    entityBundle
    fieldPoster {
      alt
      derivative(style: WEBP) {
        url
      }
      title
      url
    }
    fieldVariantVideoText
    fieldVideo {
      description
      entity {
        url
      }
    }
    fieldVideoUrl {
      title
      url {
        path
      }
    }
    fieldName
    fieldVideoDescription
    fieldVideoDuration
    entityCreated
  }
`;
