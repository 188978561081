import { gql } from 'graphql-request';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';
import { ParagraphOnlineEventHeaderPreview } from '@graphql/fragments/ParagraphOnlineEventHeader';
import { ParagraphRecommendedArticlesPreview } from '@graphql/fragments/ParagraphRecommendedArticles';
import { ParagraphEventDisclaimerPreview } from '@graphql/event/ParagraphEventDisclaimer';
import { ParagraphEventCopyPreview } from '@graphql/event/ParagraphEventCopy';
import { ParagraphEventConversionBannerPreview } from '@graphql/event/ParagraphEventConversionBanner';
import { ParagraphEventImagePreview } from '@graphql/event/ParagraphEventImage';
import { ParagraphArticlePreview } from '@graphql/blog/fragments/ParagraphArticle';
import { ParagraphBlogAlertBoxPreview } from '@graphql/blog/fragments/ParagraphBlogAlertBox';
import { ParagraphBlogImagePreview } from '@graphql/blog/fragments/ParagraphBlogImage';
import { ParagraphBlogTextImageVideoPreview } from '@graphql/blog/fragments/ParagraphBlogTextImageVideo';
import { ParagraphBlogVideoPreview } from '@graphql/blog/fragments/ParagraphBlogVideo';
import { ParagraphBlogTestimonialPreview } from '@graphql/blog/fragments/ParagraphTestimonial';
import { NodeAuthorPreview } from '@graphql/blog/fragments/NodeAuthor';
import { ParagraphNoOnlineEventPreview } from '@graphql/fragments/ParagraphNoOnlineEvent';

export const EventNoEventSite = gql`
  ... on NodeEventNoEventSite {
    entityLabel

    fieldForm {
      entity {
        uuid
      }
    }
    fieldFormOffer
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldOnlineEventHeader {
      entity {
        ${ParagraphOnlineEventHeaderPreview}
        ${ParagraphNoOnlineEventPreview}
      }
    }
    fieldRecommendedArticles {
      entity {
        ${ParagraphRecommendedArticlesPreview}
      }
    }
    fieldSectionEvent {
      entity {
        ${ParagraphEventDisclaimerPreview}
        ${ParagraphEventCopyPreview}
        ${ParagraphEventConversionBannerPreview}
        ${ParagraphEventImagePreview}
      }
    }

    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
