import { gql } from 'graphql-request';
import { ParagraphMainBannerVariant1Preview } from '@graphql/fragments/ParagraphMainBannerVariant1';
import { ParagraphMainBannerVariant2Preview } from '@graphql/fragments/ParagraphMainBannerVariant2';
import { ParagraphMainBannerVariant3Preview } from '@graphql/fragments/ParagraphMainBannerVariant3';
import { ParagraphContextImageWithCaptionPreview } from '@graphql/fragments/ParagraphContextImageWithCaption';
import { ParagraphConversion2CtaPreview } from '@graphql/fragments/ParagraphConversion2Cta';
import { ParagraphIndustriesSubmenuSectionPreview } from '@graphql/fragments/ParagraphIndustriesSubmenuSection';
import { ParagraphMetatagsPreview } from '@graphql/fragments/ParagraphMetatags';

export const IndustriesSite = gql`
  ... on NodeIndustriesSite {
    entityLabel

    fieldContextImageWithCaption {
      entity {
        ${ParagraphContextImageWithCaptionPreview}
      }
    }
    fieldConversion2Cta {
      entity {
        ${ParagraphConversion2CtaPreview}
      }
    }
    fieldIndustriesSubmenuSection {
      entity {
        ${ParagraphIndustriesSubmenuSectionPreview}
      }
    }
    fieldMainBanner {
      entity {
        ${ParagraphMainBannerVariant1Preview}
        ${ParagraphMainBannerVariant2Preview}
        ${ParagraphMainBannerVariant3Preview}
      }
    }
    fieldMetatags {
      entity {
        ${ParagraphMetatagsPreview}
      }
    }
    fieldFormOffer
    fieldForm {
      entity {
        uuid
      }
    }

    # important !!!
    path {
      alias
    }
    title
    uuid
    langcode {
      value
    }
    sticky: entityMultisiteEnabled
    status
    entityMultisiteTranslations {
      entity {
        entityTranslations {
          entityLabel
          entityLanguage {
            id
          }
          ... on Node {
            status
            path {
              alias
            }
          }
        }
      }
    }
    entityTranslations {
      entityLabel
      entityLanguage {
        id
      }
      ... on Node {
        status
        path {
          alias
        }
      }
    }
  }
`;
